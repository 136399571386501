import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { apiKey, apiLessonsTableUrl } from '../../config';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import CheckboxLessonAddress from '../../components/checkboxLessonAddress';
import DynamicChecklist  from '../../components/dynamicChecklist';
import DynamicTable from '../../components/dynamicTable';
import StandardCodeSelector from '../../components/standardCodeSelector';

import { getStatusTitle } from './utils';
import { onSubjectCall } from '../../server/getLessonSubjectCall/lesson-subject-call';
import { onGradeCall } from '../../server/getLessonGradesCall/lesson-grade-call';
import { onStatusCall } from '../../server/getLessonStatusCall/lesson-status-call';

import './view-lesson.css';
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
class ViewLessonsPage extends Component{

  constructor(props){
    super(props);
    this.state={
      loading: false,
      subjectsFromServer: [],
      gradesFromServer: [],
      statusFromServer: [],
      selectedGrades: [],
      selectedAddresses: [],
      selectedSubjects: [],
      selectedStatus: [],
      tableData: [{}],
      tablePage: 1,
      totalItems: 0,
      orderBy: "ASC",
      nextButtonStatus: false,
      previousButtonStatus: false,
      selectedStandardId: "",
      selectedExpectationId: "",
      selectedSubCodeId: "",
      selectedSubMainSubCodeId: "",
      subjectOpen: false,
      gradeOpen: false,
      statusOpen: false,
      codeOpen: false,
      projectOpen: false
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeId = this.onChangeId.bind(this);
    this.onChangeOrderAscending = this.onChangeOrderAscending.bind(this);
    this.onChangeOrderDescending = this.onChangeOrderDescending.bind(this);
    this.onLessonsCall = this.onLessonsCall.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.buttonStatusRefresh = this.buttonStatusRefresh.bind(this);
    this.toggleSubject = this.toggleSubject.bind(this);
    this.toggleGrade = this.toggleGrade.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.toggleCode = this.toggleCode.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
  }
  
  onChange(field, value){
    //Trick to add to state from multiple inputs with same function
    this.setState({[field]: value});
  }

  onChangeId(event){
    this.setState({ searchForId: event.target.value });
  }
  
  toggleSubject(){
    this.setState({subjectOpen: !this.state.subjectOpen});
  }

  toggleGrade(){
    this.setState({gradeOpen: !this.state.gradeOpen});
  }

  toggleStatus(){
    this.setState({statusOpen: !this.state.statusOpen});
  }

  toggleCode(){
    this.setState({codeOpen: !this.state.codeOpen});
  }

  toggleProject(){
    this.setState({projectOpen: !this.state.projectOpen});
  }


  onChangeOrderAscending() {
    this.setState({
      orderBy: "ASC"
    }, function(){
      this.onLessonsCall();
    })
  }
  onChangeOrderDescending() {
    this.setState({
      orderBy: "DESC"
    }, function(){
      this.onLessonsCall();
    })
  }

  onLessonsCall(e){
    e.preventDefault();
    this.setState({loading: true}, function(){
      let self = this;
      const { selectedStandardId, 
        selectedExpectationId, 
        selectedSubCodeId, 
        selectedSubjects, 
        selectedGrades, 
        selectedStatus, 
        orderBy,
        searchForId,
        selectedAddresses } = this.state;
      const params = {
          "LessonId": searchForId, 
          "SubjectCodes": selectedStandardId === "" ? selectedSubjects : "",
          "LevelCodes": selectedStandardId === "" ? selectedGrades : "",
          "AddressIds": selectedAddresses,
          "StatusIds": selectedStatus,
          "StandardId": selectedStandardId,
          "ExpectativeId": selectedExpectationId,
          "SubCode": selectedSubCodeId,
          "PageNumber": 1,
          "Limit": 10,
          "OrderBy": orderBy,
          "ApiKey": apiKey,
          "Token": sessionStorage.athenasToken,
          "langCode": this.props.language
      }
      axios.post(apiLessonsTableUrl, params).then(function (response){
        console.log(response);
        if(response.data.TransactionValid && response.data.SessionValid){
          console.log("Response data for lessons", response.data.LessonsDetails.Lessons);
          const lessons = response.data.LessonsDetails.Lessons.map((item) => ({ "id": item.LessonModel.Id,
            "title": item.LessonModel.LessonTitle,
            "code": item.LessonStandardCodeModelList[0] ? item.LessonStandardCodeModelList[0].StandardCode : "",
            "subject": item.LessonModel.SubjectCode,
            "status": getStatusTitle(item.LessonStatusModelList[0].StatusId),
            "definitionQuantity": item.LessonDefinitionModelList.length,
            "exampleQuantity": item.LessonExampleModelList.length,
            "quizQuantity": item.QuizRequestModelList.length,
            "linkItems": [
              {
                "lessonId": item.LessonModel.Id,
                "link": "./lesson"
              },
              {
                "lessonId": item.LessonModel.Id,
                "link": "./pdf", 
                "linkText": "PDF"
              }
            ]}));
          self.setState({
            tableData: lessons,
            tablePage: 1,
            totalItems: response.data.LessonsDetails.TotalResults,
            loading: false
          }, function(){
            self.buttonStatusRefresh();
          });
        }
      }).catch(error => {
        alert(error.response.data.Messages);
      });//Refreshing button statuses
    });
  }

  onChangePageCall(){
    this.setState({loading: true}, function(){
      let self = this;
      const { selectedStandardId, 
        selectedExpectationId, 
        selectedSubCodeId, 
        selectedSubjects, 
        selectedGrades, 
        selectedStatus, 
        tablePage,
        orderBy,
        searchForId,
        selectedAddresses } = this.state;
      const params = {
        "LessonId": searchForId,
        "SubjectCodes": selectedStandardId === "" ? selectedSubjects : "",
        "LevelCodes": selectedStandardId === "" ? selectedGrades : "",
        "AddressIds": selectedAddresses,
        "StatusIds": selectedStatus,
        "StandardId": selectedStandardId,
        "ExpectativeId": selectedExpectationId,
        "SubCode": selectedSubCodeId,
        "PageNumber": tablePage,
        "Limit": 10,
        "OrderBy": orderBy,
        "ApiKey": apiKey,
        "Token": sessionStorage.athenasToken,
        "langCode": this.props.language
      }
      axios.post(apiLessonsTableUrl, params).then(function (response){
        if(response.data.TransactionValid && response.data.SessionValid){
          
          const lessons = response.data.LessonsDetails.Lessons.map((item) => ({ 
            "id": item.LessonModel.Id,
            "title": item.LessonModel.LessonTitle,
            "code": item.LessonStandardCodeModelList[0] ? item.LessonStandardCodeModelList[0].StandardCode : "",
            "subject": item.LessonModel.SubjectCode,
            "status": getStatusTitle(item.LessonStatusModelList[0].StatusId),
            "definitionQuantity": item.LessonDefinitionModelList.length,
            "exampleQuantity": item.LessonExampleModelList.length,
            "quizQuantity": item.QuizRequestModelList.length,
            "linkItems": [
              {
                "lessonId": item.LessonModel.Id,
                "link": "./lesson"
              },
              {
                "lessonId": item.LessonModel.Id,
                "link": "./pdf", 
                "linkText": "PDF"
              }
            ]}));
          self.setState({
            tableData: lessons,
            totalItems: response.data.LessonsDetails.TotalResults,
            loading: false,
          });
        }
      }).catch(error => {
        alert(error.response.data.Messages);
      });
    });
  }

  nextPage(){
    this.setState({
      tablePage: this.state.tablePage + 1
    }, function() {
      this.onChangePageCall();
      this.buttonStatusRefresh();
    });
  }

  previousPage(){
    this.setState({
      tablePage: this.state.tablePage - 1
    }, function(){
      this.onChangePageCall();
      this.buttonStatusRefresh();
    });
  }

  buttonStatusRefresh = () => {
    // Checking if back button can go back
    if(this.state.tablePage === 1){
      this.setState({
        previousButtonStatus: true
      });
    } else{
      this.setState({
        previousButtonStatus: false
      });
    }
    if(this.getLastPage() === this.state.tablePage){
      this.setState({
        nextButtonStatus: true
      });
    } else{
      this.setState({
        nextButtonStatus: false
      });
    }
  }

  //Getting the last possible page
  getLastPage = () => {
    if(this.state.totalItems%10 > 0){
      return Math.floor(this.state.totalItems/10) + 1; 
    } else {
      return this.state.totalItems/10;
    }
  }

  componentDidMount(){
    onSubjectCall(this.props.language).then(data => {
      this.setState({subjectsFromServer: data});
    });
    onGradeCall(this.props.language).then(data => {
      this.setState({gradesFromServer: data});
    });
    onStatusCall().then(data => {
      this.setState({statusFromServer: data});
    });
    this.buttonStatusRefresh();
  }

  render(){
    return(
      <div>
        <h1><FormattedMessage id="vl-title"/></h1>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <h4><FormattedMessage id="vl-filters"/></h4>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="1">
              <Button
                variant={this.state.subjectOpen ? "success" : "primary"}
                onClick={this.toggleSubject}
                aria-controls="set-subject-collapse"
                aria-expanded={this.state.subjectOpen}>
                  <FormattedMessage id="vl-subject"/>
              </Button>
            </Col>
            <Col xs lg="1">
              <Button
                variant={this.state.gradeOpen ? "success" : "primary"}
                onClick={this.toggleGrade}
                aria-controls="set-grade-collapse"
                aria-expanded={this.state.gradeOpen}>
                  <FormattedMessage id="vl-grade"/>
              </Button>
            </Col>
            <Col xs lg="1">
              <Button 
                variant={this.state.statusOpen ? "success" : "primary"}
                onClick={this.toggleStatus}
                aria-controls="set-status-collapse"
                aria-expanded={this.state.statusOpen}>
                  <FormattedMessage id="vl-status"/>
              </Button>
            </Col>
            <Col xs lg="1">
              <Button
                variant={this.state.codeOpen ? "success" : "primary"}
                onClick={this.toggleCode}
                aria-controls="set-code-collapse"
                aria-expanded={this.state.codeOpen}>
                  <FormattedMessage id="vl-code-label"/>
              </Button>
            </Col>
            <Col xs lg="1">
              <Button
                variant={this.state.projectOpen ? "success" : "primary"}
                onClick={this.toggleProject}
                aria-controls="set-project-collapse"
                aria-expanded={this.state.projectOpen}>
                  <FormattedMessage id="vl-project-label"/>
              </Button>
            </Col>
          </Row>
        </Container>
        <Collapse in={this.state.subjectOpen}>
          <div id="set-subject-collapse">  
            <h3><FormattedMessage id="vl-choose-subject"/></h3>
            <DynamicChecklist 
              items={this.state.subjectsFromServer}
              parentState={this.state.selectedSubjects}
              stateName="selectedSubjects"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.gradeOpen}>
          <div id="set-grade-collapse">
            <h3><FormattedMessage id="vl-choose-grade"/></h3>
            <DynamicChecklist 
              items={this.state.gradesFromServer}
              parentState={this.state.selectedGrades}
              stateName="selectedGrades"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.statusOpen}>
          <div id="set-status-collapse">
            <h3>Status</h3>
            <DynamicChecklist 
              items={this.state.statusFromServer}
              parentState={this.state.selectedStatus}
              stateName="selectedStatus"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.codeOpen}>
          <div id="set-code-collapse">
            <div className="searchInput">
              <StandardCodeSelector 
                subjectCode={this.state.selectedSubjects[0]} 
                grade={this.state.selectedGrades[0]} 
                language={this.props.language}
                onChange={this.onChange}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={this.state.projectOpen}>
          <div id="set-project-collapse">
            <CheckboxLessonAddress
              onChange={this.onChange}
            />
          </div>
        </Collapse>
        {/* Search field for id */}
        <div>
          <div>
            <h4><FormattedMessage id="vl-inputId"/></h4>
            <Form onSubmit={this.onLessonsCall}>
              <Row>
                <Col>
                  <Form.Control placeholder="ID" onChange={this.onChangeId} />
                </Col>
                <Col>
                  <Button variant="primary" type="submit"><FormattedMessage id="vl-search"/></Button>
                </Col>
              </Row>
            </Form> 
          </div>         
        </div>
        {/* If there are entries for the table, display it */}
        {this.state.loading ? <div style={{marginTop: "40px"}}><SpinnerLoader/></div> 
        : 
        <div>
          {this.state.totalItems > 0 && 
            <div>
              <hr/>
              <div className="tableControls">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <FormattedMessage id="vl-order-by-button"/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.onChangeOrderAscending}>
                      <FormattedMessage id="vl-order-by-ascending"/>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.onChangeOrderDescending}>
                      <FormattedMessage id="vl-order-by-descending"/>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <p className="totalResultsDisplay"><FormattedMessage id="vl-results"/> :  {this.state.totalItems}</p>
              </div>
              <DynamicTable 
                data={this.state.tableData} 
                itemsPerPage={10} 
                language={this.props.language}
                pageNumber={this.state.tablePage} 
                changePage={(direction) => {this.onChangePage(direction)}}
                resultQuantity={this.state.totalItems}
              />
              <div className="pageControls">
                  {/* Next page buttons */}
                  <Button disabled={this.state.previousButtonStatus} onClick={this.previousPage}>{'<'}</Button>
                    {this.state.tablePage}
                  <Button disabled={this.state.nextButtonStatus} onClick={this.nextPage}>{'>'}</Button>
              </div>
            </div>
          }
        </div>
        }
      </div>
    );
  }
}

export default ViewLessonsPage;