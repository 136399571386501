import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Answer from '../answerComponent/answer-view';
import { FormattedMessage } from 'react-intl';

class QuestionAnswersList extends Component{
  constructor(props){
    super(props);
    this.state={
      answers: [],
      correctAnswerId: ""
    }
    this.addAnswer = this.addAnswer.bind(this);
    this.editAnswerContent = this.editAnswerContent.bind(this);
    this.setCorrectAnswer = this.setCorrectAnswer.bind(this);
  }
  addAnswer(){
    let newContent = this.state.answers;
    newContent.push({answerContent: "", isCorrect: false});
    this.setState({answers: newContent});
  }
  editAnswerContent(index, content){
    let newContent = this.state.answers;
    newContent[index] = content;
    this.props.onChangeAnswer(index, content);
  }
  setCorrectAnswer(id){
    this.setState({correctAnswerId: id})
  }

  render(){
    return(
      <div>
      <Button variant="success" onClick={this.props.addNewAnswer}><FormattedMessage id="qp-add-answer"/></Button>
      {this.props.responses.map((item, index) => {
        return(
          <div key={index}>
            <Answer
              key={index}
              deleteAnswer={this.props.deleteAnswer}
              index={index}
              onChangeCorrectStatus={this.props.onChangeCorrectStatus}
              correctStatus={item.Correct}
              onChangeAnswerContent={this.editAnswerContent}
              answerResponseContent={item.Response}
              lessonSubject={this.props.lessonSubject}
              lessonGrade={this.props.lessonGrade}
              limitFromRules={this.props.quizRules ? this.props.quizRules.ResponseMaxLength : null}
              fileRules={this.props.fileRules}
            />
            
          </div>
        ) 
      })}
      </div>
    );
  }
}

export default QuestionAnswersList;