import React, { Component } from 'react';

import CircleItem from '../circleItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';

import { apiKey, uploadLink} from '../../config';

import Dropzone from 'react-dropzone-uploader';

class FileDropzone extends Component{
  constructor(props){
    super(props);
    this.state={
      chosenMedia: "",
      uploadType: "",
      files: [],
      audioHeaders: [],
      videoHeaders: [],
      submitDisabled: false,
    }
    this.chooseMedia = this.chooseMedia.bind(this);
    this.closeDropzone = this.closeDropzone.bind(this);
    // this.getRules = this.getRules.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateFiles = this.validateFiles.bind(this);
  }

  chooseMedia(value){
    console.log("ChooseMedia");
    let type = " ";
    switch(value){
      case "image/png, image/jpg, image/jpeg":
        type = "image"
        this.setState({submitNotNeeded: true});
        break;
      case "audio/mp3, audio/ogg":
        type = "audio";
        //Setting false until two files are uploaded
        this.setState({submitDisabled: true});
        break;
      case "audio/ogg":
        type = "audio";
        break;
      case "video/mp4, video/ogv":
        type= "video";
        //Setting false until two files are uploaded
        this.setState({submitDisabled: true});
        break;
      default: 
        type = "*";
        break;
      }
    this.setState({chosenMedia: value, showDropzone: true, uploadType: type});
  }

  closeDropzone(){
    this.setState({showDropzone: false});
  }

  // getRules(){
  //   let self = this;
  //   let params = {
  //     apiKey: apiKey,
  //     token: sessionStorage.athenasToken
  //   }
  //   axios.get(apiGetFileRules, { params }).then(function(response) {
  //     //This response just returns the rules
  //     if(response.status === 200){
  //         self.setState({fileRules: response.data});
  //     }else {
  //       alert("Something went wrong with getting the file upload rules.");
  //     }
  //   });
  // }

  getUploadParams =  (headers) => {
    console.log("getUploadParams")
    //In order to get the response URL: headers.xhr.response NOTE: this object is available when the call ends, it is async
    this.setState({dropzoneResponseObject: headers});
    if(this.state.chosenMedia === "audio/mp3, audio/ogg"){
      let newState = this.state.audioHeaders;
      newState.push(headers);
      this.setState({audioHeaders: newState});
      //if there are two files then we activate the submit
      if(this.state.audioHeaders.length === 2){
        console.log("we have two files")
        this.setState({submitDisabled: false});
      }
    }
    if(this.state.chosenMedia === "video/mp4, video/ogv"){
      let newState = this.state.videoHeaders;
      newState.push(headers);
      this.setState({videoHeaders: newState});
      //if there are two files then we activate the submit
      if(this.state.videoHeaders.length === 2){
        console.log("we have two files")
        this.setState({submitDisabled: false});
      }
    }
    console.log(headers);
    return { url :  uploadLink + "token=" + sessionStorage.athenasToken
      + "&apiKey=" + apiKey
      + "&subject=" + this.props.subject 
      + "&level=" + this.props.grade 
      + "&uploadType=" + this.state.uploadType 
      + "&langCode=" + this.props.language   
    } 
  }

  //here we can get image size and set a cancelation if the file does not follow the rules.
  handleChangeStatus = (fileWithMeta, status) => {
    console.log("Hadle change status", status, fileWithMeta);
    if(this.state.chosenMedia === "image/png, image/jpg, image/jpeg"){
      if(fileWithMeta.meta.height > this.props.fileRules[0].MaxHeight){
        fileWithMeta.cancel();
      }
    }
    //console.log(status, meta)
  }

  handleSubmit = (files, allFiles) => {
    //console.log(files.map(f => f.meta));
    console.log("handleSubmit");
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    console.log("Waaaaaaaaaa", response);
    switch(this.state.chosenMedia){
      case "image/png, image/jpg, image/jpeg":
        if(this.state.removeFile){
          allFiles.forEach(f => {f.remove()});
          alert("File id too large");
        } else{
          this.props.onChange(this.props.parentStateKey, this.props.content + "<img " + (this.props.isQuiz ? 'style="max-width: 150px;"' : "")  + " src='" + response.Messages[0] + "' />");
        }
        break;
      case "audio/mp3, audio/ogg":
        console.log(this.state.audioHeaders);
        let audioInputString = "";
        this.state.audioHeaders.forEach((item) => {
          let singleResponse = JSON.parse(item.xhr.responseText);
          audioInputString = audioInputString + '<source src="' + singleResponse.Messages[0] + '" type="audio/' + singleResponse.Messages[0].substr(singleResponse.Messages[0].length - 3) + '">'
        });
        this.props.onChange(this.props.parentStateKey, this.props.content + '<p><audio controls>' + audioInputString + 'Your browser does not support the audio element.</audio>' );
        this.setState({audioHeaders: []});
        break;
      case "audio/ogg":
          this.props.onChange(this.props.parentStateKey, this.props.content + '<p><audio controls><source src="' + response.Messages[0] + '" type="audio/ogg"> Your browser does not support the audio element. </audio></p>');
          break;
      case "video/mp4, video/ogv":
        console.log(this.state.videoHeaders);
        let videoInputString = "";
        this.state.videoHeaders.forEach((item) => {
          let singleResponse = JSON.parse(item.xhr.responseText);
          videoInputString = videoInputString + '<source src="' + singleResponse.Messages[0] + '" type="video/' + (singleResponse.Messages[0].substr(singleResponse.Messages[0].length - 3) === "ogv" ? "ogg" : singleResponse.Messages[0].substr(singleResponse.Messages[0].length - 3)) + '">'
        });
        this.props.onChange(this.props.parentStateKey, this.props.content + '<p><video controls style="width:80%;height:auto;margin-left:10%;">' + videoInputString + 'Your browser does not support the video element.</video>' );
        this.setState({videoHeaders: []});
        break;
      default:
        //default string to remove warning. this case should never be reached anyway.
        break;
      }
    allFiles.forEach(f => f.remove())
  }
  
  validateFiles(droppedFile){
    console.log("validating?", droppedFile);
    let appliedRules = {};
    let messages = [];
    this.props.fileRules.forEach((item) => {
      let typeString = item.Classification + "/" + item.Format;
      if(typeString === droppedFile.meta.type){
        appliedRules = item;
      } 
    })

    if(appliedRules.Classification === "image"){

      if(droppedFile.meta.height > appliedRules.MaxHeight){
        messages.push("File heigth is too large");
      }
      if(droppedFile.meta.width > appliedRules.MaxWidth){
        messages.push("File width is too large");
      }
    }
    if(droppedFile.meta.size > appliedRules.MaxSize){
      console.log("Comparing", droppedFile.meta.size, ">", appliedRules.MaxSize);
      messages.push("File size is too large");
    }
    // if(this.props.grade === ""){
    //   messages.push("Grade must be selected");
    // }
    // if(this.props.subject === ""){
    //   messages.push("Subject must be selected");
    // }
    if(messages.length > 0){
      droppedFile.remove();
      alert(messages);
    }
  }

  // componentDidMount(){
  //   this.getRules();
  // }

  render(){
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
      <div>
      <h4><FormattedMessage id="u-add"/></h4>
        <Container style={{marginTop: "40px"}}>
          <Row>
            <Col onClick={() => this.chooseMedia("image/png, image/jpg, image/jpeg")}>
              <CircleItem imgSrc="faImage" size="90px">
                <FormattedMessage id="um-image"/>
              </CircleItem>
            </Col>
            <Col onClick={() => this.chooseMedia("audio/mp3, audio/ogg")}>
              <CircleItem imgSrc="audio" size="90px">
                <FormattedMessage id="um-audio"/>
              </CircleItem>
            </Col>
            {/* <Col onClick={() => this.chooseMedia("audio/ogg")}>
              <CircleItem imgSrc="introAudio" size="90px">
                <FormattedMessage id="um-intro-audio"/>
              </CircleItem>
            </Col> */}
            <Col onClick={() => this.chooseMedia("video/mp4, video/ogv")}>
              <CircleItem imgSrc="video" size="90px">
                <FormattedMessage id="um-video"/>
              </CircleItem>
            </Col>
          </Row>
        </Container>
        {this.state.showDropzone ? 
          <Dropzone
            key="Dropzone View"
            accept={this.state.type}
            validate={this.validateFiles}
            timeout={180000}
            submitButtonDisabled={this.state.submitDisabled}
            style={baseStyle}
            getUploadParams={this.getUploadParams}
            onChangeStatus={this.handleChangeStatus}
            onSubmit={this.handleSubmit}
            styles={{ dropzone: baseStyle }}
            inputContent={<FormattedMessage id={"u-" + this.state.chosenMedia}/>}
          />: ""}
      </div>
    );
  }
}

export default FileDropzone;