import React from 'react';
import Image from 'react-bootstrap/Image';



const ImageComponent = ({ src, altmsg, width, height }) => (
  <Image 
    src={src} 
    alt={altmsg}
    width={width}
    height={height}
    fluid
  />
);

export default ImageComponent;