import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { getLessonPdfLink } from '../../server/getLessonPdfLinkCall/get-lesson-pdf-link';

class LessonTableLinks extends Component {
  
  saveLessonId(id){
    sessionStorage.setItem("lastSearch", id);
  }
  
  openWindowWithPDF(id){
    // console.log("Openning pdf with id:", id)
    getLessonPdfLink(this.props.language, id).then((response) =>{
      window.open(response, "_blank");
    }).catch((error) => {
      alert(error.response.data.Messages);
    });
  }

  render(){
    const { links } = this.props;
    return(
      <div style={{display: "flex", flexDirection: "column"}}>
        {links.map((item, index) => {
          return (
            item.linkText ? 
              <p
              style={{color: "royalblue", textDecoration: "underline", cursor: "pointer"}}
              key={index} 
              onClick={() => this.openWindowWithPDF(item.lessonId)}>PDF</p>
            :
            <Link  
              key={index} 
              onClick={() => this.saveLessonId(item.lessonId)} 
              to={{pathname: item.link, lessonId: item.lessonId}}>
                {item.linkText ? item.linkText : <FormattedMessage id="lt-view-lesson"/>}
            </Link> 
            
          );
        })}
      </div>
    );
  }
}

export default LessonTableLinks;