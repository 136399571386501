import Cookies from 'universal-cookie';

const cookies = new Cookies();
const COOKIE_NAME = 'athenasAuth';

export const navAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    cookies.set(COOKIE_NAME, true, { path: '/' });
    setTimeout(cb, 100) //fake async
  },
  signout(cb) {
    cookies.remove(COOKIE_NAME);
    setTimeout(cb, 100) //fake async
  }
}