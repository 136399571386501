import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'react-bootstrap/Button';
import TextAreaInput from '../textAreaInput';

import './added-simple-field.css';

class AddedSimpleField extends Component{
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value){
    this.props.onChange(this.props.index, {"Desc": value});
  }

  render(){
    return(
      <div>
        <TextAreaInput 
          labelID={this.props.labelID} 
          index={this.props.index}
          label={<FieldLabel labelId={this.props.labelID} index={this.props.index+1} />}
          textType="textarea"
          onChange={this.onChange}
          content={this.props.content}
          />
        <div className="removeFieldButton">
          <Button variant="danger" onClick={() => this.props.removeField(this.props.index)}><FormattedMessage id="remove"/></Button>
        </div>
      </div>
    );
  }
}

class FieldLabel extends Component{
  render(){
    return(
      <div>
        <h5><FormattedMessage id={this.props.labelId}/> {this.props.index}</h5>
      </div>
    );
  }
}

export default AddedSimpleField;