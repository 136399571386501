//Libraries
import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';


//Regular Components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ImageComponent from '../imageComponent';
import athenasImage from '../../assets/images/athenasLoginImage.png';
import logoGenialSkills from '../../assets/images/genial-logo.png';
import logoRequire from '../../assets/images/logo_require.png';
import logoEdulab from '../../assets/images/logo-edulab.png'; 
import './login-form.css';

import { navAuth } from '../../utils/authHandling/navigation-auth-handler';
import { apiLoginUrl, apiKey } from '../../config';

class LoginForm extends Component{
  constructor(props) {
    super(props);
    this.state={
      username: '',
      password: '', 
      Loading: false
    }

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.sendCall = this.sendCall.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleAuth = (token) => {
    navAuth.authenticate(() => {
      this.setState(() => ({
        redirectToReferrer: true
      }));
    });
  }

  /**
   *  Esta funcion manda el llamado post al api con el payload de username, password y api key 
   *  Por ahora solo tiene un console log para la respuesta.
   *  Los proximos pasos es redirigir al dashboard o manejar cuando los credenciales estan erroneos
   */
  sendCall(event){
    this.setState({Loading: true});
    let self = this;
    const handleAuth = this.handleAuth;
    let apiRoute = apiLoginUrl;
    // var self = this;
    let payload = {
      "Username": this.state.username,
      "Password": this.state.password,
      "APIKey": apiKey,
      "LangCode": this.props.language
    }
    axios.post(apiRoute, payload).then(function (response) {
      self.setState({Loading: false});
      //If response has token
      if(!response.data.Token){
        //Login is not successful
        alert(response.data.Messages[0]);
      } else{
        //Login is successful
        sessionStorage.setItem("athenasToken", response.data.Token);
        handleAuth(response.data.Token); //call redirect function
      }
    }).catch(function (error) {
      alert(error.response.data.Messages);
    });

    //Prevent page reload from form input
    event.preventDefault(); 
  }

  render() {
    const { redirectToReferrer } = this.state;

    if(redirectToReferrer === true){
      return <Redirect to='/' />
    }
    return(
      <div className="loginContent">
        <ImageComponent src={athenasImage} altmsg="Athenas Logo"/>
        {this.state.Loading ? 
          <Spinner animation="grow"/> 
          : 
          <Form onSubmit={this.sendCall.bind(this)} className="loginForm">
            <Form.Group className="textInput" controlId="formBasicEmail">
              <Form.Control placeholder="Username" onChange={this.handleUsernameChange.bind(this)} />
            </Form.Group>
            <Form.Group className="textInput" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Password" onChange={this.handlePasswordChange.bind(this)} />
            </Form.Group>
            <Button className="loginButton" variant="primary" type="submit">Login</Button>
          </Form>
        }
        <Container>
          <Row>
            <Col className="logoImageCol">
              <ImageComponent src={logoGenialSkills} altmsg="Genial Skills Logo" width="240px"/>
            </Col>
            <Col className="logoImageCol">
              <ImageComponent src={logoRequire} altmsg="Require Logo" width="240px"/>
            </Col>
            <Col className="logoImageCol">
              <ImageComponent src={logoEdulab} altmsg="Edulab Logo" width="240px"/>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LoginForm;