import axios from 'axios';
import { apiKey, apiReadComment } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const postCommentRead = async function(language, commentModel){
  let params = {
    APIKey: apiKey,
    Token: sessionStorage.athenasToken,
    LangCode: language,
    CommentModel: commentModel
  }
  try{
    const response = await axios.post(apiReadComment, params);
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        //the post was saved correctly
        return false;
      } else{
        alert(response.data.Messages);
        return false;
      }
    } else{
      //Session is not valid, therefore logout
      alert('Session is not valid.');
      logoutUser();
      return false;
    }
  } catch(error){
    alert(error.response.data.Messages);
    return false;
  }
}