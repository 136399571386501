import React, { Component } from 'react';

import { Player } from 'video-react';

class VideoPlayer extends Component{
  render(){
    return(
      <div>
        <Player
          src={this.props.videoSrc}
        />
      </div>
    );
  }
}

export default VideoPlayer;