import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';
import RichTextInput from '../../../../../../components/richTextInput/rich-text-input-view';

class ExtraContent extends Component{
  render(){
    return(
      <div>
        <Form.Check
          inline
          id="HasExtraContentCheckbox"
          label={<FormattedMessage id="qp-extra-content"/>}
          name="checkbox"
          value="extraContent"
          checked={this.props.hasExtraContent}
          onClick={this.props.extraContentToggle}
        />
        {this.props.hasExtraContent ? 
          <RichTextInput
            content={this.props.extraContent}            
            onChange={this.props.changeExtraContent}
            subject={this.props.lessonSubject}
            grade={this.props.lessonGrade}
            language={this.props.language}
            textName="extraContent"
            limitFromRules={this.props.limitFromRules}
            fileRules={this.props.fileRules}
          /> 
          : ""}
      </div>
    );
  }
}

export default ExtraContent;