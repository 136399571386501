import React, { Component } from 'react';

import { Form } from 'react-bootstrap';

class RadioCheckItem extends Component{

  constructor(props){
    super(props);
    this.state = {
      checked: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(){
    this.setState({
      checked: !this.state.checked,
    })
  }

  render(){
    const { text, handleSelect, code, content } = this.props;
    return(
        <Form.Check
          inline
          id={text}
          label={text} 
          name="radioButton"
          type="radio"
          value={code}
          onClick={() => handleSelect(code)}
          checked={code === content}
          onChange={this.onChange}
        />
    );
    
  }
}

export default RadioCheckItem;