import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { apiKey, apiSubjectUrl } from '../../config';

// import DynamicRadioChecklist from '../dynamicRadioCheckList';
import DropdownSelector from '../dropdownSelector';

class RadioButtonSubjects extends Component{
  constructor(props){
    super(props);
    this.state = {
      subjectsFromServer: [],
      selectedSubject: ""
    }
    this.onSubjectCall = this.onSubjectCall.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle(code){
    let title;
    this.state.subjectsFromServer.forEach((item, index) => {
      if(item.code === code){
        title = item.title;
      }
    });
    return title;
  }

  onSubjectCall(){
    const self = this;
    let params = {
      "token": sessionStorage.athenasToken,
      "apiKey": apiKey,
      "langCode": this.props.language
    }
    axios.get(apiSubjectUrl, {params}).then(function (response){
      if(response.data.TransactionValid && response.data.SessionValid){
        const subjectStrings = response.data.Subjects.map((item) => ({title: item.Name, code: item.Code}));
        self.setState({
          subjectsFromServer: subjectStrings
        });
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }

  componentDidMount(){
    this.onSubjectCall();
  }
  render(){
    return(
      <div>        
        <h4><FormattedMessage id="cl-select-subject"/></h4>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-radios"/></p> : ""}
        {/* <DynamicRadioChecklist 
          items={this.state.subjectsFromServer}
          parentState={this.state.selectedSubject}
          stateName={this.props.textName}
          onChange={this.props.onChange}
          content={this.props.content}
        /> */}
        <DropdownSelector 
          content={this.state.subjectsFromServer} 
          stateName={this.props.textName}
          onChange={this.props.onChange}
          buttonTitle={this.props.content ? this.getTitle(this.props.content) : <FormattedMessage id="cl-select-subject"/>}
        />
      </div>
    );
  }
}

export default RadioButtonSubjects;