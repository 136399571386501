import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

//Regular Components
import DashboardItem from '../../components/dashboardItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class DashboardPage extends Component {
  render() {
    const darkRed = "#C60E00";
    return(
      <div>
        <Container style={{marginTop: "40px"}}>
          <Row>
            <Col>
              <DashboardItem imgSrc="checklist" route="./create-lesson" color={darkRed} iconColor="#dddddd">
                <FormattedMessage id="dash-create-lesson"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="pencil" route="./create-template" color={darkRed} iconColor="#dddddd">
                <FormattedMessage id="dash-generate-layout"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="hashtag" route="./public" color={darkRed} iconColor="#dddddd" isDissabled>
                <FormattedMessage id="dash-manage-standard"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="checklist" route="./public" color={darkRed} iconColor="#dddddd" isDissabled>
                <FormattedMessage id="dash-my-lessons"/>
              </DashboardItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <DashboardItem imgSrc="checklist" route="./public" color={darkRed} iconColor="#dddddd" isDissabled>
                <FormattedMessage id="dash-pre-post-test"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="cogs" route="./public" color={darkRed} iconColor="#dddddd" isDissabled>
                <FormattedMessage id="dash-generate-sales-pdf"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="users" route="./view-users" color={darkRed} iconColor="#dddddd">
                <FormattedMessage id="dash-manage-users"/>
              </DashboardItem>
            </Col>
            <Col>
              <DashboardItem imgSrc="hashtag" route="./public" color={darkRed} iconColor="#dddddd" isDissabled>
                <FormattedMessage id="dash-manage-curricolumn-map"/>
              </DashboardItem>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DashboardPage;