import React, { Component } from 'react';
import Popout from 'react-popout';

import axios from 'axios';
import { apiKey,  apiHtmlLesson, apiHtmlExample, apiHtmlConcept, apiHtmlDefinition, apiHtmlQuiz } from '../../config';

export default class LessonHTMLViewer extends Component{
  constructor(props) {
    super(props);
    this.state={
      contentId: ""
    }
    this.popoutOpen = this.popoutOpen.bind(this);
    this.popoutClosed = this.popoutClosed.bind(this);
    this.getLessonContent = this.getLessonContent.bind(this);
    this.getConceptContent = this.getConceptContent.bind(this);
    this.getDefinitionContent = this.getDefinitionContent.bind(this);
    this.getExampleContent = this.getExampleContent.bind(this);
    this.getQuizContent = this.getQuizContent.bind(this);
    this.callContent = this.callContent.bind(this);
    this.state = { isPoppedOut: false, contentId: ""};
  }

  popoutOpen() {
    this.setState({isPoppedOut: true});
  }

  popoutClosed() {
    
    this.setState({
      isPoppedOut: false,
      contentFromServer: null
    });
    this.props.removePreviewId();
  }


  getLessonContent(){
    let self = this;
    let params = {
      apiKey: apiKey,
      lessonId: this.props.lessonId,
      langCode: this.props.language,
      token: sessionStorage.athenasToken
    }
    axios.get(apiHtmlLesson, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        const content = response.data.HTMLCode;
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }
  getConceptContent(){
    let self = this;
    let params = {
      apiKey: apiKey,
      lessonId: this.props.lessonId,
      langCode: this.props.language,
      token: sessionStorage.athenasToken
    }
    axios.get(apiHtmlConcept, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        const content = response.data.HTMLCode;
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }
  getDefinitionContent(){
    let self = this;
    let params = {
      apiKey: apiKey,
      lessonId: this.props.lessonId,
      langCode: this.props.language,
      token: sessionStorage.athenasToken
    }
    axios.get(apiHtmlDefinition, {params}).then(function (response){
      if(response.data.SessionValid){
        if(response.data.TransactionValid){
          if(response.data.Link){
            // console.log("Definitions is dynamic");
            // console.log(response.data.Link)
            window.open(response.data.Link,"Lesson Content", "width=960,height=720");
            self.props.removePreviewId();
            // self.setState({
            //   linkFromServer: response.data.Link,
            //   previewIsLink: true,
            //   isPoppedOut: true,
            //   stopStopper: false
            // }, function(){
            //   self.setState({
            //     stopStopper: true
            //   })
            // });
          } else{
            const content = response.data.HTMLCode;
            // console.log("Response From get definition content: ", response.data.HTMLCode);
            self.setState({
              contentFromServer: content,
              isPoppedOut: true
            });
          }
        }
      } else{
        alert("Error: Session is not valid");
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }
  getExampleContent(){
    let self = this;
    let params = {
      apiKey: apiKey,
      lessonId: this.props.lessonId,
      langCode: this.props.language,
      token: sessionStorage.athenasToken
    }
    axios.get(apiHtmlExample, {params}).then(function (response){
      if(response.data.SessionValid){
        if(response.data.TransactionValid){
          if(response.data.Link){
            // console.log("Examples is dynamic");
            // console.log(response.data.Link)
            window.open(response.data.Link,"Lesson Content", "width=960,height=720");
            self.props.removePreviewId();
          //   self.setState({
          //     linkFromServer: response.data.Link,
          //     previewIsLink: true,
          //     isPoppedOut: true,
          //     stopStopper: true
          //   });
          }else{
            const content = response.data.HTMLCode;
            self.setState({
              contentFromServer: content,
              isPoppedOut: true
            });
          }
        }
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }
  getQuizContent(){
    let self = this;
    let params = {
      apiKey: apiKey,
      quizId: this.props.lessonId,
      langCode: this.props.language,
      token: sessionStorage.athenasToken
    }
    axios.get(apiHtmlQuiz, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        const content = response.data.HTMLCode;
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }

  callContent(contentId){
    switch(contentId){
      case 0:
        this.getLessonContent();
        this.setState({isPoppedOut: true});
        break;
      case 1:
        this.getConceptContent();
        this.setState({isPoppedOut: true});
        break;
      case 2:
        this.getDefinitionContent();
        // this.setState({isPoppedOut: true});
        break;
      case 3:
        this.getExampleContent();
        // this.setState({isPoppedOut: true});
        break;
      case 4:
        this.getQuizContent();
        this.setState({isPoppedOut: true});
        break;
      default:
        break;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.contentId !== null && nextProps.contentId !== undefined && !nextProps.savingLesson){
      this.setState({contentId: nextProps.contentId}, function(){
        this.callContent(this.state.contentId);
      })
    }
  }

  render() {
    if (this.state.isPoppedOut) {    
      return (
        <Popout  
          options={{height: '720', width: '960'}}
          title='Lesson Content' 
          onClosing={this.popoutClosed}
        >
          <div dangerouslySetInnerHTML={{ __html: this.state.contentFromServer}}></div>
        </Popout>
      );
    }else{
      return(
        <div></div>
      );
    }
  }
}
