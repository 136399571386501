export var emptyLessonModel = {
  "LessonModifierRequestModel": {
    "LessonModel": {
      "LessonNo": null,
      "LevelCode": null,
      "SubjectCode": null,
      "LessonTitle": null
    },
    "LessonCourseYearModel": {
      "CourseYearId": "3" //Verify for what is this
    },
    "LessonDefinitionModelList": [],
    "LessonDetailModel": {
      "KnowledgeLevel": "Norman Webb (1-4)", //Verify how to set this in front end
      "Description": null,
      "Concept": null
    },
    "LessonExampleModelList": [],
    //Program Module for this
    // "LessonIntroAudioModel": {
    //   "AudioSrc": "https://audiosourceinwebservice.com"
    // },
    "LessonObjectiveModelList": [],
    "LessonPerformanceTaskModelList": [],
    "LessonStandardCodeModelList": [
      {
        "StandardCode": null
      }
    ],
    "LessonTransversalThemeModelList": [],
    "ClassicFormat": false,
    "LessonStrategyModelList": [],
    "TaxonomyVerbsToLessonList": [
      {
        "TaxVerbId": "3" //Verify how to set this
      }
    ],
    "AddressToLessonModelList": [
      {
        "AddressId": null
      }
    ],
    "CurricularMapModelList": [
      {
        "UnityId": "2" //Verify how to set this in front-end
      }
    ]
  },
  "apiKey": null,
  "token": null,
  "langCode": null
}

export var testParams = {
  "LessonModifierRequestModel": {
    "LessonModel": {
      "LessonNo": "14",
      "LevelCode": "1",
      "SubjectCode": "MAT-SP",
      "LessonTitle": "Patrones (Título de lección)"
    },
    "LessonCourseYearModel": {
      "CourseYearId": "3"
    },
    "LessonDefinitionModelList": [
      {
        "Name": "Patrón",
        "Desc": "Es un diseño que consiste de: colores, formas o números siguiendo una regla. "
      },
      {
        "Name": "Regla",
        "Desc": "Es una instrucción que se debe seguir. "
      },
      {
        "Name": "Nueva definición",
        "Desc": "Esto es una nueva definición, por ende, no tiene ID.  "
      }
    ],
    "LessonDetailModel": {
      "KnowledgeLevel": "Norman Webb (1-4)",
      "Description": "Basándose en diferentes situaciones, y por medio de esta lección, el estudiante podrá: reconocer, identificar, completar y crear patrones. Además, a través de la inclusión de: modelos concretos, formas geométricas, números, movimientos, sonidos, entre otros elementos, el estudiante desarrollará las destrezas necesarias para resolver situaciones de la vida real.",
      "Concept": "Si miras a tu alrededor y observas detalladamente, te darás cuenta de que algunas formas o cosas, como diseños, se repiten. Tal vez, encontrarás esas repeticiones en las losas del baño de tu casa, en el empapelado de las paredes de tu cocina o en tu ropa, como: franjas, puntos..."
    },
    "LessonExampleModelList": [
      {
        "Name": "Patrón y Regla de colores",
        "Desc": "<img src='https://imagemustbeinourservice.com'> <br/> Patrón: azul-azul amarillo. Regla: Después de dos carros color azul, sigue uno de color amarillo..."
      },
      {
        "Name": "Patrón y Regla de figuras",
        "Desc": "<img src='https://imagemustbeinourservice.com'> <br/> Patrón: flecha - triángulo. Regla: Se observa que hay dos formas que se repiten..."
      },
      {
        "Name": "Nuevo ejemplo",
        "Desc": "Esto es un nuevo ejemplo, por ende, no tiene ID, ya que se autoasigna. "
      }
    ],
    "LessonIntroAudioModel": {
      "AudioSrc": "https://audiosourceinwebservice.com"
    },
    "LessonObjectiveModelList": [
      {
        "Desc": "Leerá diverson patrones"
      },
      {
        "Desc": "Escribirá distinto patrones, y"
      },
      {
        "Desc": "Creará diferentes tipos de patrones"
      },
      {
        "Desc": "Nuevo objetivo, por esto no tiene ID"
      }
    ],
    "LessonPerformanceTaskModelList": [
      {
        "Desc": "Preguntas de ejemplo para tarea"
      },
      {
        "Desc": "Prueba corta"
      },
      {
        "Desc": "Nuevo perfomance task, por esto no tiene ID"
      }
    ],
    "LessonStandardCodeModelList": [
      {
        "StandardCode": "1.A.5.2"
      },
      {
        "StandardCode": "Nuevo estandar code, por ende, no lleva ID"
      }
    ],
    "LessonTransversalThemeModelList": [
      {
        "Desc": "Educación para la paz"
      },
      {
        "Desc": "Nuevo tema transversal, por ende, no lleva ID"
      }
    ],
    "ClassicFormat": false,
    "LessonStrategyModelList": [
      {
        "Desc": "Aprendizaje cooperativo"
      },
      {
        "Desc": "Nueva estrategia, por ende, no lleva ID"
      }
    ],
    "TaxonomyVerbsToLessonList": [
      {
        "TaxVerbId": "3"
      }
    ],
    "AddressToLessonModelList": [
      {
        "AddressId": "2"
      }
    ],
    "CurricularMapModelList": [
      {
        "UnityId": "2"
      }
    ]
  },
  "apiKey": "lssM7swlRt01Vav7nM5RAiUTSPJyGilj",
  "token": "/TRNwgPAJ06dkC5nJxf/IA==",
  "langCode": "en"
}


export const getTaxVerbIds = function(data){
  let taxonomies = [];
  data.forEach(element => {
    taxonomies.push({"TaxVerbId": element});
  });
  return taxonomies;
}

