import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


import  axios  from 'axios';
import  { onRulesCall }  from '../../server/getFileUploadRulesCall/file-upload-rules-call';
import { apiKey, apiLessonGetContent, apiLessonUpdate, apiGetPdfLink } from '../../config';
import { getLessonRules} from '../../server/getLessonRulesCall/get-lesson-rules';

import LessonStatusToggle from '../../components/lessonStatusToggle';
import LessonDetailsDisplay from '../../components/lessonDetailsDisplay';
import LessonHTMLViewer from '../../components/lessonHTMLViewer';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSave } from '@fortawesome/free-solid-svg-icons';

import { getDescContent, getDescAndNameContent, getTaxVerbIds, getUnityIds, setUnityIds, setTaxVerbIds } from './utils';
import { Dropdown } from 'react-bootstrap';


import './style.css';

class ViewLessonPage extends Component {
  constructor(props){
    super(props);
    this.state={
      loadingLessonData: true,
      loading: false,
      lessonConcept: "",
      lessonDefinitions: "",
      lessonDescription: "",
      lessonExample: "",
      lessonGrade: "",
      lessonId: "",
      lessonNo: "",
      lessonObjectives: "",
      lessonQuizes: [],
      lessonStatus: "",
      lessonStrategies:"",
      lessonSubject: "",
      lessonTasks: "",
      lessonTaxonomies: [],
      lessonTitle: "",
      lessonTransversalThemes: "",
      lessonUnit: [],
      lessonStandardCode: "",
      previewId: "",
      lessonTemplates: [],
    }
    this.onLessonCall = this.onLessonCall.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateLesson = this.updateLesson.bind(this);
    this.openWindowWithPDF = this.openWindowWithPDF.bind(this);
    this.removePreviewId = this.removePreviewId.bind(this);
    this.superCompareLesson = this.superCompareLesson.bind(this);
  }
  
  onLessonCall(){
    let self = this;
    let params = {
      "apiKey": apiKey,
      "lessonId": sessionStorage.lastSearch,
      "langCode": this.props.language,
      "isForOutsideSource": false,
      "isAWebService": true,
      "token": sessionStorage.athenasToken
    }
    axios.get(apiLessonGetContent, {params}).then(function (response){
      if(response.data.TransactionValid && response.data.SessionValid){
        let lessonData = response.data.LessonModifierRequestModel;
        console.log("Lesson data from server: ", response.data);
        let quizes = [];
        let exams = [];
        lessonData.QuizRequestModelList.forEach(element => {
          if(element.QuizModel.IsExam === "0"){
            quizes.push(element);
          } else{
            exams.push(element);
          }
        });
        self.setState({
          loadingLessonData: false,
          loading: false,
          lesson: response,
          lessonAddress: lessonData.AddressToLessonModelList[0].AddressId,
          lessonStatus: lessonData.LessonStatusModelList[0].StatusId,
          lessonId: lessonData.LessonModel.Id,
          lessonTitle: lessonData.LessonModel.LessonTitle,
          selectedStandardId: lessonData.LessonStandardCodeModelList[0] ? lessonData.LessonStandardCodeModelList[0].StandardId : null ,
          selectedExpectationId: lessonData.LessonStandardCodeModelList[0] ? lessonData.LessonStandardCodeModelList[0].CodeMainId : null ,
          selectedSubCodeId: lessonData.LessonStandardCodeModelList[0] ? lessonData.LessonStandardCodeModelList[0].SubmainCodeId : null ,
          selectedSubMainSubCodeId: lessonData.LessonStandardCodeModelList[0] ? lessonData.LessonStandardCodeModelList[0].SubmainSubCodeId : null,
          lessonNo: lessonData.LessonModel.LessonNo,
          lessonDescription: lessonData.LessonDetailModel.Description,
          lessonSubject: lessonData.LessonModel.SubjectCode,
          lessonGrade: lessonData.LessonModel.LevelCode,
          lessonConcept: lessonData.LessonDetailModel.Concept,
          lessonObjectives: lessonData.LessonObjectiveModelList ? getDescContent(lessonData.LessonObjectiveModelList) : [],
          lessonTasks: lessonData.LessonPerformanceTaskModelList ? getDescContent(lessonData.LessonPerformanceTaskModelList) : [],
          lessonStrategies: lessonData.LessonStrategyModelList ? getDescContent(lessonData.LessonStrategyModelList) : [],
          lessonTransversalThemes: lessonData.LessonTransversalThemeModelList ? getDescContent(lessonData.LessonTransversalThemeModelList) : [],
          lessonDefinitions: getDescAndNameContent(lessonData.LessonDefinitionModelList),
          lessonExample: getDescAndNameContent(lessonData.LessonExampleModelList),
          lessonQuizes: lessonData.QuizRequestModelList,
          lessonPractices: quizes, 
          lessonExams: exams,
          lessonTaxonomies: lessonData.TaxonomyVerbsToLessonModelList ? getTaxVerbIds(lessonData.TaxonomyVerbsToLessonModelList) : [],
          lessonUnit: lessonData.LessonCurricularMapModelList ? getUnityIds(lessonData.LessonCurricularMapModelList) : [],
          lessonHasRecorder: lessonData.LessonModel.HasAudioRecorder === "1",
          lessonAudioSrc: lessonData.LessonIntroAudioModel ? lessonData.LessonIntroAudioModel.AudioSrc : "",
          lessonStandardCode: lessonData.LessonStandardCodeModelList[0] !== null && lessonData.LessonStandardCodeModelList[0] !== undefined ? lessonData.LessonStandardCodeModelList[0].StandardCode : "",
          lessonTemplates: lessonData.LessonTemplateModelList ? lessonData.LessonTemplateModelList : []
        }, function(){
          // console.log("Lesson data loaded from server: ", self.state);
          getLessonRules(self.state.lessonAddress).then((data) => {
            self.setState({lessonRules: data});
          });
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }

  onChange(field, value){
    //Trick to add to state from multiple inputs with same function
    this.setState({[field]: value});
  }

  superCompareLesson(){
    this.setState({loading: true}, function(){
      let self = this;
      let params = {
        "apiKey": apiKey,
        "lessonId": sessionStorage.lastSearch,
        "langCode": this.props.language,
        "isForOutsideSource": false,
        "isAWebService": true,
        "token": sessionStorage.athenasToken
      }
      axios.get(apiLessonGetContent, {params}).then(function (response){
        if(response.data.TransactionValid && response.data.SessionValid){
          let lessonData = response.data.LessonModifierRequestModel;
          console.log("Lesson data from server: ", response.data);
          let quizes = [];
          let exams = [];
          lessonData.QuizRequestModelList.forEach(element => {
            if(element.QuizModel.IsExam === "0"){
              quizes.push(element);
            } else{
              exams.push(element);
            }
          });
          self.setState({
            secondLessonCall: response.data
          }, function(){
            let oldLesson = self.state.lesson.data.LessonModifierRequestModel;
            let newLesson = self.state.secondLessonCall.LessonModifierRequestModel;
            console.log("Second lesson call", self.state.secondLessonCall)
            let params = {
              APIKey: apiKey,
              Token: sessionStorage.athenasToken,
              LangCode: self.props.language,
              LessonModifierRequestModel: {
                LessonModel: {
                  Id: self.state.lesson.data.LessonModifierRequestModel.LessonModel.Id,
                  LessonNo: oldLesson.LessonModel.LessonNo ===  self.state.lessonNo ? newLesson.LessonModel.LessonNo : self.state.lessonNo,
                  LevelCode: oldLesson.LessonModel.LevelCode === self.state.lessonGrade ? newLesson.LessonModel.LevelCode  : self.state.lessonGrade,
                  SubjectCode: oldLesson.LessonModel.SubjectCode ===  self.state.lessonSubject ? newLesson.LessonModel.SubjectCode : self.state.lessonSubject,
                  LessonTitle: oldLesson.LessonModel.LessonTitle === self.state.lessonTitle ? newLesson.LessonModel.LessonTitle : self.state.lessonTitle,
                  HasAudioRecorder: self.state.lessonHasRecorder ? "1" : "0"
                },
                // LessonCourseYearModel: {
                //   "Id": 029293,
                //   "LessonId": "999206",
                //   "CourseYearId": "3"
                // },
                LessonDefinitionModelList: oldLesson.LessonDefinitionModelList === self.state.lessonDefinitions ? newLesson.LessonDefinitionModelList : self.state.lessonDefinitions,
                LessonDetailModel: {
                  LessonId: self.state.lesson.data.LessonModifierRequestModel.LessonModel.Id,
                  KnowledgeLevel: "Norman Webb (1-4)",
                  Description: oldLesson.LessonDetailModel.Description === self.state.lessonDescription ? oldLesson.LessonDetailModel.Description : self.state.lessonDescription,
                  Concept: oldLesson.LessonDetailModel.Concept === self.state.lessonConcept ? newLesson.LessonDetailModel.Concept : self.state.lessonConcept
                },
                "LessonExampleModelList": oldLesson.LessonExampleModelList === self.state.lessonExample ? newLesson.LessonExampleModelList : self.state.lessonExample,
                "LessonIntroAudioModel": {
                  "LessonId": self.state.lesson.data.LessonModifierRequestModel.LessonModel.Id,
                  "AudioSrc": oldLesson.LessonIntroAudioModel ? oldLesson.LessonIntroAudioModel.AudioSrc  === self.state.lessonAudioSrc ? newLesson.LessonIntroAudioModel.AudioSrc : self.state.lessonAudioSrc : ""
                },
                "LessonObjectiveModelList": oldLesson.LessonObjectiveModelList === self.state.lessonObjectives ? newLesson.LessonObjectiveModelList : self.state.lessonObjectives,
                "LessonPerformanceTaskModelList": oldLesson.LessonPerformanceTaskModelList === self.state.lessonTasks ? newLesson.LessonObjectiveModelList : self.state.lessonTasks,
                "LessonStandardCodeModelList": [{
                  "StandardCode": oldLesson.LessonStandardCodeModelList[0] ? oldLesson.LessonStandardCodeModelList[0].StandardCode  === self.state.lessonStandardCode ? newLesson.LessonStandardCodeModelList[0].StandardCode : self.state.lessonStandardCode : self.state.lessonStandardCode,
                  "StandardId": oldLesson.LessonStandardCodeModelList[0] ? oldLesson.LessonStandardCodeModelList[0].StandardId === self.state.selectedStandardId ? newLesson.LessonStandardCodeModelList[0].StandardId : self.state.selectedStandardId : self.state.selectedStandardId,
                  "CodeMainId": oldLesson.LessonStandardCodeModelList[0] ? oldLesson.LessonStandardCodeModelList[0].CodeMainId === self.state.selectedExpectationId ? newLesson.LessonStandardCodeModelList[0].CodeMainId : self.state.selectedExpectationId : self.state.selectedExpectationId,
                  "SubmainCodeId": oldLesson.LessonStandardCodeModelList[0] ? oldLesson.LessonStandardCodeModelList[0].SubmainCodeId === self.state.selectedSubCodeId ? newLesson.LessonStandardCodeModelList[0].SubmainCodeId : self.state.selectedSubCodeId : self.state.selectedSubCodeId,
                  "SubmainSubCodeId": oldLesson.LessonStandardCodeModelList[0] ? oldLesson.LessonStandardCodeModelList[0].SubmainSubCodeId === self.state.selectedSubMainSubCodeId ? newLesson.LessonStandardCodeModelList[0].SubmainSubCodeId : self.state.selectedSubMainSubCodeId : self.state.selectedSubMainSubCodeId
                }],
                // [
                //   {
                //     
                //   }
                // ],
                "LessonTransversalThemeModelList": oldLesson.LessonTransversalThemeModelList === self.state.lessonTransversalThemes ? newLesson.LessonTransversalThemeModelList : self.state.lessonTransversalThemes,
                "ClassicFormat": false,
                "LessonStatusModelList": [
                  {
                    "StatusId": oldLesson.LessonStatusModelList[0].StatusId === self.state.lessonStatus ? newLesson.LessonStatusModelList[0].StatusId : self.state.lessonStatus
                  }
                ],
                "LessonStrategyModelList": oldLesson.LessonStrategyModelList === self.state.lessonStrategies ? newLesson.LessonStrategyModelList : self.state.lessonStrategies,
                "TaxonomyVerbsToLessonModelList": setTaxVerbIds(self.state.lessonTaxonomies),
                "LessonCurricularMapModelList": setUnityIds(self.state.lessonUnit),
                "LessonTemplateModelList": oldLesson.LessonTemplateModelList === self.state.lessonTemplates ? newLesson.LessonTemplateModelList : self.state.lessonTemplates
              }
            }
            self.updateLesson(params);
          });
        }
      }).catch(error => {
        alert(error.response.data.Messages);
      });
    });
  }

  updateLesson(par){
    let params = par;
    console.log("Trying to update Lesson", params)
    let self = this;
    this.setState({loading: true}, function(){
      axios.post(apiLessonUpdate, params).then(function (response){
        console.log(response)
        if(response.data.TransactionValid && response.data.SessionValid){
          alert(response.data.Messages);
          window.location.reload(false);
        } else{
          alert(response.data.Messages);
          self.setState({loading: false})
        }
      }).catch(error => {
        alert(error.response.data.Messages);
        self.setState({loading: false});
      });
    });
  }

  openWindowWithPDF(){
    console.log("Openning pdf with id: " + this.state.lessonId)
    let params = {
      apiKey: apiKey,
      langCode: this.props.language,
      lessonId: this.state.lessonId,
      token: sessionStorage.athenasToken
    }
    axios.get(apiGetPdfLink, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        //Send user to new tab with the link returned
        window.open(response.data.Location, "_blank");
      } else{
        alert(response.data.Messages);
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }

  componentDidMount(){
    this.onLessonCall();
    onRulesCall().then((data) => {
      this.setState({
        fileRules: data
      });
    });
  }
  getAddressDisplay(){
    switch(this.state.lessonAddress){
      case '1':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>VR</b></p>;
      case '2':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>Genial Web</b></p>;
      case '3':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>PDF</b></p>;
      case '4':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>AR</b></p>;
      default:
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>Loading</b></p>;
    }
  }

  removePreviewId(){
    this.setState({previewId: null});
  }

  render(){
    return(
      <div style={{padding: "10px"}}>
        <div>
          <Container className="justify-content-md-start">
            <Row>
              <Col md="auto">
                <h1><FormattedMessage id="el-title"/></h1>
              </Col>
              <Col md="auto" style={{paddingTop: "8px"}}>
                <Button onClick={this.openWindowWithPDF}><FormattedMessage id="el-view-pdf"/></Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{backgroundColor: "#ddd", padding: "5px", margin: "5px"}}>
            {
              this.state.lessonId ? 
              <p><FormattedMessage id="cl-lesson-id-display"/> <b>{this.state.lessonId}</b></p>
              :
              ""
            }
            {this.getAddressDisplay()}
        </div>
        {/* <div style={{display: "flex", flex: "1", justifyContent: "flex-end"}}> */}
          <span style={{textAlign: "end"}}><FormattedMessage id="ul-choose-lesson-status"/></span>
        {/* </div> */}
        {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> */}
          {/* <Button variant="success" onClick={this.updateLesson}>Update</Button> */}
          <LessonStatusToggle currentStatus={this.state.lessonStatus} onChange={this.onChange} stateName="lessonStatus"/>
        {/* </div> */}

        {/*  
              This is the component that has all of the lesson data
         */}
        {this.state.loading ?
        <div style={{textAlign: "Center", marginTop: "100px"}}>
          <Spinner animation="border" role="status"/> 
        </div>
          : 
        <LessonDetailsDisplay
          language={this.props.language}
          lessonContent={this.state}
          onChange={this.onChange}
        />} 


        {/* 
              These are the buttons that are floating on the lower left corner of the page
         */}

        <div 
          style={{
            bottom: "0",
            right: "0",
            position: "fixed",
            textAlign: "center",
            zIndex: "100"
          }}
        > 
          <Dropdown drop="up">
          <Dropdown.Toggle bsPrefix="invisibleMenu">
          <FontAwesomeIcon 
            icon={faEye} 
            size="4x" 
            color="#fff"
            style={{
              backgroundColor: "#f22", 
              height: "70px",
              width: "70px",
              padding: "5px", 
              borderRadius: "100%",
              margin: "10px"
            }} 
          />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={()=>this.setState({previewId: 0})}><FormattedMessage id="p-lesson"/></Dropdown.Item>
            <Dropdown.Item onClick={()=>this.setState({previewId: 1})}><FormattedMessage id="p-concept"/></Dropdown.Item>
            <Dropdown.Item onClick={()=>this.setState({previewId: 2})}><FormattedMessage id="p-definition"/></Dropdown.Item>
            <Dropdown.Item onClick={()=>this.setState({previewId: 3})}><FormattedMessage id="p-example"/></Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown>
          <p><FormattedMessage id="preview"/></p>
          {this.state.loading ? 
          <FontAwesomeIcon 
            icon={faSave}
            color="#fff"
            style={{
              backgroundColor: "#ccc",
              height: "70px",
              width: "70px",
              padding: "10px", 
              borderRadius: "100%",
              margin: "10px"
            }} 
          />
          : 
          <FontAwesomeIcon 
            onClick={this.superCompareLesson}
            icon={faSave}
            color="#fff"
            style={{
              backgroundColor: "#22f",
              height: "70px",
              width: "70px",
              padding: "10px", 
              borderRadius: "100%",
              margin: "10px"
            }} 
          />}
          
          {/* 
              This is an invisible component, it will appear when the user selects a preview
           */}
          <p><FormattedMessage id="p-save"/></p>
          <LessonHTMLViewer
            contentId={this.state.previewId}
            lessonId={this.state.lessonId}
            language={this.props.language}
            removePreviewId={this.removePreviewId}
            savingLesson={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

export default ViewLessonPage;