import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import ChecklistItem from '../checklistItem';

import './dynamic-checklist.css';

class DynamicChecklist extends Component {

  constructor(props){
    super(props);
    this.state = {
      checkStatus: []
    }
    this.handleSelect = this.handleSelect.bind(this);
    this.checkedUpdate = this.checkedUpdate.bind(this);
  }

  handleSelect = (item) => {
    let { parentState } = this.props;
    if(parentState.includes(item)){
      for(var i = 0; i < parentState.length; i++){
        if(parentState[i] === item){
          parentState.splice(i, 1);
          this.props.onChange(this.props.stateName, parentState);
          i--;
        }
      }
    } else{
      this.props.onChange(this.props.stateName, parentState.concat(item));
    }
  }

  checkedUpdate(){
    let content = [];
    this.props.content.forEach(item => {
      content.push(item);
    });
    let checkArray = [];
    this.props.items.forEach(item => {
      if(content.includes(item.code)){
        checkArray.push(true);
      }
    })
    this.setState({checkStatus: checkArray});
  }

  componentWillReceiveProps(){
    if(this.props.content){
      this.checkedUpdate();
    }
  }


  render(){
    return(
      <Form>
        <Form.Group>
          <fieldset>
            {this.props.items.length === 0 && !this.props.dontLoad ? 
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}><Spinner animation="border" role="status"/></div> 
            :
              this.props.items.map((item, index) => {
                return <ChecklistItem 
                  checked={this.state.checkStatus[index]}
                  key={index} 
                  text={item.title}
                  code={item.code}
                  handleSelect={this.handleSelect}
                  backgroundColor={this.props.backgroundColor}
                  /> 
              })
            }
          </fieldset>
        </Form.Group>
      </Form>
    );
  }
}

export default DynamicChecklist;