import axios from 'axios';
import { apiKey, apiGetTemplateDetails } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const getTemplateById = async function(language, id, type){
  const params = {
    apiKey: apiKey,
    token: sessionStorage.athenasToken,
    templateId: id,
    templateType: type,
    langCode: language,
    isForOutsideSource: true
  }
  try{
    const response = await axios.get(apiGetTemplateDetails, { params });
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        // console.log("Get template details response", response.data);
        return response.data.TemplateModifierRequestModel;
      }
    } else{
      alert('Session is not valid');
      logoutUser();
    }
  } catch(error){
    alert(error.response.data.Messages);
  }
}