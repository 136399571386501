import axios from 'axios';
import { apiKey, apiAdressUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const onAddressCall = async function(){
  let params = {
    "token": sessionStorage.athenasToken,
    "apiKey": apiKey
  }
  try {
    const response = await axios.get(apiAdressUrl, { params });
    if(response.data.SessionValid){
      if (response.data.TransactionValid) {
        // console.log("Response from lesson address call:", response);
        return response.data.AddressAt.map((item) => ({ title: item.AddressName, code: item.Id }));
      }
    }else {
      //session is not valid, user is sent to login
      alert('Session is not valid.');
      logoutUser();
    }
  }
  catch (error) {
    alert(error.response.data.Messages);
  }
}