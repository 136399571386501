import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Redirect } from 'react-router-dom';

import axios from 'axios';
import { apiKey, apiLessonCreate } from '../../config';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';
import { getLessonRules } from '../../server/getLessonRulesCall/get-lesson-rules';

import Button from 'react-bootstrap/Button';

import LessonDetailsDisplay from '../../components/lessonDetailsDisplay';
import { emptyLessonModel } from './utils';
// import { getTaxVerbIds } from './utils';

import PreSelectionPage from '../preSelectionPage';

import './create-lesson-page-style.css';

class CreateLessonPage extends Component {

  constructor(props){
    super(props);
    this.state={
      loading: false,
      lessonAddress: "",
      lessonCode: "",
      lessonConcept: "",
      lessonDefinitions: [],
      lessonDescription: "",
      lessonExample: [],
      lessonGrade: "",
      lessonKnowledgeLevel: "",
      lessonNo: "",
      lessonObjectives: [],
      lessonStrategies: [],
      lessonSubject: "",
      lessonTasks: [],
      // lessonTaxonomies: [],
      lessonHasRecorder: false,
      lessonTitle: "",
      lessonTransversalThemes: [],
      lessonUnit: [],
      lessonAudioSrc: "",
      lessonStandardCode: "",
      
    }
    this.onCreateLessonCall = this.onCreateLessonCall.bind(this);
    this.onChange = this.onChange.bind(this);
    this.redirectToEdit = this.redirectToEdit.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onChange(field, value){
    //Trick to add to state from multiple inputs with same function
    this.setState({[field]: value});
  }

  onCreateLessonCall(){
    //Set params with all the contents of the fields, this is the main structure of the call
    //create empty params
    let params = emptyLessonModel;
    let proceedUpload = true;
    this.setState({loading: true}, function(){
      // Lesosn Details section
      if(this.state.lessonTitle === ""){
        proceedUpload = false;
        this.setState({validateLessonTitle: true});
      }
      params.LessonModifierRequestModel.LessonModel.LessonTitle = this.state.lessonTitle;
      if(this.state.lessonNo === ""){
        proceedUpload = false;
        this.setState({validateLessonNo: true});
      }
      params.LessonModifierRequestModel.LessonModel.LessonNo = this.state.lessonNo;
      if(this.state.lessonDescription === ""){
        proceedUpload = false;
        this.setState({validateLessonDescription: true});
      }  
      params.LessonModifierRequestModel.LessonDetailModel.Description = this.state.lessonDescription;
      //This section was removed therefor we should not validate it or add it to the call
      // if(this.state.lessonKnowledgeLevel === ""){
      //   proceedUpload = false;
      //   this.setState({validateLessonKnowledgeLevel: true});
      // }
      // params.LessonModifierRequestModel.LessonDetailModel.KnowledgeLevel = this.state.lessonKnowledgeLevel;
      if(this.state.lessonConcept === ""){
        proceedUpload = false;
        this.setState({validateLessonConcept: true});
      }
      params.LessonModifierRequestModel.LessonDetailModel.Concept = this.state.lessonConcept; 
      
      if(this.state.lessonSubject === ""){
        proceedUpload = false;
        this.setState({validateLessonSubject: true});
      }
      params.LessonModifierRequestModel.LessonModel.SubjectCode = this.state.lessonSubject;
      if(this.state.lessonGrade === ""){
        proceedUpload = false;
        this.setState({validateLessonGrade: true});
      }
      params.LessonModifierRequestModel.LessonModel.LevelCode = this.state.lessonGrade;
      if(this.state.selectedStandardId === "" || this.state.selectedExpectationId === "" || this.state.selectedSubCodeId === "" || this.state.selectedSubMainSubCodeId === ""){
        proceedUpload = false;
        this.setState({validateLessonCode: true});
      }
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].StandardCode = this.state.lessonStandardCode;
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].StandardId = this.state.selectedStandardId;
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].CodeMainId = this.state.selectedExpectationId;
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].SubmainCodeId = this.state.selectedSubCodeId;
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].SubmainSubCodeId = this.state.selectedSubMainSubCodeId;
      if(this.state.lessonAddress === ""){
        proceedUpload = false;
        this.setState({validateLessonAddress: true});
      }
      params.LessonModifierRequestModel.AddressToLessonModelList[0].AddressId = this.state.lessonAddress;
      //Lesson Taxonomies
      // if(this.state.lessonTaxonomies.length === 0){
      //   proceedUpload = false;
      //   this.setState({validateLessonTaxonomy: true});
      // }
      // params.LessonModifierRequestModel.TaxonomyVerbsToLessonModelList = getTaxVerbIds(this.state.lessonTaxonomies);
      //Lesson objectives
      if(this.state.lessonObjectives.length === 0){
        proceedUpload = false;
        this.setState({validateLessonObjectives: true});
      }
      params.LessonModifierRequestModel.LessonObjectiveModelList = this.state.lessonObjectives;
      //Leesson tasks
      if(this.state.lessonTasks.length === 0){
        proceedUpload = false;
        this.setState({validateLessonTasks: true});
      }
      params.LessonModifierRequestModel.LessonPerformanceTaskModelList = this.state.lessonTasks;
      //Lesson strategies
      if(this.state.lessonStrategies.length === 0){
        proceedUpload = false;
        this.setState({validateLessonStrategies: true});
      }
      params.LessonModifierRequestModel.LessonStrategyModelList = this.state.lessonStrategies;
      //Lesson transversal themes
      if(this.state.lessonTransversalThemes.length === 0){
        proceedUpload = false;
        this.setState({validateLessonTransversalThemes: true})
      }
      params.LessonModifierRequestModel.LessonTransversalThemeModelList = this.state.lessonTransversalThemes;
      //Lesson definitions
      if(this.state.lessonDefinitions.length === 0){
        proceedUpload = false;
        this.setState({validateLessonDefinitions: true});
      }
      params.LessonModifierRequestModel.LessonDefinitionModelList = this.state.lessonDefinitions;
      //Lesson evxamples
      if(this.state.lessonExample.length === 0){
        proceedUpload = false;
        this.setState({validateLessonExample: true});
      }
      params.LessonModifierRequestModel.LessonStandardCodeModelList[0].StandardCode = this.state.lessonStandardCode;
      params.LessonModifierRequestModel.LessonExampleModelList = this.state.lessonExample;
      //Lesson recorder
      params.LessonModifierRequestModel.LessonModel.HasAudioRecorder = this.state.lessonHasRecorder ? 1 : 0;
      //lesson audio model
      params.LessonModifierRequestModel.LessonIntroAudioModel = { "AudioSrc": this.state.lessonIntroAudioSrc}
      if(this.state.lessonUnit.length > 0){
        let curricularMapItems = [];
        this.state.lessonUnit.forEach((item) => {
          curricularMapItems.push({"UnityId": item});
        })
        params.LessonModifierRequestModel.LessonCurricularMapModelList = curricularMapItems;
      }

      let self = this;
      // //Required params
      params.apiKey = apiKey;
      params.token = sessionStorage.athenasToken;
      params.langCode = this.props.language;
      // console.log(params);
      // console.log("state", this.state);
      // console.log(proceedUpload)
      proceedUpload=true;
      if(proceedUpload){
        console.log("lesson creation params: ", params);
        console.log("JSON Stringify de creacion de leccion:");
        console.log(JSON.stringify(params));
        axios.post(apiLessonCreate, params).then(function (response){
          console.log(response);
          if(response.data.TransactionValid && response.data.SessionValid){
            if(response.data.TransactionValid && response.data.SessionValid){
              alert(response.data.Messages);
              sessionStorage.setItem("lastSearch", response.data.LessonId);
              self.redirectToEdit();
            } else{
              alert("Error: " + response.data.Messages);
              self.setState({loading: false});
            }
          }
        }).catch(error => {
          alert(error.response.data.Messages);
          self.setState({loading: false});
        });
      }
    });
  }

  selectOption(value){
    onRulesCall().then((data) => {
      this.setState({
        fileRules: data
      });
    });
    getLessonRules(value).then((data) => {
      this.setState({
        lessonAddress: value,
        lessonAddressSelected: true,
        lessonRules: data
      })
    })
  }

  redirectToEdit(){
    this.setState({redirectToEdit: true})
  }
  getAddressDisplay(){
    switch(this.state.lessonAddress){
      case '1':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>VR</b></p>;
      case '2':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>Genial Web</b></p>;
      case '3':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>PDF</b></p>;
      case '4':
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>AR</b></p>;
      default:
        return <p><FormattedMessage id="cl-addressDisplay"/> <b>Loading</b></p>;
    }
  }

  render(){
    if (this.state.redirectToEdit) {
      return <Redirect to='/lesson' />
    }
    return(
      <div style={{padding: "10px"}}>
      {this.state.lessonAddressSelected 
        ? <div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <h1><FormattedMessage id="cl-title"/></h1>
              {this.state.loading ? <Button disabled variant="success"><FormattedMessage id="cl-title"/></Button> : <Button variant="success" onClick={this.onCreateLessonCall}><FormattedMessage id="cl-title"/></Button>}
            </div>
            <div style={{backgroundColor: "#ddd", padding: "5px", margin: "5px"}}>
              {this.getAddressDisplay()}
            </div>
            <LessonDetailsDisplay
              language={this.props.language}
              lessonContent={this.state}
              onChange={this.onChange}
            />
          </div>
        : <div>
            <PreSelectionPage
              title="cl-pre-creation"
              language={this.props.language}
              selectOption={this.selectOption}
            />
          </div>
      }
      </div>
    );
  }
}

export default CreateLessonPage;