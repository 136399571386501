import React, { Component } from 'react';

import ImageUploaderAndPreview from '../imageUploaderAndPreview';
import { FormattedMessage } from 'react-intl';



class ImageSpriteUploader extends Component{

  constructor(props){
    super(props);
    this.changeFramesValue = this.changeFramesValue.bind(this);
  }

  changeFramesValue(event){
    this.props.changeParentState(this.props.framesParentStateName, event.target.value);
  }

  render(){
    return(
      <div>
        <ImageUploaderAndPreview 
          label={this.props.label}
          stateName={this.props.stateName}
          content={this.props.content}
          changeParentState={this.props.changeParentState}
          language={this.props.language}
          require={this.props.require}
          fileRules={this.props.fileRules}
        />
        {this.props.frameInstructions ? <p><FormattedMessage id={this.props.frameInstructions}/></p> : ""}
        {this.props.requireFrames === "1" ? <p style={{color: "#F00"}}> <FormattedMessage id="td-required-frames"/> {this.props.requireFrames} <FormattedMessage id="td-plural-frames"/> </p> : "" }
        <div style={{display: "flex", marginLeft: "20px", justifyContent: "flex-start"}}>
          <p style={{marginTop: "20px"}}><FormattedMessage id="ct-frames-instructions"/></p>
          <input 
            style={{ marginLeft: "20px", marginTop: "20px", height: "25px"}} 
            type="number"
            onChange={this.changeFramesValue}
            value={this.props.framesValue === null ? "" : this.props.framesValue}
          />
        </div>
      </div>
    );
  }
}

export default ImageSpriteUploader;