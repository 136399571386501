export const emptyTemplateValidationRules = {
  "Id": null,
  "Name": null,
  "Desc": null,
  "CssCode": null,
  "CssDynamicCode": null,
  "HtmlCode": null,
  "HtmlDynamicCode": null,
  "JavascriptCode": null,
  "JavascriptDynamicCode": null,
  "ImgTemplateRequirements": null,
  "RequireCharacterAnimationImg": null,
  "RequireCharacterAnimationFrames": null,
  "RequireCharacterAnimationAudio": null,
  "RequireObjectTouchImg": null,
  "RequireObjectTouchImgFrames": null,
  "RequireObjectTouchAudio": null,
  "RequireObjectDropImg": null,
  "RequireObjectDropImgFrames": null,
  "RequireObjectDropAudio": null,
  "RequireBackgroundImg": null,
  "RequireBackgroundImgInfiniteScroll": null,
  "ModifiedBy": null,
  "CreatedAt": null,
  "UpdatedAt": null
}