import React, { Component } from 'react';

import { Form } from 'react-bootstrap';

class ChecklistItem extends Component{
  render(){
    const { text, handleSelect, code } = this.props;
    return(
      <Form.Check
        inline
        // style={{backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#ccc", color: "white", padding: "5px", marginTop: "3px", borderRadius: "3px"}}
        checked={this.props.checked}
        id={text}
        label={text}
        name="checkbox"
        value={text}
        onClick={() => { handleSelect(code)}}
      />
    );
  }
}

export default ChecklistItem;