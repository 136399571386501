import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RichTextInput from '../../../../../../components/richTextInput';
import TextAreaInput from '../../../../../../components/textAreaInput';
import SingleNumberInput from './subComponent/singleNumberInput';
import { FormattedMessage } from 'react-intl';

class QuizRubricComponent extends Component{
  constructor(props){
    super(props);
    this.state={
      rubric: "", 
      criterions: [],
      rubricOpen: false
    }
    this.onChange = this.onChange.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
    this.removeCriterion = this.removeCriterion.bind(this);
    this.removeDescriptor = this.removeDescriptor.bind(this);
    this.changeRubricContent = this.changeRubricContent.bind(this);
    this.changeCriterionContent = this.changeCriterionContent.bind(this);
    this.onChangeDescriptorValue = this.onChangeDescriptorValue.bind(this);
    this.changeCriterionDescriptor = this.changeCriterionDescriptor.bind(this);
    this.autoFillEscala = this.autoFillEscala.bind(this);
  }
  onChange(){
    let previousContent = this.props.content;
    previousContent[0].Rubric = this.state.rubric;
    previousContent[0].QuestionRubricCriterionModelList = this.state.criterions;
    this.props.onChange(previousContent);
  }
  addCriterion(){
    let currrentList = this.state.criterions;
    currrentList.push({Criterion: "", QuestionRubricCriterionValueModelList: [{Desc: "", Value: ""}]});
    this.setState({criterions: currrentList}, function(){this.onChange()});
  }
  addDescriptor(index){
    let currrentList = this.state.criterions;
    currrentList[index].QuestionRubricCriterionValueModelList.push({Desc: "", Value: ""});
    this.setState({criterions: currrentList}, function(){this.onChange()});
  }
  autoFillEscala(index, option){
    let content;
    if(option === 0){
      content = [
        {Desc: "Excelente", Value: "4"},
        {Desc: "Muy Bueno", Value: "3"},
        {Desc: "Promedio", Value: "2"},
        {Desc: "Puede Hacerlo Mejor", Value: "1"},
        {Desc: "No presentó", Value: "0"}
      ];
    } else{
      content = [
        {Desc: "Excellent", Value: "4"},
        {Desc: "Very Good", Value: "3"},
        {Desc: "Average", Value: "2"},
        {Desc: "Needs Improvement", Value: "1"},
        {Desc: "Did Not Answer", Value: "0"}
      ];
    }
    //Delete the current list
    let autoCompleteList = this.state.criterions;
    autoCompleteList[index].QuestionRubricCriterionValueModelList = content;
    this.setState({criterions: autoCompleteList}, function(){this.onChange()});

  }
  removeCriterion(index){
    let currrentList = this.state.criterions;
    currrentList.splice(index, 1);
    this.setState({criterions: currrentList}, function(){this.onChange()});
  }
  removeDescriptor(indexC, index){
    let currrentList = this.state.criterions;
    currrentList[indexC].QuestionRubricCriterionValueModelList.splice(index, 1);
    this.setState({criterions: currrentList}, function(){this.onChange()});
  }
  changeRubricContent(foo, content){
    this.setState({rubric: content}, function(){this.onChange();});
  }
  changeCriterionContent(index, content){
    let currentList = this.state.criterions;
    currentList[index].Criterion = content;
    this.setState({criterions: currentList}, function(){this.onChange()});
  }
  changeCriterionDescriptor(e, index, indexC){
    let currentList = this.state.criterions;
    currentList[indexC].QuestionRubricCriterionValueModelList[index].Desc = e;
    // console.log(e);
    this.setState({criterions: currentList}, function(){this.onChange()});
  }
  onChangeDescriptorValue(newValue, index, indexC){
    console.log(newValue, index, indexC)
    let currentList = this.state.criterions;
    currentList[indexC].QuestionRubricCriterionValueModelList[index].Value = newValue;
    this.setState({criterions: currentList}, function(){this.onChange()});
  }
  componentDidMount(){
    this.setState({
      rubric: this.props.content[0].Rubric ? this.props.content[0].Rubric : "",
      criterions: []
    });
  }
  componentWillReceiveProps(newProps){
      this.setState({
        rubric: newProps.content[0].Rubric ? newProps.content[0].Rubric: "",
        criterions: newProps.content[0].QuestionRubricCriterionModelList ? newProps.content[0].QuestionRubricCriterionModelList: [],
        rubricOpen: newProps.content[0].Rubric ? true : false
      });
      console.log("Rubric, open: ", this.state.rubricOpen);
  }
  render(){
    let background = "#ccc";
    let bakgroundAlternate = "#aaa";
    return(
      <div style={{marginBottom: "20px"}}>
        <h1><FormattedMessage id="qp-rubric"/></h1>
        <Button
          variant={this.state.rubricOpen ? "success" : "primary"}
          onClick={() => this.setState({rubricOpen: !this.state.rubricOpen})}
          aria-controls="rubricCollapse"
          aria-expanded={this.state.rubricOpen}>{this.state.rubricOpen ? "Close":"Open"}</Button>
        <Collapse in={this.state.rubricOpen}>
          <div id="rubricCollapse">
            <RichTextInput
              onChange={this.changeRubricContent}
              textName="rubric"
              content={this.props.content[0].Rubric}
            />
            {this.state.criterions.map((item, indexC) => {
              return (
                  <div key={indexC} style={indexC % 2 === 1 ? {backgroundColor: bakgroundAlternate} : {backgroundColor: background} }>
                  <div style={{padding: "10px"}}>
                    <h5><FormattedMessage id="qp-criteria"/> #{indexC + 1}</h5> 
                    <Container>
                      <Row>
                        <Col md="11">
                          <TextAreaInput
                            isForVR
                            parentStateKey={indexC}
                            onChange={this.changeCriterionContent}
                            content={item.Criterion}
                          />
                        </Col>
                        <Col md="1">
                          <Button variant="danger" onClick={() => this.removeCriterion(indexC)}><FormattedMessage id="qp-delete-criteria"/></Button>
                        </Col>
                      </Row>
                    </Container>
                    <h5><FormattedMessage id="qp-scale"/></h5>
                    {/* <Button onClick={() => this.autoFillEscala(indexC)}><FormattedMessage id="qp-autocomplete"/></Button> */}
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <FormattedMessage id="qp-autocomplete"/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.autoFillEscala(indexC, 0)}><FormattedMessage id="qp-spanish"/></Dropdown.Item>
                        <Dropdown.Item onClick={() => this.autoFillEscala(indexC, 1)}><FormattedMessage id="qp-english"/></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Container>
                      
                        {this.state.criterions[indexC].QuestionRubricCriterionValueModelList.map((item, index) => {
                          return(
                            <Row key={index}>
                              <div style={{ margin: "5px", padding: "5px"}}>
                                <input type="text" onChange={(e) => this.changeCriterionDescriptor(e.target.value, index, indexC)} value={item.Desc}/>
                                <Button variant="danger" className="buttonMargin" onClick={() => this.removeDescriptor(indexC, index)}><FormattedMessage id="qp-delete"/></Button>
                                <SingleNumberInput 
                                  indexC={indexC} 
                                  index={index} 
                                  onChange={this.onChangeDescriptorValue} 
                                  value={item.Value}
                                />
                              </div>
                            </Row>
                          )
                        })}
                        <Col>
                          <Button style={{margin: "10px"}} variant="dark" onClick={() => this.addDescriptor(indexC)}>+</Button>
                        </Col>
                    </Container>
                  </div>
                  </div>
              )
            })}
            <Button onClick={this.addCriterion}><FormattedMessage id="qp-add-criteria"/></Button>
          </div>
        </Collapse>
      </div>
    );
  }
}
export default QuizRubricComponent;