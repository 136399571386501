import React, { Component } from 'react';

import Table from 'react-bootstrap/Table';
import { FormattedMessage } from 'react-intl';
import Spinner from 'react-bootstrap/Spinner';

import LessonTableLinks from '../lessonTableLinks';

class DynamicTable extends Component {
  constructor(props){
    super(props);
    this.state={
      nextButtonStatus: false,
      previousButtonStatus: true,
      ascending: true
    }

    this.changeOrder = this.changeOrder.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changeOrder = () => {
    this.setState({
      ascending: !this.state.ascending
    }, function() {
      this.dataGeter();
    })
  }

  changePage = (direction) => {
    this.props.changePage(direction)
  }


  render() {
    return (
      <div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th><FormattedMessage id="tb-id"/></th>
              <th><FormattedMessage id="tb-title"/></th>
              <th><FormattedMessage id="tb-code"/></th>
              <th><FormattedMessage id="tb-subject"/></th>
              <th><FormattedMessage id="tb-status"/></th>
              <th><FormattedMessage id="tb-definitions"/></th>
              <th><FormattedMessage id="tb-examples"/></th>
              <th><FormattedMessage id="tb-quizes"/></th>
              <th><FormattedMessage id="tb-generate"/></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.data.length === 0 ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}><Spinner animation="grow"/></div> :
              this.props.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td key={(index + "ID").toString}>{item.id}</td>
                  <td key={(index) + "TITLE"}>{item.title}</td>
                  <td key={(index) + "CODE"}>{item.code}</td>
                  <td key={(index) + "SUBJECT"}>{item.subject}</td>
                  <td key={(index) + "STATUS"}>{item.status}</td>
                  <td key={(index) + "QUANTITY"}>{item.definitionQuantity}</td>
                  <td key={(index) + "EXAMPLE"}>{item.exampleQuantity}</td>
                  <td key={(index) + "QUIZ"}>{item.quizQuantity}</td>
                  <td key={(index) + "generar"}><LessonTableLinks language={this.props.language} links={item.linkItems}/></td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
export default DynamicTable;