import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import Spinner from 'react-bootstrap/Spinner';
import CommentTableInstance from './comment-table-instance';

import './style.css';
import ModalCommentCreate from './modal-comment-create';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

// import { getComments } from '../../server/getComments/get-comments';
// import { getCommentsSent } from '../../server/getCommentsSent/get-comments-sent';

class CommentTable extends Component{

  constructor(props){
    super(props);
    this.state={
      commentDataArray: [],
      modalCreateOpen: false
    }
    this.closeCreateModal = this.closeCreateModal.bind(this);
    this.updateAReceivedMessageAsRead = this.updateAReceivedMessageAsRead.bind(this);
    this.updateMessageCompleted = this.updateMessageCompleted.bind(this);
  }

  closeCreateModal(){
    this.setState({modalCreateOpen: false});
  }

  updateAReceivedMessageAsRead(id){
    let newArray = this.props.messagesReceived;
    newArray[id].read = "1";
    this.setState({MessagesReceived: newArray});
  }

  updateMessageCompleted(id){
    let newArray = this.props.messagesReceived;
    newArray[id].completed = "1";
    this.setState({MessagesReceived: newArray});
  }


  // componentDidMount(){
    
  //   getComments(this.props.language, this.props.entityId, this.props.entityType).then((data) => {
  //     console.log("Comments for me" ,data);
  //     let messagesReceived = []
  //     data.CommentDetailsModelList.forEach((item, index) => {
  //       messagesReceived.push({to: item.RecipientName ,from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed, read: item.Read, id: item.Id, userFromId: item.UserId});
  //     })  
  //     this.setState({MessagesReceived: messagesReceived});
  //   });



  //   getCommentsSent(this.props.language, this.props.entityId, this.props.entityType).then((data) => {
  //     console.log("Comments that I sent", data);
  //     let messagesSent = []
  //     data.CommentDetailsModelList.forEach((item, index) => {
  //       messagesSent.push({to: item.RecipientName, from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed});
  //     })  
  //     this.setState({MessagesSent: messagesSent});
  //   })
  // }


  render(){
    return(
      <div>
        <div>
          <Button size="lg" onClick={()=> {this.setState({modalCreateOpen: !this.state.modalCreateOpen})}}>+  <FormattedMessage id="cs-create"/></Button>
          <FontAwesomeIcon icon={faSync} size="2x" style={{paddingTop: "10px", marginLeft: "10px"}} onClick={this.props.loadComments}></FontAwesomeIcon>
        </div>
        
        <Tabs style={{marginTop: "10px"}} defaultActiveKey="Received" id="comment-tabs">
          <Tab eventKey="Received" title="Recividos">
            <CommentTableInstance 
              updateCompleted={this.updateMessageCompleted} 
              updateRead={this.updateAReceivedMessageAsRead} 
              data={this.props.messagesReceived} 
              commentType="received" 
              language={this.props.language}
              entityId={this.props.entityId}
              entityType={this.props.entityType}
              loadComments={this.props.loadComments}
            />
          </Tab>
          <Tab eventKey="Sent" title="Enviados">
            <CommentTableInstance 
              data={this.props.messagesSent} 
              commentType="sent" 
              language={this.props.language}
              loadComments={this.props.loadComments}
            />
          </Tab>
        </Tabs>
        
        <ModalCommentCreate 
          modalOpen={this.state.modalCreateOpen}
          closeModal={this.closeCreateModal}
          entityId={this.props.entityId}
          entityType={this.props.entityType}
          language={this.props.language}
          loadComments={this.props.loadComments}
        />
      </div>
    );
  }
}

export default CommentTable;