import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getTemplates } from '../../server/getTemplatesCall/get-templates-call';

import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
class ViewTemplatesPage extends Component{

  constructor(props){
    super(props);
    this.state={
      exampleTemplatesFromServer: null,
      definitionTemplatesFromServer: null,
      redirectToEdit: false
    }
    this.goToTemplate = this.goToTemplate.bind(this);
  }

  goToTemplate(id, type){
    console.log("Selected template to view", id);
    sessionStorage.setItem("TemplateId", id);
    sessionStorage.setItem("TemplateIdType", type);
    this.setState({redirectToEdit: true});
  }

  componentDidMount(){
    getTemplates(this.props.language, "EXA").then(data => {
      this.setState({exampleTemplatesFromServer: data});
    });
    getTemplates(this.props.language, "DEF").then(data => {
      this.setState({definitionTemplatesFromServer: data});
    });
  }

  render(){
    if(this.state.redirectToEdit){
      return(
        <Redirect to="edit-template"/>
      );
    } else{
      return(
        <div>
          <h1><FormattedMessage id="vt-title"/></h1>
          <h3><FormattedMessage id="vt-examples-title"/></h3>
          {this.state.exampleTemplatesFromServer ? this.state.exampleTemplatesFromServer.map((item, index) => {
            return (
              <div key={index} style={{margin: "10px"}}>
                <Button onClick={() => this.goToTemplate(item.Id, item.Type)}>{item.Name}</Button>
              </div>
            )
          })
          :
          ""}
          <h3><FormattedMessage id="vt-definitions-title"/></h3>
          {this.state.definitionTemplatesFromServer ? this.state.definitionTemplatesFromServer.map((item, index) => {
            return (
              <div key={index} style={{margin: "10px"}}>
                <Button onClick={() => this.goToTemplate(item.Id)}>{item.Name}</Button>
              </div>
            )
          })
          :
          ""}
        </div>
      );
    }
  }
}

export default ViewTemplatesPage;