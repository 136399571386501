import axios from 'axios';
import { apiKey, apiGetPdfLink } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const getLessonPdfLink = async function(language, lessonId){
  let params = {
    apiKey: apiKey,
    langCode: language,
    lessonId: lessonId,
    token: sessionStorage.athenasToken
  }
  try{
    const response = await axios.get(apiGetPdfLink, { params });
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        //Session and transaction is valid, return the values
        // console.log("Get pdf link response", response.data);
        return response.data.Location;
      } else{
        alert(response.data.Messages);
      }
    } else{
      //session is not valid, user is sent to login
      alert('Session is not valid.');
      logoutUser();
    }
  } catch(error){
    alert(error.response.data.Messages);
  }
}