import React, { Component } from 'react';
import CommentTable from '../../../../components/commentTable';


class QuizCommentsSection extends Component{
  render(){
    return(
      <div>
      <CommentTable
        entityType="QUI"
        entityId={this.props.state.quizId}
        language={this.props.language}
        messagesSent={this.props.messagesSent}
        messagesReceived={this.props.messagesReceived}
        loadComments={this.props.loadComments}
      />
    </div>
    );
  }
}

export default QuizCommentsSection;