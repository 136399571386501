import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { onTemplateCall } from '../../../../server/getLessonTemplateCall/lesson-template-call';

import ModalPopout from '../../../modalPopout';
import ModalTemplateContent from '../../../modalTemplateContent';
import TextAndFieldGenerator from '../../../textAndFieldGenerator';

class CreateLessonEjemplosSection extends Component{

  constructor(props){
    super(props);
    this.state={
      showModal: false
    }
    this.onChange = this.onChange.bind(this);    
    this.onChangeTemplates = this.onChangeTemplates.bind(this);
    this.openTemplateSelection = this.openTemplateSelection.bind(this);
  }

  onChange(content){
    this.props.parentStateHandle("lessonExample", content)
  }

  onChangeTemplates(id, content, selectedId){
    this.setState({selectionChange: true}, function(){
      this.props.parentStateHandle(id, content);
      this.props.selectExampleTemplate(selectedId)
    });
  }

  openTemplateSelection(){
    onTemplateCall("EXA", this.props.language).then(data => {
      console.log("Templates from server",data, this.props.selectedTemplates);
      let selectedTemplateIds = [];
      if(this.props.selectedTemplates !== null || this.props.selectedTemplates !== undefined){
        this.props.selectedTemplates.map((item) => {
          selectedTemplateIds.push({TemplateId: item.TemplateId});
          data.map((itemI, index) => {
            if(itemI.Id === item.TemplateId){
              data[index].isSelected = true;
              this.setState({
                selectedTemplateTitle: item.Name,
                selectedTemplateImage: item.ImgTemplate,
                selectedTemplateDescription: item.Desc ? item.Desc.substring(0, 50) +  (item.Desc.length > 50 ? "..." : "") : "",
              });
            }
            return true;
          })
          return true;
        });
      }
      this.setState({showModal: true, modalBodyData: data, selectedTemplateIds: selectedTemplateIds});
    })
  }
  
  componentDidMount(){
    if((this.props.selectedTemplates !== null || this.props.selectedTemplates !== undefined) && this.state.selectedTemplateTitle === undefined && this.state.selectionChange){
      onTemplateCall("EXA", this.props.language).then(data => {
        console.log(this.props.selectedTemplates)
        let selectedExaTemplateid = this.props.selectedTemplates[0] ? this.props.selectedTemplates[0].TemplateId : "";
        data.map((item) => {
          if(selectedExaTemplateid === item.Id){
            this.setState({
              selectedTemplateTitle: item.Name,
              selectedTemplateImage: item.ImgTemplate,
              selectedTemplateDescription: item.Desc.substring(0, 50) + (item.Desc.length > 50 ? "..." : ""),
              selectionChange: false
            });
          }
          return true;
        });
      });
    }
  }


  render(){
    return(
      <div>
        <h3><FormattedMessage id="cl-examples-title"/></h3>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        <p><FormattedMessage id="cl-field-instruction"/></p>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Button onClick={this.openTemplateSelection}><FormattedMessage id="cl-select-template"/></Button>
        </div>
        {this.props.selectedExampleTemplate ? 
          <div style={{backgroundColor: "lightgray", margin: "10px", marginLeft: "0px"}}>
            <h4 style={{margin: "10px"}}><FormattedMessage id="cl-selected-template"/></h4>
            <div style={{display: "flex"}}>
              <div>
                <Image style={{margin: "10px"}} width="200px" src={this.props.selectedExampleTemplate.img}/>
              </div>
              <div style={{margin: "10px"}}>
                <p><b><FormattedMessage id="cl-template-title"/>: </b>{this.props.selectedExampleTemplate.title}</p>
                <p><b><FormattedMessage id="cl-desc"/>: </b>{this.props.selectedExampleTemplate.description}</p>
              </div>
            </div>
          </div>
          : 
          <p><FormattedMessage id="cl-no-template"/></p>
        }
        
        <TextAndFieldGenerator 
          isDefinitionOrExample
          isVr={this.props.isVr}
          labelId="cl-example"
          onChange={this.onChange}
          state={this.props.state}
          lessonGrade={this.props.lessonGrade}
          lessonSubject={this.props.lessonSubject}
          limitFromRules={this.props.lessonRules ? this.props.lessonRules.ExampleMaxLength : null}
          fileRules={this.props.fileRules}
        />
        {this.state.showModal ? 
          <ModalPopout
            title={<FormattedMessage id="cl-select-template"/>}
            body={<ModalTemplateContent handleClose={() => {this.setState({showModal: false})}} data={this.state.modalBodyData} selectedIds={this.state.selectedTemplateIds} onChange={this.onChangeTemplates}/>}
            handleClose={() => {this.setState({showModal: false})}}
          /> 
        :
          ""
        }
      </div>
    );
  }
}

export default CreateLessonEjemplosSection;