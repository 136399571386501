import React, { Component } from 'react';
import { emptyTemplateValidationRules } from './utils';

import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut, faHandPointUp, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import LabelAndEditor from './subComponents/labelAndEditor';
import TypeSelector from './subComponents/typeSelector';
import ImageUploaderAndPreview from './subComponents/imageUploaderAndPreview';
import ImageSpriteUploader from './subComponents/imageSpriteUploaderAndPreview';
import TemplateCodeSelector from './subComponents/templateCodeSelector';
import TemplateInstructions from './subComponents/templateInstructions';
import BooleanSelector from './subComponents/booleanSelector';
import AudioUploader from './subComponents/audioUploader';

import './template-details-style.css';

class TemplateDetailsDisplay extends Component{

  constructor(props){
    super(props);
    this.state={
      codeValidationRules: emptyTemplateValidationRules
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeTemplateProp = this.onChangeTemplateProp.bind(this);
    this.onChangeTemplateInstructions = this.onChangeTemplateInstructions.bind(this);
    this.setValidationRules = this.setValidationRules.bind(this);
  }

  onChange(stateName, value){
    this.props.onChange(stateName, value);
  }

  onChangeTemplateProp(stateName, value){
    this.props.onChangeTemplate(stateName, value);
  }

  onChangeTemplateInstructions(value, lang){
    this.props.onChangeInstructions(value, lang);
  }

  setValidationRules(templateCodeObject){
    // console.log("Rules that where passed to display page: ", templateCodeObject);
    this.setState({
      codeValidationRules: templateCodeObject
    }, function() {
      this.onChange("templateCodeModel", templateCodeObject);
    });
  }

  render(){
    // console.log("details display template content", this.props.parentState)
    const { codeValidationRules } = this.state;
    return(
      <div>
        <p><FormattedMessage id="td-required-fields"/><span style={{color: "#f00"}}>*</span></p>
        <Tabs defaultActiveKey="GeneralInfo" id="template-tabs">
          <Tab eventKey="GeneralInfo" title={<FormattedMessage id="t-general-info"/>}>
          {/* General info Section */}
            <div className="tabContent">
              {/* Name */}
              <LabelAndEditor 
                label="ct-name"
                stateName="Name"
                content={this.props.parentState.templateModel.Name}
                changeParentState={this.onChangeTemplateProp}
                require="1"
              />
              {/* Description */}
              <LabelAndEditor 
                label="ct-descripcion"
                stateName="Desc"
                content={this.props.parentState.templateModel.Desc}
                changeParentState={this.onChangeTemplateProp}
                require="1"
              />
              {/* Type */}
              <TypeSelector
                label="ct-type"
                stateName="Type"
                content={this.props.parentState.templateModel.Type}
                changeParentState={this.onChangeTemplateProp}
                require="1"
              />
              {/* Template Code */}
              <hr/>
              <TemplateCodeSelector
                language={this.props.language}
                stateName="TemplateCodeId"
                changeParentState={this.onChangeTemplateProp}
                content={this.props.parentState.templateModel.TemplateCodeId}
                setValidationRules={this.setValidationRules}
              />
              {/* Image Template */}
              <hr/>
              <ImageUploaderAndPreview 
                label="ct-image-template"
                stateName="ImgTemplate"
                language={this.props.language}
                content={this.props.parentState.templateModel.ImgTemplate}
                changeParentState={this.onChangeTemplateProp}
                require="1"
                fileRules={this.props.parentState.fileUploadRules}
              />
              {/* Background Image */}
              <hr/>
              <ImageUploaderAndPreview 
                label="ct-background-image-template"
                stateName="BackgroundImg"
                language={this.props.language}
                content={this.props.parentState.templateModel.BackgroundImg}
                changeParentState={this.onChangeTemplateProp}
                require={codeValidationRules.RequireBackgroundImg}
                fileRules={this.props.parentState.fileUploadRules}
              />
              <BooleanSelector 
                label="ct-background-infinate-bool-instructions"
                stateName="BackgroundImgInfiniteScroll"
                content={this.props.parentState.templateModel.BackgroundImgInfiniteScroll}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireBackgroundImgInfiniteScroll}
              />
              <hr/>
              <h2><FormattedMessage id="ct-background-music"/></h2>
              <p><FormattedMessage id="ct-background-music-instructions"/></p>
              {/* Background Mp3 */}
              <AudioUploader
                label="ct-mp3-label"
                audioFormat="mp3"
                stateName="BackgroundMusic"
                content={this.props.parentState.templateModel.BackgroundMusic}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={null}
                fileRules={this.props.parentState.fileUploadRules}
              />
              {/* Background Ogg */}
              <AudioUploader
                label="ct-ogg-label"
                audioFormat="ogg"
                stateName="BackgroundMusicOgg"
                content={this.props.parentState.templateModel.BackgroundMusicOgg}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={null}
                fileRules={this.props.parentState.fileUploadRules}
              />
              {/* Annotation */}
              <LabelAndEditor 
                label="ct-annotation"
                stateName="Annotation"
                content={this.props.parentState.templateModel.Annotation}
                changeParentState={this.onChangeTemplateProp}
                require="1"
              />
              <hr/>
              {/* Template Instructions */}
              <h2><FormattedMessage id="ct-instructions-title"/></h2>
              <TemplateInstructions
                changeParentState={this.onChangeTemplateInstructions}
                enContent={this.props.parentState.templateInstructionsModelList[0].InstructionDesc}
                esContent={this.props.parentState.templateInstructionsModelList[1].InstructionDesc}
                require="1"
              />
            </div>
          </Tab>
          <Tab eventKey="Character" title={<FormattedMessage id="t-character"/>}>
            {/* Character Section */}
            <div className="tabContent">
              {/* Character */}
              <h2><FontAwesomeIcon icon={faUserAstronaut}/> <FormattedMessage id="ct-character"/></h2>
              <p><FormattedMessage id="ct-character-instructions"/><br/><FormattedMessage id="ct-character-second-instructions"/></p>
              <ImageSpriteUploader 
                label="ct-character-image"
                stateName="CharacterAnimationImg"
                content={this.props.parentState.templateModel.CharacterAnimationImg}
                changeParentState={this.onChangeTemplateProp}
                framesParentStateName="CharacterAnimationFrames"
                framesValue={this.props.parentState.templateModel.CharacterAnimationFrames}
                require={codeValidationRules.RequireCharacterAnimationImg}
                requireFrames={codeValidationRules.RequireCharacterAnimationFrames}
                frameInstructions="td-frame-instructions"
                fileRules={this.props.parentState.fileUploadRules}
              />
              <BooleanSelector 
                label="ct-character-infinate-bool-instructions"
                stateName="CharacterInfiniteLoop"
                content={this.props.parentState.templateModel.CharacterInfiniteLoop}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireCharacterAnimationImg}
              />
              <hr/>
              <p><FormattedMessage id="ct-character-audio-instructions"/></p>
              <AudioUploader
                label="ct-mp3-label"
                audioFormat="mp3"
                stateName="CharacterAnimationAudio"
                content={this.props.parentState.templateModel.CharacterAnimationAudio}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireCharacterAnimationAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
              <AudioUploader
                label="ct-ogg-label"
                audioFormat="ogg"
                stateName="CharacterAnimationAudioOgg"
                content={this.props.parentState.templateModel.CharacterAnimationAudioOgg}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireCharacterAnimationAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
            </div>
          </Tab>
          <Tab eventKey="TouchObject" title={<FormattedMessage id="t-touch-object"/>}>
            {/* Touch Object Section */}
            <div className="tabContent">
              {/* Touch Object */}
              <h2><FontAwesomeIcon icon={faHandPointUp}/> <FormattedMessage id="ct-touch-object"/></h2>
              <p><FormattedMessage id="ct-touch-object-instructions"/></p>
              <ImageSpriteUploader 
                label="ct-touch-object-image"
                stateName="ObjectTouchImg"
                content={this.props.parentState.templateModel.ObjectTouchImg}
                changeParentState={this.onChangeTemplateProp}
                framesParentStateName="ObjectTouchImgFrames"
                framesValue={this.props.parentState.templateModel.ObjectTouchImgFrames}
                frameInstructions="td-frame-instructions"
                require={codeValidationRules.RequireObjectTouchImg}
                requireFrames={codeValidationRules.RequireObjectTouchImgFrames}
                fileRules={this.props.parentState.fileUploadRules}
              />        
              <BooleanSelector 
                label="ct-touch-infinate-bool-instructions"
                stateName="ObjectTouchInfiniteLoop"
                content={this.props.parentState.templateModel.ObjectTouchInfiniteLoop}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectTouchImg}
              />
              <hr/>
              <p><FormattedMessage id="ct-touch-audio-instructions"/></p>
              <AudioUploader
                label="ct-mp3-label"
                audioFormat="mp3"
                stateName="ObjectTouchAudio"
                content={this.props.parentState.templateModel.ObjectTouchAudio}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectTouchAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
              <AudioUploader
                label="ct-ogg-label"
                audioFormat="ogg"
                stateName="ObjectTouchAudioOgg"
                content={this.props.parentState.templateModel.ObjectTouchAudioOgg}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectTouchAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
            </div>
          </Tab>
          <Tab eventKey="DropObject" title={<FormattedMessage id="t-drop-object"/>}>
            {/* Drop Object Section */}
            <div className="tabContent">
              {/* Drop Object */}
              <h2><FontAwesomeIcon icon={faBoxOpen}/> <FormattedMessage id="ct-drop-object"/></h2>
              <p><FormattedMessage id="ct-drop-object-instructions"/></p>
              <ImageSpriteUploader 
                label="ct-drop-object-image"
                stateName="ObjectDropImg"
                content={this.props.parentState.templateModel.ObjectDropImg}
                changeParentState={this.onChangeTemplateProp}
                framesParentStateName="ObjectDropImgFrames"
                framesValue={this.props.parentState.templateModel.ObjectDropImgFrames}
                require={codeValidationRules.RequireObjectDropImg}
                requireFrames={codeValidationRules.RequireObjectDropImgFrames}
                frameInstructions="td-frame-instructions"
                fileRules={this.props.parentState.fileUploadRules}
              />
              <BooleanSelector 
                label="ct-drop-infinate-bool-instructions"
                stateName="ObjectDropInfiniteLoop"
                content={this.props.parentState.templateModel.ObjectDropInfiniteLoop}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectDropImg}
              />
              <hr/>
              <p><FormattedMessage id="ct-drop-audio-instructions"/></p>
              <AudioUploader
                label="ct-mp3-label"
                audioFormat="mp3"
                stateName="ObjectDropAudio"
                content={this.props.parentState.templateModel.ObjectDropAudio}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectDropAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
              <AudioUploader
                label="ct-ogg-label"
                audioFormat="ogg"
                stateName="ObjectDropAudioOgg"
                content={this.props.parentState.templateModel.ObjectDropAudioOgg}
                changeParentState={this.onChangeTemplateProp}
                language={this.props.language}
                require={codeValidationRules.RequireObjectDropAudio}
                fileRules={this.props.parentState.fileUploadRules}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default TemplateDetailsDisplay;