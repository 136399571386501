import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import axios from 'axios';
import { apiGetUsersUrl, apiGetUserRoles, apiKey } from '../../config';

import DynamicUserTable from '../../components/dynamicUserTable';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

/** Edited by POR 2019.08.08 **/
class ViewUsersPage extends Component{

  constructor(props){
    super(props);
    this.state={
      usersFromServer: [], // Users
      roles: [], // Roles
      selectedRole: null, // Selected role Id
      selectedRoleName: null, // Selected role name
      tablePage: 1 //Current page on display

    };
    this.getUsers = this.getUsers.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.onChangeSearchUser = this.onChangeSearchUser.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  /** To get the users from server **/
  getUsers(){
    let self = this;
    const params = {
      "PageNumber": this.state.tablePage,
      "Limit": 10,
      "OrderBy": "ASC",
      "APIKey": apiKey,
      "Token": sessionStorage.athenasToken,
      "LangCode": this.props.language,
      "Username": this.state.username ? this.state.username : null,
      "RoleId": this.state.selectedRole ? this.state.selectedRole : null
    };


    axios.post(apiGetUsersUrl, params).then(function(response){

      if(response.data.TransactionValid && response.data.SessionValid){

        let users = null;
        if(response.data.UserInfo !== null) {
            users = response.data.UserInfo.map((item) => ({
            "userId": item.UserId,
            "userName": item.Username,
            "firstName": item.FullName.GivenName,
            "lastName": item.FullName.Surname,
            "lastSession": item.LastSession,
            "dateCreated": item.CreatedAt
          }));
        }

        self.setState({
          usersFromServer: users,
          pageTotals: response.data.PageTotals
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    });
  }

  /** To get the roles from server **/
  getRoles(){
    let self = this;
    const params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      langCode: this.props.language
    };

    axios.get(apiGetUserRoles, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        let roles = [];
        response.data.Roles.map((item) => {
          return roles.push({id: item.Id, name: item.Name});
        });
        self.setState({roles: roles});
      }
    });
  }

  /** Action when selecting a role **/
  selectRole(id, name){
    this.setState({
      selectedRole: id,
      selectedRoleName: name
    });
  }

  /** Action when typing a username to search **/
  onChangeSearchUser(event){
    this.setState({
      username: event.target.value
    });
  }

  /** Changing to previous page **/
  previousPage(){
    this.setState({
      tablePage: this.state.tablePage - 1
    }, function(){
      this.getUsers();
    });
  }

  /** Changing to next page **/
  nextPage(){
    this.setState({
      tablePage: this.state.tablePage + 1
    }, function(){
      this.getUsers();
    });
  }
  
  componentDidMount(){
    this.getUsers();
    this.getRoles();
  }

  render(){
    return(
      <div>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px"}}>
          <h4><FormattedMessage id="u-title"/></h4>
          <div style={{display: "flex", justifyContent: "space-evenly", }}>
            <Form.Control
              type="text" 
              name="username" 
              onChange={this.onChangeSearchUser}
            />
          </div>
          <DropdownButton id="dropdown-basic-button" title={<FormattedMessage id="u-select-role"/>}>
            {
              this.state.roles.map((item) => {
                return <Dropdown.Item
                    key={item.id} onClick={() => this.selectRole(item.id, item.name)}>{item.name}</Dropdown.Item>
              })
            }
          </DropdownButton>
          {this.state.selectedRoleName}
          <Button onClick={this.getUsers}><FormattedMessage id="vl-search"/></Button>
        </div>
        {this.state.usersFromServer !== null ?
          <DynamicUserTable data={this.state.usersFromServer}/> : null
        }
        <div className="pageControls">
          {/* Next page buttons */}
          <Button disabled={this.state.tablePage  === 1} onClick={this.previousPage}>{'<'}</Button>
            {this.state.tablePage}
          <Button disabled={this.state.pageTotals === this.state.tablePage} onClick={this.nextPage}>{'>'}</Button>
        </div>
      </div>
    );
  }
}

export default ViewUsersPage;