import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

import TextAreaInput from '../textAreaInput';
import { FormattedMessage } from 'react-intl';

import { getCommentUsers } from '../../server/getCommentsUsers/get-comment-users';
import { createCommentCall } from '../../server/postComment/post-comment';

class ModalCommentCreate extends Component{
  
  constructor(props){
    super(props);
    this.state={
      userName: "",
      users: [],
      messageContent: null,
      userRecipientId: null
    }
    this.sendComment = this.sendComment.bind(this);
    this.createComment = this.createComment.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
  }

  sendComment(commentModel){
    console.log("Sending Comment", this.state.messageContent, commentModel);
    if(createCommentCall(this.props.language, commentModel).then(() => {
      this.props.loadComments();
    })){
      this.props.closeModal();
    }
  }

  createComment(){
    const commentModel = {
      UserToId: this.state.userRecipientId,
      EntityId: this.props.entityId,
      Type: this.props.entityType,
      Comment: this.state.messageContent
    };
    let commentComplete = true;
    //User
    if(commentModel.UserTold === null){
      this.setState({userValidFlag: true});
      commentComplete = false;
    }
    //Message
    if(commentModel.Comment === null){
      this.setState({messageValidFlag: true});
      commentComplete = false;
    }

    if(commentComplete){
      this.sendComment(commentModel);
    }
  }

  onChangeMessage(content){
    this.setState({messageContent: content});
  }

  componentWillMount(){
    let self = this;
    getCommentUsers("es").then(function(data){
      console.log("Users for sending comments", data);
      self.setState({
        users: data.UserFullNameModelList
      })
    })
  }

  render(){
    return(
      <div>
        <Modal show={this.props.modalOpen} onHide={this.props.closeModal}>
          <Modal.Title style={{marginLeft: "10px"}}><FormattedMessage  id="cs-comment"/> {this.props.messageId}</Modal.Title>
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div>
                <p>{this.state.userValidFlag ? <p style={{color: "red"}}>*Required</p> : ""}<FormattedMessage id="cs-for"/> {this.state.userName ? this.state.userName : ""}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FormattedMessage id="cs-user-list"/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.users.map((item, index) => {
                      return <Dropdown.Item 
                        key={index} 
                        eventKey={index} 
                        onClick={() => {this.setState({userName: item.GivenName + " " + item.Surname, userRecipientId: item.UserId})}}>
                          {item.GivenName + " " + item.Surname}
                      </Dropdown.Item>
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div style={{backgroundColor: "rgba(0,0,0,0.2)", height: "200px", padding: "20px", marginTop: "10px"}}>
              {this.state.messageValidFlag ? <p style={{color: "red"}}>*Required</p> : ""}
              <TextAreaInput textType="TextArea" onChange={this.onChangeMessage}/>
              <Button style={{marginTop: '10px'}} onClick={this.createComment}><FormattedMessage id="cs-send"/></Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeModal}>
              <FormattedMessage id="option-close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalCommentCreate;