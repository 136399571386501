import React, { Component } from 'react';
import axios from 'axios';
import { apiKey, apiGetStandard, apiGetExpectations, apiGetSubCodes, apiGetSubMainSubCodes } from '../../config';

// import StandardCodeDisplay from './subComponents/standard-code-display';
import DropdownSelector from './subComponents/dropdownSelector/dropdown-selector';
import { FormattedMessage } from 'react-intl';

class StandardCodeSelector extends Component{
  constructor(props){
    super(props);
    this.state={
      firstLoad: true,
      standardCodesFromServer: [],
      expectationsFromServer: [],
      subcodesFromServer: [],
      subMainSubCodesFromServer: [],
      selectedStandard: "",
      selectedExpectation: "",
      selectedSubcode: "",
      selectedSubMainSubCode: "",
      subjectFromProps: "",
      gradeFromProps: "",
      standardFromProps: "",
      expectativeFromProps: "",
      subcodeFromProps: "",
      subMainSubCodeFromProps: "",
      subjectCode: ""
    }
    this.getStandards = this.getStandards.bind(this);
    this.getExpectations = this.getExpectations.bind(this);
    this.getSubCodes = this.getSubCodes.bind(this);
    this.getSubMainSubCodes = this.getSubMainSubCodes.bind(this);
    this.onSelectStandard = this.onSelectStandard.bind(this);
    this.onSelectExpectation = this.onSelectExpectation.bind(this);
    this.onSelectSubcode = this.onSelectSubcode.bind(this);
    this.onSelectSubmainSubcode = this.onSelectSubmainSubcode.bind(this);
    this.loadStandardFromProps = this.loadStandardFromProps.bind(this);
    this.loadExpectationFromProps = this.loadExpectationFromProps.bind(this);
    this.loadSubCodesFromProps = this.loadSubCodesFromProps.bind(this);
    this.loadSubMainSubCodesFromProps = this.loadSubMainSubCodesFromProps.bind(this);
  }

  getStandards(){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      subjectCode: this.props.subjectCode,
      langCode: this.props.language
    }
    axios(apiGetStandard, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        let standards = [];
        response.data.PRStandards.map((item) => {
            return standards.push({id: item.Id, code: item.Code, description: item.Name});
        });
        self.setState({ standardCodesFromServer: standards });
      }
    });
  }

  getExpectations(){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      standardId: this.state.selectedStandard.id,
      langCode: this.props.language,
      grade: this.props.grade
    }
    axios.get(apiGetExpectations, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        // console.log("Response from expectations", response)
        let expectations = [];
        response.data.Expectations.map((item) => {
          return expectations.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({expectationsFromServer: expectations});
      }
    });
  }

  getSubCodes(){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      expectationId: this.state.selectedExpectation.id,
      langCode: this.props.language
    }
    axios.get(apiGetSubCodes, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        // console.log(response.data)
        let subcodes = [];
        response.data.SubCodes.map((item) => {
          return subcodes.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({subcodesFromServer: subcodes});
      }
    })
  }

  getSubMainSubCodes(){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      subcodeId: this.state.selectedSubcode.id,
      langCode: this.props.language
    }
    axios.get(apiGetSubMainSubCodes, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        // console.log(response.data)
        let submainSubcodes = [];
        response.data.SubmainSubCodes.map((item) => {
          return submainSubcodes.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({subMainSubCodesFromServer: submainSubcodes});
      }
    })
  }

  onSelectStandard(value){
    this.setState({
      selectedStandard: value, 
      selectedExpectation: "", 
      selectedSubcode: "", 
      selectedSubMainSubCode: "",
      expectationsFromServer: [], 
      subcodesFromServer: []
    }, () => { 
      this.getExpectations();
      this.props.onChange("selectedStandardId", value.id);
      this.props.onChange("selectedExpectationId", "");
      this.props.onChange("selectedSubCodeId", "");
      this.props.onChange("selectedSubMainSubCodeId", "");
    });
  }
  onSelectExpectation(value){
    this.setState({
      selectedExpectation: value,
      selectedSubcode: "",
      selectedSubMainSubCode: "",
      subcodesFromServer: []
    }, () => { 
      this.getSubCodes();
      this.props.onChange("selectedExpectationId", value.id);
      this.props.onChange("selectedSubCodeId", "");
      this.props.onChange("selectedSubMainSubCodeId", "");
    });
  }
  onSelectSubcode(value){
    this.setState({
      selectedSubcode: value,
      selectedSubMainSubCode: ""
    }, () => {  
      this.getSubMainSubCodes()
      this.props.onChange("selectedSubCodeId", value.id);
      this.props.onChange("selectedSubMainSubCodeId", "");
    });
  }
  onSelectSubmainSubcode(value){
    this.setState({
      selectedSubMainSubCode: value
    }, () => {
      this.props.onChange("selectedSubMainSubCodeId", value.id);
    })
  }

  loadStandardFromProps(id){
    //Use id to get the standard object
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      subjectCode: this.state.subjectFromProps,
      langCode: this.props.language
    }
    axios(apiGetStandard, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        let standards = [];
        response.data.PRStandards.map((item) => {
            return standards.push({id: item.Id, code: item.Code, description: item.Name});
        });
        self.setState({ standardCodesFromServer: standards }, function(){
          self.state.standardCodesFromServer.forEach(function(item){
            if(item.id === id){
              self.setState({
                selectedStandard: item
              }, function(){
                if(this.state.expectativeFromProps && this.state.selectedExpectation === ""){
                  this.loadExpectationFromProps(this.state.expectativeFromProps);
                } else{
                  self.setState({firstLoad: false});
                }
              }); 
            }
          });
        });
      }
    });
  }

  loadExpectationFromProps(id){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      standardId: this.state.selectedStandard.id,
      langCode: this.props.language,
      grade: this.state.gradeFromProps
    }
    axios.get(apiGetExpectations, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        let expectations = [];
        response.data.Expectations.map((item) => {
          return expectations.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({expectationsFromServer: expectations}, function(){
          self.state.expectationsFromServer.forEach(function(item){
            if(item.id === id){
              self.setState({
                selectedExpectation: item,
              },function(){
                if(this.state.subcodeFromProps && this.state.selectedSubcode === ""){
                  this.loadSubCodesFromProps(this.state.subcodeFromProps);
                } else{
                  self.setState({firstLoad: false});
                }
              });
            }
          });
        });
      }
    });
  }

  loadSubCodesFromProps(id){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      expectationId: this.state.selectedExpectation.id,
      langCode: this.props.language
    }
    axios.get(apiGetSubCodes, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        // console.log(response.data)
        let subcodes = [];
        response.data.SubCodes.map((item) => {
          return subcodes.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({subcodesFromServer: subcodes}, function(){
          self.state.subcodesFromServer.forEach(function(item){
            if(item.id === id){
              self.setState({
                selectedSubcode: item
              }, function(){
                if(this.state.subMainSubCodeFromProps && this.state.selectedSubMainSubCode === ""){
                  this.loadSubMainSubCodesFromProps(this.state.subMainSubCodeFromProps);
                } else{
                  self.setState({firstLoad: false});
                }
              });
            }
          })
        });
      }
    })
  }

  loadSubMainSubCodesFromProps(id){
    let self = this;
    let params = {
      token: sessionStorage.athenasToken,
      apiKey: apiKey,
      subcodeId: this.state.selectedSubcode.id,
      langCode: this.props.language
    }
    axios.get(apiGetSubMainSubCodes, { params }).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        // console.log(response.data)
        let submainSubcodes = [];
        response.data.SubmainSubCodes.map((item) => {
          return submainSubcodes.push({id: item.Id, code: item.Code, description: item.Desc});
        });
        self.setState({subMainSubCodesFromServer: submainSubcodes}, function(){
          self.state.subMainSubCodesFromServer.forEach(function(item){
            if(item.id === id){
              self.setState({
                selectedSubMainSubCode: item,
                firstLoad: false
              });
            }
          })
        });
      }
    })
  }

  componentWillReceiveProps(nextProps){
    // here is where the props will arrive
    
    if(this.props.loading){
      // console.log("First load in standard code ran")
      if(nextProps.selectedStandardId && this.state.selectedStandard === ""){
        this.setState({
          standardFromProps: nextProps.selectedStandardId,
          expectativeFromProps: nextProps.selectedExpectationId,
          subcodeFromProps: nextProps.selectedSubCodeId,
          subMainSubCodeFromProps: nextProps.selectedSubMainSubCodeId,
          subjectFromProps: nextProps.subjectCode,
          gradeFromProps: nextProps.grade
        }, function(){
          // console.log("Is this really  working?",nextProps.selectedStandardId, nextProps.selectedExpectationId, nextProps.selectedSubCodeId, nextProps.selectedSubMainSubCodeId) 
          this.loadStandardFromProps(this.state.standardFromProps);
        });
      } else{
        this.setState({firstLoad: false})
      }
    } else{
      if(nextProps.subjectCode !== this.state.subjectFromProps || nextProps.grade !== this.state.gradeFromProps){
        this.setState({
          subjectFromProps: nextProps.subjectCode,
          gradeFromProps: nextProps.grade,
          selectedStandard: "",
          selectedExpectation: "", 
          selectedSubcode: "",
          selectedSubMainSubCode: "" 
          }, function(){
            this.getStandards();
            this.props.onChange("selectedStandardId", "");
            this.props.onChange("selectedExpectationId", "");
            this.props.onChange("selectedSubCodeId", "");
            this.props.onChange("selectedSubMainSubCodeId", "");
        });
      }
    }
  }

  render(){
    const { 
      selectedStandard, 
      selectedExpectation, 
      selectedSubcode,
      selectedSubMainSubCode,
      standardCodesFromServer,
      expectationsFromServer,
      subcodesFromServer,
      subMainSubCodesFromServer
    } = this.state
    return(
      <div className="standardCodeSelector">
        <h4><FormattedMessage id="cl-code-input"/></h4>
        {/* <StandardCodeDisplay 
          standard={selectedStandard ? selectedStandard.code : ""} 
          expectation={selectedExpectation ? selectedExpectation.code : ""} 
          subcode={selectedSubcode ? selectedSubcode.code : ""}
          submainSubCode={selectedSubMainSubCode ? selectedSubMainSubCode : ""}
        /> */}
        <div style={{display: "flex", flexDirection: "row"}}>
          <DropdownSelector 
            content={standardCodesFromServer} 
            buttonTitle={selectedStandard ? selectedStandard.code : <FormattedMessage id="cl-standard"/>}
            onChange={this.onSelectStandard}
            noContentMessage="Debe seleccionar una materia"
          />
          <DropdownSelector 
            content={expectationsFromServer} 
            buttonTitle={selectedExpectation ? selectedExpectation.code : <FormattedMessage id="cl-expectation"/>}
            onChange={this.onSelectExpectation}
            noContentMessage="Debe seleccionar un standard"
          />
          <DropdownSelector 
            content={subcodesFromServer} 
            buttonTitle={selectedSubcode ? selectedSubcode.code : <FormattedMessage id="cl-indicator"/>}
            onChange={this.onSelectSubcode}
            noContentMessage="Debe seleccionar una expectativa"
          />
          <DropdownSelector 
            content={subMainSubCodesFromServer} 
            buttonTitle={selectedSubMainSubCode ? selectedSubMainSubCode.code : <FormattedMessage id="cl-subindicator" />} 
            onChange={this.onSelectSubmainSubcode}
            noContentMessage="Debe seleccionar un subcodigo"
          />
        </div>
      </div>
    );
  }
}

export default StandardCodeSelector;