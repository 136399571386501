import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';

import { FormattedMessage } from 'react-intl';

class QuizExamRadioSelection extends Component{

  constructor(props){
    super(props);
    this.state={
      selectecValue: ""
    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(value){
    this.setState({
      selectecValue: value
    });
  }

  render(){
    const { changeIsExam, currentSelection } = this.props
    return(
      <div>
        <br/>
        <h5><FormattedMessage id="qp-quiz-or-exam"/></h5>
        <Form className="checklist">
          <Form.Group>
            <Form.Check
              inline
              label="Practice"
              name="radioButton"
              type="radio"
              onClick={() => changeIsExam("0")}
              checked={currentSelection === "0"}
              onChange={() => this.onChange("0")}
            />
            <Form.Check
              inline
              label="Exam"
              name="radioButton"
              type="radio"
              onClick={() => changeIsExam("1")}
              checked={currentSelection === "1"}
              onChange={() => this.onChange("1")}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default QuizExamRadioSelection;