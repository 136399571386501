import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import { FormattedMessage } from 'react-intl';

class ReadyToPublishFlag extends Component{
  
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  
  onClick(){

    if(this.props.ready === "1"){
      this.props.onChange("quizReadyToPublish", "0");
    } if(this.props.ready === "0"){
      this.props.onChange("quizReadyToPublish", "1");
    }
  }
  
  render(){
    return(
      <div>
        <Form.Check checked={this.props.ready === "1"} type="checkbox" label={<FormattedMessage id="qp-ready-to-publish"/>} onClick={this.onClick}/>
      </div>
    );
  }
}

export default ReadyToPublishFlag;