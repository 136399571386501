import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

import TextAreaInput from '../textAreaInput';

import { FormattedMessage } from 'react-intl';

import { createCommentCall } from '../../server/postComment/post-comment';

class ModalCommentReadRespond extends Component{

  constructor(props){
    super(props);
    this.state={
      respondSelected: false,
      messageContent: ""
    }
    this.postResponse = this.postResponse.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.createComment = this.createComment.bind(this);
  }

  onChangeMessage(content){
    this.setState({messageContent: content});
  }

  postResponse(commentModel){
    console.log("Sending Comment", this.state.messageContent, commentModel);
    if(createCommentCall(this.props.language, commentModel).then(() => {
      this.props.loadComments();
    })){
      this.props.closeModal();
    }
  }

  createComment(){
    console.log("MessageModel", this.props.messageModel)
    const commentModel = {
      UserToId: this.props.messageModel.userFromId,
      EntityId: this.props.entityId,
      Type: this.props.entityType,
      Comment: this.state.messageContent
    };
    let commentComplete = true;
    //User
    if(commentModel.UserTold === null){
      this.setState({userValidFlag: true});
      commentComplete = false;
    }
    //Message
    if(commentModel.Comment === null){
      this.setState({messageValidFlag: true});
      commentComplete = false;
    }

    if(commentComplete){
      this.postResponse(commentModel);
    }
  }

  render(){
    const { respondSelected } = this.state;
    return(
      <div>
        <Modal show={this.props.modalOpen} onHide={this.props.closeModal}>
          <Modal.Title style={{marginLeft: "10px"}}><FormattedMessage  id="cs-comment"/></Modal.Title>
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div>
                <p><FormattedMessage id="cs-for"/>{this.props.messageModel ? this.props.messageModel.to : ""}</p>
                <p><FormattedMessage id="cs-from"/>{this.props.messageModel ? this.props.messageModel.from : ""}</p>
              </div>
              <div>
                <Button style={{margin: "10px"}} onClick={() => {this.props.updateCompleted(this.props.messageId)}}><FormattedMessage id="cs-mark-completed"/></Button>
                <Button style={{margin: "10px"}} aria-controls="respondCollapse" aria-expanded={respondSelected} onClick={() => {this.setState({respondSelected: !respondSelected})}}><FormattedMessage id="cs-respond"/></Button>
              </div>
            </div>
            <div style={{backgroundColor: "rgba(0,0,0,0.2)", height: "200px", padding: "20px"}}>
              {this.props.messageModel ? this.props.messageModel.comment : ""}
            </div>
            <Collapse in={respondSelected}>
              <div id="respondCollapse">
                <TextAreaInput label="Respuesta" textType="textarea" onChange={(this.onChangeMessage)}/>
                <Button style={{marginTop: "10px"}} onClick={this.createComment}>Send</Button>
              </div>
            </Collapse>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeModal}>
              <FormattedMessage id="option-close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalCommentReadRespond;