import axios from 'axios';
import { apiKey, apiTemplateUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const onTemplateCall = async function(templateCode, language){
  let params = {
    "token": sessionStorage.athenasToken,
    "apiKey": apiKey,
    "templateType": templateCode,
    "langCode": language
  }

  try{
    const response = await axios.get(apiTemplateUrl, { params });
    // console.log("Template Response", response);
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        //Session and Transaction is valid, return the values
        return response.data.TemplateModelList;
      }
    } else {
      //Session is not valid, user is sent to login
      alert('Session is not valid');
      logoutUser();
    }

  }
  catch(error){
    alert(error.response.data.Messages);
  }
}