import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';


import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';

import '../../assets/styles/nav-bar-style.css';
import image from '../../assets/images/athenaslogo.png'

class NavBar extends Component{

  logout = () => {
    sessionStorage.removeItem("athenasToken");
    this.props.refresh();
  }

  render(){
    const { intl } = this.props;
    return(
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Navbar.Brand>
          <LinkContainer to="./">
            <img src={image} alt="Athenas v2" height="auto" width="150px"/>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className=" mr-auto" justify="true">
            <NavDropdown title={intl.formatMessage({id: 'nav-lessons'})} id="nav-dropdown">
              <LinkContainer to="/view-lessons">
                <NavDropdown.Item><FormattedMessage id="nav-view"/></NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/create-lesson">
                <NavDropdown.Item><FormattedMessage id="nav-create"/></NavDropdown.Item>
              </LinkContainer>
              {/* <NavDropdown.Item><FormattedMessage id="nav-edit"/></NavDropdown.Item> */}
            </NavDropdown>
            {/* <NavDropdown className="disabled nav-dropdown" title={intl.formatMessage({id: 'nav-reports'})} id="nav-dropdown">
              <NavDropdown.Item><FormattedMessage id="nav-audit"/></NavDropdown.Item>
            </NavDropdown> */}
            <NavDropdown title={intl.formatMessage({id: 'nav-template'})} id="nav-dropdown">
              <LinkContainer to="/view-templates">
                <NavDropdown.Item><FormattedMessage id="nav-view"/></NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/create-template">
                <NavDropdown.Item><FormattedMessage id="nav-create"/></NavDropdown.Item>
              </LinkContainer>
              {/* <NavDropdown.Item><FormattedMessage id="nav-edit"/></NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Item>
              <LinkContainer to="/audits">
                <Nav.Link className="disabled nav-link">
                  <FormattedMessage id="nav-audit"/>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to="/Generations">
                <Nav.Link className="disabled nav-link">
                  <FormattedMessage id="nav-generations"/>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {/* Added by POR 2019.08.08 */}
            <NavDropdown title={intl.formatMessage({id: 'nav-users'})} id="nav-dropdown">
              <LinkContainer to="/view-users">
                <NavDropdown.Item><FormattedMessage id="nav-view"/></NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/create-users">
                <NavDropdown.Item><FormattedMessage id="nav-create"/></NavDropdown.Item>
              </LinkContainer>
              {/* <NavDropdown.Item><FormattedMessage id="nav-edit"/></NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title={intl.formatMessage({id: 'nav-language'})} id="nav-dropdown">
              <NavDropdown.Item onClick={this.props.changeToES}><FormattedMessage id="nav-spanish"/></NavDropdown.Item>
              <NavDropdown.Item onClick={this.props.changeToEN}><FormattedMessage id="nav-english"/></NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link onClick={this.logout} to="./">
                <FormattedMessage id="nav-logout"/>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default injectIntl(NavBar);