import React, { Component } from 'react';
import { Editor }  from '@tinymce/tinymce-react';

import { tinyMCEkey } from '../../config';


class TinyMCE extends Component{

  constructor(props) {
    super(props);

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange = (content) => {
    this.setState({ 
      content: content
    }, function(){
      this.props.onChange(this.props.parentStateKey, content);
    });
  }

  componentDidMount(){
    this.setState({
      content: this.props.content
    })
  }


  render(){
    return(
      <Editor
        apiKey={tinyMCEkey}
        initialValue={this.props.content ? this.props.content : ""}
        value={this.props.content}
        init={{
          plugins: 'link image code media table lists',
          toolbar: 'undo redo | indent bold italic underline forecolor table numlist bullist | alignleft aligncenter alignright | code | tiny_mce_wiris_formulaEditor addColonsButton ',
          // WirisButton
          setup: function(editor){
            editor.ui.registry.addButton('addColonsButton', {
              icon: 'quote',
              tooltip: 'Insert colons',
              onAction: () => editor.insertContent("“”")
            });
            // editor.ui.registry.addButton('WirisButton', {
            //   text: '<image src="https://www.hearne.software/Images/Software-Icons/Software-Header-Icons/MathType-Square-v2.aspx?width=24&height=24"/>',
            //   tooltip: 'Insert a math equation - MathType',
            //   onAction: () => {
            //     // editor.editorCommands.commands.exec.tiny_mce_wiris_openformulaeditorchemistry()
            //     console.log(editor)
            //   }
              //console.log(editor)
              //editor.editorCommands.commands.exec.tiny_mce_wiris_openFormulaEditor()
              // tiny_mce_wiris_openFormulaEditor
              
            // });
          },
          menubar: false,
          paste_data_images: true,
          external_plugins: {
            'tiny_mce_wiris': 'https://athenas.genialskills.com/wiris.js'
          },
          formats: {
            underline: {inline: 'u'}
          },
          audio_template_callback: function(d) {
            return '<audio controls>\n<source src="' + d.source1 + '"' + (d.source1mime ? ' type="' + d.source1mime + '"' : '') + ' />\n</audio>';
          }
        }}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}

export default TinyMCE;