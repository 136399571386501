import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faCogs, faUsers, faHashtag, faQuestion, faPenSquare } from '@fortawesome/free-solid-svg-icons';

import './dashboard-item.css';

class DashboardItem extends Component{
  render(){

    const { color, imgSrc, iconColor, isDissabled} = this.props;
    let icon;
    switch(imgSrc){
      case "checklist":
        icon = faTasks;
        break;
      case "cogs":
        icon = faCogs;
        break;
      case "users":
        icon = faUsers;
        break;
      case "hashtag":
        icon = faHashtag;
        break;
      case "pencil":
        icon = faPenSquare;
        break;
      default: 
        icon = faQuestion;
        break;
    }
    
    return(
      <div>
      { isDissabled ? 
      <div className="itemContainer">
        <div className="circle" style={{backgroundColor: "#888888" }}>
          <FontAwesomeIcon icon={icon} size="4x" color="#444444"/>
        </div>
      <p className="itemLabel">{this.props.children}</p>
      </div> : 
      <Link to={this.props.route}>
        <div className="itemContainer">
          <div className="circle" style={{backgroundColor: color }}>
            <FontAwesomeIcon icon={icon} size="4x" color={iconColor}/>
          </div>
          <p className="itemLabel">{this.props.children}</p>
        </div>
      </Link>}
      </div>
    );
    
  }
}

export default DashboardItem;