import React, { Component } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { FormattedMessage } from 'react-intl';

import axios from 'axios';
import { apiLessonStatusesUrl, apiKey } from '../../config';

class LessonStatusToggle extends Component{

  constructor(props){
    super(props);
    this.state={
      statusFromServer: [],
      currentStatus: this.props.currentStatus
    }
    this.getStatusesFromServer = this.getStatusesFromServer.bind(this);
    this.chooseStatus = this.chooseStatus.bind(this);
    this.getSelectedStatusTitle = this.getSelectedStatusTitle.bind(this);
  }

  getStatusesFromServer(){
    let self = this;
    let params = {
      "token": sessionStorage.athenasToken,
      "apiKey": apiKey
    }
    axios.get(apiLessonStatusesUrl, {params}).then(function (response){
      
      if(response.data.TransactionValid && response.data.SessionValid){
        const statusStrings = response.data.StatusOfLessons.map((item) => ({title: item.Name, code: item.Id}));
        self.setState({
          statusFromServer: statusStrings
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages);
    }); 
  }

  chooseStatus(statusId){
    this.setState({
      currentStatus: statusId
    }, function(){
      this.props.onChange(this.props.stateName, this.state.currentStatus);
    });
  }

  getSelectedStatusTitle(){
    let result;
    this.state.statusFromServer.forEach((item) => {
      if(item.code === this.state.currentStatus){
        
        result = item.title;
      }
    });
    return result;
  }

  componentDidMount(){
    this.getStatusesFromServer();
  }

  componentWillReceiveProps(nextProps){
    this.setState({currentStatus: nextProps.currentStatus})
  }

  render(){
    return(
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.currentStatus ? this.getSelectedStatusTitle() : <FormattedMessage id="ul-choose-lesson-status"/>}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.statusFromServer.length > 0 ? this.state.statusFromServer.map( (item, index) => {
              return <Dropdown.Item key={index} onClick={() => this.chooseStatus(item.code)}>{item.title}</Dropdown.Item>
            })
            : ""}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default LessonStatusToggle;