import React, { Component } from 'react';

import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

class ModalTemplateContent extends Component{
  constructor(props){
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.containsSelectedTemplate = this.containsSelectedTemplate.bind(this);
  }

  handleSelect(id){
    //if selected id exists
    let sameIdWasAlreadySelected = false;
    console.log("handleSelect")
    this.props.data.map((item) => {
      console.log(item)
      //Check if there is another template selected in the category
      if(this.containsSelectedTemplate(item.Id)){
        let templates = this.props.selectedIds;
        for(let i = 0; i < templates.length; i++){
          if(templates[i].TemplateId === item.Id){
            console.log("Deleting template")
            templates.splice(i, 1);
            sameIdWasAlreadySelected = item.Id === id;
          }
        }
        this.props.onChange("lessonTemplates", templates, null);  
      }
      return true;
    })
    if(!sameIdWasAlreadySelected){
      //add new selected id  
      console.log("Pushing template ID: ", id)
      let templates = this.props.selectedIds;
      templates.push({"TemplateId": id})
      this.props.onChange("lessonTemplates", templates, id);
    }
    this.props.handleClose();
  }



  containsSelectedTemplate(id){
    let i;
    for(i = 0; i < this.props.selectedIds.length; i++){
      if(this.props.selectedIds[i].TemplateId === id){
        return true;
      }
    }
    return false;
  }

  render(){
    return(
      <div>
        <Form>
        <Form.Group>
          {this.props.data.map((item, index) => {
            return(
              <div key={index}>
                <Image src={item.ImgTemplate} fluid/>
                <Form.Check
                  id={item.Name}
                  label={item.Name + ": " + item.Desc} 
                  type="radio"
                  name="radioButton"
                  value={item.Id}
                  onClick={() => this.handleSelect(item.Id)}
                  checked={item.isSelected}
                  // onChange={this.onChange}
                />
                
              </div>
            )
          })}
          {/* this is a prototype for when no template is selected??? */}
          {/* <Form.Check
            id="none"
            label={<FormattedMessage id="none"/>} 
            type="radio"
            name="radioButton"
            value="none"
            onClick={() => this.removeSelectedId()}
            checked={!this.props.selectedId}
            // onChange={this.onChange}
          /> */}
        </Form.Group>
        </Form>
      </div>
    );
  }
}

export default ModalTemplateContent;