import React, { Component } from 'react';
// import RichTextInput from '../../../../../../components/richTextInput';
import TextAreaInput from '../../../../../../components/textAreaInput';
import { FormattedMessage } from 'react-intl';

class QuizInstructions extends Component{
  render(){
    const { limitFromRules, content } = this.props;
    return(
      <div>
        <h4><FormattedMessage id="qp-instructions"/></h4>
        {/* <RichTextInput
          textName="quizInstructions"
          onChange={this.props.onChange}
          language={this.props.language}
          content={this.props.content}
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade}
        /> */}
        <TextAreaInput
          // label="Instructions"
          textType="textarea"
          content={this.props.content}
          onChange={this.props.onChange}
        />
        {
          limitFromRules ? <p style={content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</p> : ""
        }
      </div>
    );
  }
}

export default QuizInstructions;