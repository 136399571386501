import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { apiKey, apiPsychUrl, apiLevelsUrl } from '../../../../../../config';
import { FormattedMessage } from 'react-intl';

class QuizLevelSelection extends Component{
  constructor(props){
    super(props);
    this.state={
      psychFromRequest: [],
      levelsFromRequest: [],
      selectedPsych: "",
      selectedLevel: ""
    }
    this.getPsychRequest = this.getPsychRequest.bind(this);
    this.getLevelRequest = this.getLevelRequest.bind(this);
    this.selectPsych = this.selectPsych.bind(this);
    this.selectLevel = this.selectLevel.bind(this);
  }

  
  getPsychRequest(){
    return new Promise((resolve, reject) => {
      const params = {
        "apiKey": apiKey,
        "token": sessionStorage.athenasToken
      };
      axios.get(apiPsychUrl, {params}).then(function (response){
        if(response.data.TransactionValid && response.data.SessionValid){
          resolve(response.data);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  getLevelRequest(){
    return new Promise((resolve, reject) => {
      const params = {
        "apiKey": apiKey,
        "token": sessionStorage.athenasToken,
        "psychId": this.state.selectedPsych
      };
      axios.get(apiLevelsUrl, {params}).then(function (response){
        if(response.data.TransactionValid && response.data.SessionValid){
          resolve(response.data);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  selectPsych(value){
    this.setState({
      selectedPsych: value
    }, function(){
      this.getLevelRequest().then(result => {
        const levels = result.TaxonomyLevels.map(item => ({Name: item.TaxLevelName, Id: item.Id}));
        this.setState({
          levelsFromRequest: levels
        });
      });
    });
  }

  selectLevel(value, levelString){
    this.props.changeLevel(value, levelString);
  }

  componentDidMount(){
    this.getPsychRequest().then(result => {
      const psych = result.TaxonomyPsych.map(item => ({Name: item.PsychName, Id: item.Id}));
      this.setState({
        psychFromRequest: psych
      });
    });
  }


  render(){
    return(
      <div>
        <br/>
        <h5><FormattedMessage id="cl-select-psych"/></h5>
        <Form className="checklist">
          <Form.Group>
            {this.state.psychFromRequest.map((item, index) => {
              return( 
                <Form.Check
                  inline
                  key={index}
                  label={item.Name}
                  name="radioButton"
                  type="radio"
                  checked={this.state.selectedPsych === item.Id}
                  onChange={() => this.selectPsych(item.Id)}
                />
              )
            })}
          </Form.Group>
        </Form>
        <Form className="checklist">
          <Form.Group>
            {this.state.levelsFromRequest.map((item, index) => {
              return( 
                <Form.Check
                  inline
                  key={index}
                  label={item.Name}
                  name="radioButton"
                  type="radio"
                  onClick={() => this.selectLevel(item.Id, item.Name)}
                  checked={this.props.currentSelection === item.Id}
                  onChange={() => this.selectLevel(item.Id, item.Name)}
                />
              )
            })}
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default QuizLevelSelection;