import React, { Component } from 'react';


import CommentTable from '../../../commentTable';

class LessonCommentsSection extends Component{

  render(){
    return(
      <div>
        <CommentTable
          entityType="LES"
          entityId={this.props.state.lessonId}
          language={this.props.language}
          messagesSent={this.props.messagesSent}
          messagesReceived={this.props.messagesReceived}
          loadComments={this.props.loadComments}
        />
      </div>
    );
  }
}

export default LessonCommentsSection;