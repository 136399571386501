export const emptyTemplateModelObject = {
  "Name": null,
  "Desc": null,
  "Type": null,
  "ImgTemplate": null,
  "TemplateCodeId": null,
  "BackgroundMusic": null,
  "BackgroundMusicOgg": null,
  "Annotation": null,
  "CharacterAnimationImg": null,
  "CharacterAnimationFrames": null,
  "CharacterAnimationAudio": null,
  "CharacterAnimationAudioOgg": null,
  "CharacterInfiniteLoop": null,
  "ObjectTouchImg": null,
  "ObjectTouchImgFrames": null,
  "ObjectTouchAudio": null,
  "ObjectTouchAudioOgg": null,
  "ObjectTouchInfiniteLoop": null,
  "ObjectDropImg": null,
  "ObjectDropImgFrames": null,
  "ObjectDropAudio": null,
  "ObjectDropAudioOgg": null,
  "ObjectDropInfiniteLoop": null,
  "BackgroundImg": null,
  "BackgroundImgInfiniteScroll": null
}

export const emptyTemplateInstructionsModelList = [
  {
    "LangCode": "en",
    "InstructionDesc": null
  },
  {
    "LangCode": "es",
    "InstructionDesc": null
  }
]

export const emptyTemplateCodeModel = {
  "Id": null,
  "Name": null,
  "Desc": null,
  "CssCode": null,
  "HtmlCode": null,
  "JavascriptCode": null,
  "ImgTemplateRequirements": null,
  "RequireCharacterAnimationImg": null,
  "RequireCharacterAnimationFrames": null,
  "RequireCharacterAnimationAudio": null,
  "RequireObjectTouchImg": null,
  "RequireObjectTouchImgFrames": null,
  "RequireObjectTouchAudio": null,
  "RequireObjectDropImg": null,
  "RequireObjectDropImgFrames": null,
  "RequireObjectDropAudio": null,
  "RequireBackgroundImg": null,
  "RequireBackgroundImgInfiniteScroll": null,
  "ModifiedBy": null,
  "CreatedAt": null,
  "UpdatedAt": null
}

export const validateTemplate = function(templateCodeModel, templateModel, templateInstructionModelList){

  let messages = [];
  if((isEmpty(templateModel.Name))){
    messages.push("El nombre es necesario.\n");
  }
  if((isEmpty(templateModel.Desc))){
    messages.push("La descripción es necesaria\n");
  }
  if((isEmpty(templateModel.Type))){
    messages.push("El tipo de template es necesario\n");
  }
  if(isEmpty(templateModel.ImgTemplate)){
    messages.push("La imagen de template es necesaria\n");
  }
  if(templateCodeModel.RequireBackgroundImg === "1" && isEmpty(templateModel.BackgroundImg)){
    messages.push("La imagen de background es requerida\n");
  }
  if(templateCodeModel.RequireBackgroundImgInfiniteScroll === "1" && isEmpty(templateModel.BackgroundImgInfiniteScroll)){
    messages.push("La opción de background image para scroll infinito es requerida");
  }
  if(templateCodeModel.RequireCharacterAnimationImg === "1" && isEmpty(templateModel.CharacterAnimationImg)){
    messages.push("La imagen de personaje es necesaria.\n");
  }
  if(templateCodeModel.RequireCharacterAnimationFrames === "1" && isEmpty(templateModel.CharacterAnimationFrames)){
    messages.push("Los frames de la imagen del personaje es requerido\n");
  }
  if(templateCodeModel.RequireCharacterAnimationIm === "1" && isEmpty(templateModel.CharacterInfiniteLoop)){
    messages.push("La opción de animación infinita de la imagen de personaje es requerida\n");
  }
  if(templateCodeModel.RequireCharacterAnimationAudio === "1" && (isEmpty(templateModel.CharacterAnimationAudio) || isEmpty(templateModel.CharacterAnimationAudioOgg))){
    messages.push("Ambos audios para el personaje son necesarios\n");
  }
  if(templateCodeModel.RequireObjectTouchImg === "1" && (isEmpty(templateModel.ObjectTouchImg))){
    messages.push("La imagen para object touch es requrida\n");
  }
  if(templateCodeModel.RequireObjectTouchImgFrames === "1" && isEmpty(templateModel.ObjectTouchImgFrames)){
    messages.push("Los frames de la imagen touch son requeridos\n");
  }
  if(templateCodeModel.RequireObjectTouchImg === "1" && isEmpty(templateModel.ObjectTouchInfiniteLoop)){
    messages.push("La opción para la animación infinita del object touch es requrida\n");
  }
  if(templateCodeModel.RequireObjectTouchAudio === "1" && (isEmpty(templateModel.ObjectTouchAudio) || isEmpty(templateModel.ObjectTouchAudioOgg))){
    messages.push("Ambos audios para el touch object son requeridos\n");
  }
  if(templateCodeModel.RequireObjectDropImg === "1" && isEmpty(templateModel.ObjectDropImg)){
    messages.push("La imagen para el objeto drop es requirida\n");
  }
  if(templateCodeModel.RequireObjectDropImgFrames === "1" && isEmpty(templateModel.ObjectDropImgFrames)){
    messages.push("Los frames de la imagen de objeto drop son requeridos\n");
  }
  if(templateCodeModel.RequireObjectDropImg === "1" && isEmpty(templateModel.ObjectDropInfiniteLoop)){
    messages.push("La opción para animación infinita de objeto drop es requerida\n");
  }
  if(templateCodeModel.RequireObjectTouchAudio === "1" && (isEmpty(templateModel.ObjectDropAudio) || isEmpty(templateModel.ObjectDropAudioOgg))){
    messages.push("Ambos audios para el objeto touch son necesarios\n");
  }

  if(isEmpty(templateInstructionModelList[0].InstructionDesc) || isEmpty(templateInstructionModelList[1].InstructionDesc)){
    messages.push("Ambas instrucciones son necesarias\n");
  } else{
    if(templateInstructionModelList[0].InstructionDesc.length > 100 || templateInstructionModelList[1].InstructionDesc.length > 100){
      messages.push("El máximo de caracteres para las instrucciones es 100\n");
    }
  }

  if(messages.length > 0){
    alert(messages);
    console.log("returning false", messages);
    return false;
  } else {
    return true;
  }
}

const isEmpty = function(stringToValidate){
  return (stringToValidate === null || stringToValidate === "" || stringToValidate === undefined);
}