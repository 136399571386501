import axios from 'axios';
import { apiKey, apiGetTemplateCodes } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const getTemplateCodes = async function(language){
  const params = {
    apiKey: apiKey,
    token: sessionStorage.athenasToken,
    langCode: language,
    isForOutsideSource: true
  }

  try{
      const response = await axios.get(apiGetTemplateCodes, {  params });
      if(response.data.SessionValid){
        if(response.data.TransactionValid){
          // Session and transaction is valid, return the values
          // console.log("Get template codes response: ", response.data.TemplateCodesModelList);
          return response.data.TemplateCodesModelList;
        }
      } else {
        //session is not valid, user is sent to login
        alert('Session is not valid');
        logoutUser();
      }
  } catch(error){
    alert(error.response.data.Messages);
  }
}