import React from 'react';
import { FormattedMessage } from 'react-intl';

// import notFound from '../../assets/images/notFound.gif';

const NotFoundPage = () => 
  <div style={{display: "flex", flex: "1", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "10px"}}>
    <h3 style={{display: "flex", margin: "10px"}}><FormattedMessage id="notFoundPageMessage" description="Message to be displayed when page is not found"/></h3>
    {/* <img style={{display: "flex", margin: "10px"}} src={notFound} alt="notFound"/> */}
  </div>

export default NotFoundPage;