import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import './quiz-questions.css';

import QuestionComponent from './subCoponents/questionComponent';
import { FormattedMessage } from 'react-intl';

class QuizQuestions extends Component{
  constructor(props){
    super(props);
    this.state={
      currentQuestion: null,
      questions: [],
      language: this.props.language,
      questionIndex: "",
      quizQuestionModel: this.props.state.quizQuestionModel,
      quizQuestionRubricModelList: this.props.state.quizQuestionRubricModelList,
      quizQuestionResponseModelList: this.props.state.quizQuestionResponseModelList,
      currentQuizQuestion: "",
      currentQuizCriterion: [[]],
      currentQuizQuestionResponse: [[]],
      currentQuestionIndex: "",
    }
    this.addNewAnswer = this.addNewAnswer.bind(this);
    this.changeRubricObject = this.changeRubricObject.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.changeCorrectAnswerStatus = this.changeCorrectAnswerStatus.bind(this);
    this.changeCurrentAnswerContent = this.changeCurrentAnswerContent.bind(this);
    this.changeCurrentQuestion = this.changeCurrentQuestion.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.loadQuestions = this.loadQuestions.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
  }

  loadQuestions(){
    let questionList = [];
    this.props.state.quizQuestionModel.map((item, index) => {
      return questionList.push({index: index});
    });
    this.setState({
      questions: questionList,
      quizQuestionModel: this.props.state.quizQuestionModel,
      quizQuestionRubricModelList: this.props.state.quizQuestionRubricModelList,
      quizQuestionResponseModelList: this.props.state.quizQuestionResponseModelList,
    });
  }

  changeRubricObject(content){
    console.log(content);
    let newList = this.state.quizQuestionRubricModelList;
    newList[this.state.currentQuestionIndex] = content;
    this.setState({quizQuestionRubricModelList: newList}, 
      function(){
        this.props.onChange("quizQuestionRubricModelList", this.state.quizQuestionRubricModelList)
      });
  }

  addNewQuestion(){
    const newQuizQuestionModel = this.state.quizQuestionModel;
    newQuizQuestionModel.push({ "Desc": "" });
    const newQuizQuestionRubricModelList = this.state.quizQuestionRubricModelList;
    newQuizQuestionRubricModelList.push([{ Rubric: "", QuestionRubricCriterionModelList: []}]);
    let newQuestionResponseArray = this.state.quizQuestionResponseModelList;
    newQuestionResponseArray.push([{ "Response": "", "Correct": "0", "Value": "0" }])
    this.setState({
      quizQuestionModel: newQuizQuestionModel,
      quizQuestionRubricModelList: newQuizQuestionRubricModelList,
      quizQuestionResponseModelList: newQuestionResponseArray,
    }, function(){
      const newQuestionsList = this.state.questions.concat({ index: this.state.questions.length });
      this.setState({ questions: newQuestionsList });
    });
  }

  selectQuestion(index){
    this.setState({
      currentQuestion: this.state.questions[index],
      currentQuizQuestionModel: this.state.quizQuestionModel[index],
      currentQuizRubricModelList: this.state.quizQuestionRubricModelList[index],
      currentQuizQuestionResponse: this.state.quizQuestionResponseModelList[index],
      currentQuestionIndex: index
    })
  }

  deleteQuestion(index){
    console.log("question delete index", index);
    let content = this.state.questions;
    content.splice(index, 1);
    //Reset index prop
    content.forEach(function(item, index){
      item.index = index;
    });
    let questionContent = this.state.quizQuestionModel;
    questionContent.splice(index, 1);
    let rubricContent = this.state.quizQuestionRubricModelList;
    rubricContent.splice(index, 1);
    let responseContent = this.state.quizQuestionResponseModelList;
    responseContent.splice(index, 1);
    this.setState({
      currentQuestion: null,
      questions: content,
      quizQuestionModel: questionContent,
      quizQuestionRubricModelList: rubricContent,
      quizQuestionResponseModelList: responseContent 
    });
  }

  changeCurrentQuestion(field, content){
    let currentQuestion = this.state.currentQuizQuestionModel;
    let currentQuestionList = this.state.quizQuestionModel;
    currentQuestion.Desc = content;
    currentQuestionList[this.state.currentQuestionIndex] = currentQuestion;
    this.setState({
      quizQuestionModel: currentQuestionList,
    }, function(){
      this.props.onChange("quizQuestionModel", this.state.quizQuestionModel);
    });
  }

  addNewAnswer(){
    let currentResponseListForCurrentQuestion = this.state.quizQuestionResponseModelList[this.state.currentQuestionIndex];
    currentResponseListForCurrentQuestion.push({ "Response": "", "Correct": "0", "Value": "0" });
    let questionResponses = this.state.quizQuestionResponseModelList;
    questionResponses[this.state.currentQuestionIndex] = currentResponseListForCurrentQuestion;
    this.setState({
      quizQuestionResponseModelList: questionResponses,
      currentQuizQuestionResponse: currentResponseListForCurrentQuestion
    }, function() {
      this.props.onChange('quizQuestionResponseModelList', this.state.quizQuestionResponseModelList);
    });
  }

  deleteAnswer(index){
    let currentResponseListForCurrentQuestion = this.state.quizQuestionResponseModelList[this.state.currentQuestionIndex];
    currentResponseListForCurrentQuestion.splice(index, 1);
    let questionResponses = this.state.quizQuestionResponseModelList;
    questionResponses[this.state.currentQuestionIndex] = currentResponseListForCurrentQuestion;
    this.setState({
      quizQuestionResponseModelList: questionResponses,
      currentQuizQuestionResponse: currentResponseListForCurrentQuestion
    }, function() {
      this.props.onChange('quizQuestionResponseModelList', this.state.quizQuestionResponseModelList);
    });
  }

  //@param index: index of the answer you want to change
  changeCurrentAnswerContent(index, content){
    let currentResponseListForCurrentQuestion = this.state.quizQuestionResponseModelList[this.state.currentQuestionIndex];
    currentResponseListForCurrentQuestion[index].Response = content;
    let questionResponses = this.state.quizQuestionResponseModelList;
    questionResponses[this.state.currentQuestionIndex] = currentResponseListForCurrentQuestion;
    this.setState({
      quizQuestionResponseModelList: questionResponses,
      currentQuizQuestionResponse: currentResponseListForCurrentQuestion
    }, function() {
      this.props.onChange('quizQuestionResponseModelList', this.state.quizQuestionResponseModelList);
    });
  }


  changeCorrectAnswerStatus(index){
    let responseTarget = this.state.currentQuizQuestionResponse[index];
    if(responseTarget.Correct === "0"){
      responseTarget.Correct = "1";
      responseTarget.Value = "1";
    } else {
      responseTarget.Correct = "0";
      responseTarget.Value = "0";
    }

    let newCurrentResponses = this.state.currentQuizQuestionResponse;
    newCurrentResponses[index] = responseTarget;
    let questionResponses = this.state.quizQuestionResponseModelList;
    questionResponses[this.state.currentQuestionIndex] = newCurrentResponses;
    this.setState({
      quizQuestionResponseModelList: questionResponses,
      currentQuizQuestionResponse: newCurrentResponses
    }, function() {
      this.props.onChange('quizQuestionResponseModelList', this.state.quizQuestionResponseModelList);
    });
  }

  componentWillReceiveProps(){
    this.setState({
      language: this.props.language,
      quizQuestionModel: this.props.state.quizQuestionModel,
      quizQuestionRubricModelList: this.props.state.quizQuestionRubricModelList,
      quizQuestionResponseModelList: this.props.state.quizQuestionResponseModelList,
    }, function(){ 
      this.loadQuestions();
    });

  }

  render(){
    return(
      <div>
        <Button variant="success" onClick={this.addNewQuestion}><FormattedMessage id="qp-add-question"/></Button>
        <div className="paginationContainer">
          {this.state.questions.map((item, index) => {
            return <Button key={index} className="paginationButton" onClick={() => this.selectQuestion(index)}>{index + 1}</Button>
          })}
        </div>
        {this.state.currentQuestion ? <QuestionComponent 
                                        rubricContent={this.state.quizQuestionRubricModelList[this.state.currentQuestionIndex]}
                                        onChangeRubricContent={this.changeRubricObject}
                                        lessonGrade={this.props.state.lessonGrade}
                                        lessonSubject={this.props.state.lessonSubject}
                                        quizLevel={this.props.state.quizLevelOfKnowledge} 
                                        quizRules={this.props.state.quizRules}
                                        onChangeQuestion={this.changeCurrentQuestion}
                                        onChangeAnswer={this.changeCurrentAnswerContent}
                                        onChangeCorrectAnswerStatus={this.changeCorrectAnswerStatus}
                                        addNewAnswer={this.addNewAnswer}
                                        deleteQuestion={this.deleteQuestion}
                                        deleteAnswer={this.deleteAnswer}
                                        question={this.state.currentQuestion}
                                        quizQuestionModel={this.state.currentQuizQuestionModel.Desc}
                                        quizQuestionResponseModelList={this.state.quizQuestionResponseModelList[this.state.currentQuestionIndex]}
                                        fileRules={this.props.fileRules}
                                      /> : ""}
        </div>
    );
  }
}
export default QuizQuestions;