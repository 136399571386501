import axios from 'axios';
import { apiKey, apiUpdateTemplateUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const updateTemplateCall = async function(language, templateModifierRequestModel){
  let params = {
    APIKey: apiKey,
    Token: sessionStorage.athenasToken,
    LangCode: language,
    TemplateModifierRequestModel: templateModifierRequestModel
  }
  try{
    const response = await axios.post(apiUpdateTemplateUrl, params);
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        //the post was saved correctly, we return false to deactivate the loading
        alert(response.data.Messages);
        return false;
      } else{
        alert(response.data.Messages);
        return false;
      }
    } else{
      //Session is not valid, therefore logout
      alert(response.data.Messages);
      logoutUser();
      return false;
    }
  } catch(error){
    alert(error.response.data.Messages);
    return false;
  }
}