import axios from 'axios';
import { apiKey, apiGradeUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const onGradeCall = async function(language){
  let params = {
    "token": sessionStorage.athenasToken,
    "apiKey": apiKey,
    "langCode": language,
    "filterByLanguage": true
  }
  try {
    const response = await axios.get(apiGradeUrl, { params });
    if(response.data.SessionValid){
      if (response.data.SessionValid && response.data.TransactionValid) {
        // console.log("Graddes from grade call: ", response.data);
        return response.data.Grades.map((item) => ({ title: item.Level.Name, code: item.Level.Code }));
      }
    } else{
      //session in not valid, user is sent to login
      alert('Session is not valid.');
      logoutUser();
    }
  }
  catch (error) {
    alert(error.response.data.Messages);
  }
}