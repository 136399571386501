/** ./src/routes/AppRouter.js
 * This file handles what view will be shown in the webpage.
 * All of the views will be imported as they are being created.
 * These views are entires pages constructed from smaller components.
 * 
 * A page-component is a parent/container component that will display its children
 */
import React from "react";

//Navigation Components
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "../utils/authHandling/private-route-component";

//Regular Components
import NavBar from '../components/navBar';
import { apiKey } from '../config';

//Pages
import CreateLessonPage from '../pages/createLessonPage';
import CreateTemplatePage from '../pages/createTemplatepage';
import DashboardPage from '../pages/dashboardPage';
import EditQuizPage from '../pages/editQuizPage';
import EditTemplatePage from '../pages/editTemplatePage';
import LoginPage from "../pages/loginPage";
import NotFoundPage from '../pages/404Page';
import QuizPage from '../pages/createQuizPage';
import UserPage from '../pages/userPage';
import ViewLessonsPage from '../pages/viewLessonsPage';
import ViewLessonPage from '../pages/editLessonPage';
import ViewTemplatesPage from '../pages/viewTemplatesPage';
import ViewUsersPage from "../pages/viewUsersPage";

import ComponentTest from '../pages/componentTestPage';

import "../../node_modules/video-react/dist/video-react.css";


//Translations
import { addLocaleData, IntlProvider } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import messages_en from '../translations/en';
import messages_es from '../translations/es';
addLocaleData([...locale_en, ...locale_es]);

const messages = {
  'en': messages_en,
  'es': messages_es
}

//Main Class
class AppRouter extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      language: "es",
      isLoggedIn: true
    }
  }

  onChangeStateToEN = () => {
    this.setState({
      language: "en"
    });
  }
  onChangeStateToES = () => {
    this.setState({
      language: "es"
    });
  }

  rerender = () => {
    this.forceUpdate();
  }

  render(){
    return (
        <IntlProvider locale={this.state.language} messages={messages[this.state.language]}>
          <Router>
            <NavBar 
              changeToEN={this.onChangeStateToEN.bind(this)} 
              changeToES={this.onChangeStateToES.bind(this)}
              refresh={this.rerender}
            />
            <div style={{height: "100%", padding: "76px", margin: "10px"}}>
            {apiKey === "12345" ? <h1> Seccion de Pruebas </h1> : "" }
              <Switch>
                <Route exact path="/login" language={this.state.language} component={LoginPage}/>
                <PrivateRoute exact path="/create-lesson" language={this.state.language} component={CreateLessonPage}/>
                <PrivateRoute exact path="/view-lessons" language={this.state.language} component={ViewLessonsPage}/>
                <PrivateRoute exact path="/lesson" language={this.state.language} component={ViewLessonPage}/>
                <PrivateRoute exact path="/quiz" language={this.state.language} component={QuizPage}/>
                <PrivateRoute exact path="/quiz-edit" language={this.state.language} component={EditQuizPage}/>
                <PrivateRoute exact path="/view-users" language={this.state.language} component={ViewUsersPage}/>
                <PrivateRoute exact path="/user" language={this.state.language} component={UserPage}/>
                <PrivateRoute exact path="/create-users" language={this.state.language} component={UserPage} />
                <PrivateRoute exact path="/create-template" language={this.state.language} component={CreateTemplatePage}/>
                <PrivateRoute exact path="/view-templates" language={this.state.language} component={ViewTemplatesPage}/>
                <PrivateRoute exact path="/edit-template" language={this.state.language} component={EditTemplatePage}/>
                <PrivateRoute exact path="/test" language={this.state.language} component={ComponentTest}/>
                <PrivateRoute exact path="/" language={this.state.language} component={DashboardPage}/>
                <PrivateRoute component={NotFoundPage}/>
              </Switch>            
            </div>
          </Router>
        </IntlProvider>
    );
  }
}

export default AppRouter;