import axios from 'axios';
import { apiKey, apiLessonStatusesUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const onStatusCall= function(){
  let params = {
    "token": sessionStorage.athenasToken,
    "apiKey": apiKey
  }
  return axios.get(apiLessonStatusesUrl, {params}).then(function (response){
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        // console.log("Response from status call:", response.data);
        return response.data.StatusOfLessons.map((item) => ({title: item.Name, code: item.Id}));
      }
    } else {
      //session is not valid, user is sent to login
      alert('Session is not valid.');
      logoutUser();
    }
  }).catch(error => {
    alert(error.response.data.Messages);
  }); 
}