import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';

class CreateLessonPruebasSection extends Component{

  constructor(props){
    super(props);
    // console.log(this.props.state.lessonQuizes)
    this.state={
      quizes: this.props.state.lessonQuizes,
      exams: this.props.state.lessonExams,
      practices: this.props.state.lessonPractices
    }
    this.goToQuiz = this.goToQuiz.bind(this);
  }

  goToQuiz(id){
    // console.log("Go to quiz with id:" + id);
    sessionStorage.setItem("quizId", id);
    sessionStorage.setItem("quizIsForVr", this.props.isVr);
  }


  render(){
    const { lessonId, lessonDirectedTo } = this.props;
    return(
      <div>
        <h3><FormattedMessage id="vl-evaluations"/></h3>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        {/* TODO: Dynamic links to created quizes in this lesson */}
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around" , flexWrap: "wrap"}}>
          
          {this.state.practices ? 
            <div>
            <hr></hr>
              <h4><FormattedMessage id="vl-quizes"/></h4>
              <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"}}>
              {this.state.practices.length > 0 ? 
                this.state.practices.map( (item, index) => {
                return (
                    <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                      <Card.Body>
                        <Card.Title>{item.QuizModel.Title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{item.QuizModel.IsExam === "1" ? <FormattedMessage id="vl-exam"/> : <FormattedMessage id="vl-quiz"/>} <br/>Quiz ID: {item.QuizModel.Id}</Card.Subtitle>
                        <Card.Text className="mb-2 text-muted">
                          {/* Something we can user for some text inside the card */}

                        </Card.Text>
                          <Link  
                            key={index} 
                            onClick={() => this.goToQuiz(item.QuizModel.Id)} 
                            to={{pathname: "quiz-edit"}}>
                              <FormattedMessage id="qp-viewOrEdit"/>
                          </Link>
                      </Card.Body>
                    </Card>
                  )
              }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}

          {this.state.exams ?
            <div>
            <hr></hr>
            <h4><FormattedMessage id="vl-exams"/></h4>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"}}>
            {this.state.exams.length > 0 ? this.state.exams.map( (item, index) => {
              return (
                <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                  <Card.Body>
                    <Card.Title>{item.QuizModel.Title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{item.QuizModel.IsExam === "1" ? <FormattedMessage id="vl-exam"/> : <FormattedMessage id="vl-quiz"/>} <br/> Quiz ID: {item.QuizModel.Id}</Card.Subtitle>
                    <Card.Text>
                      {/* Something we can user for some text inside the card */}
                    </Card.Text>
                      <Link  
                        key={index} 
                        onClick={() => this.goToQuiz(item.QuizModel.Id)} 
                        to={{pathname: "quiz-edit"}}>
                          <FormattedMessage id="qp-viewOrEdit"/>
                      </Link>
                  </Card.Body>
                </Card>
              )
            }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}            
        </div>
        <br></br>
        <Link  
          className="btn btn-success" 
          to={{pathname: "/quiz", lessonId: lessonId, lessonDirectedTo: lessonDirectedTo}}> 
            <FormattedMessage id="cl-create-quiz"/>
        </Link>
      </div>
    );
  }
}

export default CreateLessonPruebasSection;