import React, { Component } from 'react';
import RichTextInput from '../../../../../../components/richTextInput';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FormattedMessage, injectIntl} from 'react-intl';

class Answer extends Component{
  render(){
    const { intl } = this.props;
    return(
      <div>
        <div style={{display: "flex", marginTop: "10px"}}>
          <h5 style={{marginRight: "10px"}}><FormattedMessage id="qp-answer-title"/> #{this.props.index + 1}</h5>
          <Form.Check
            inline
            label={intl.formatMessage({id: 'qp-correct-answer'})}
            name="checkbox"
            checked={this.props.correctStatus === "1"}
            onChange={() => this.props.onChangeCorrectStatus(this.props.index)}
          />
          <Button variant="danger" onClick={() => this.props.deleteAnswer(this.props.index)}><FormattedMessage id="qp-delete-answer"/></Button>
        </div>
        <RichTextInput
          isQuiz 
          content={this.props.answerResponseContent} 
          textName={this.props.index} 
          parentStateKey={this.props.key} 
          onChange={this.props.onChangeAnswerContent}
          language={this.props.language}
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade}
          limitFromRules={this.props.limitFromRules}
          fileRules={this.props.fileRules}
        />
      </div>
    );
  }
}

export default injectIntl(Answer);