import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import TextInput from '../textInput';
import TextAreaInput from '../textAreaInput';
import TinyMCE from '../tinyMCE';
import Button from 'react-bootstrap/Button';
import FileDropzone from '../dropzoneImplementation';

class AddedTextAndField extends Component{

  constructor(props){
    super(props);
    this.state={
      showUpload: false
    }
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  toggleUpload(){
    this.setState({showUpload: !this.state.showUpload});
  }

  render(){
    const { index, name, desc, onChangeDesc, onChangeName, labelId, style, removeField, limitFromRules } = this.props;
    return(
      <div>
        <h5><FormattedMessage id={labelId}/> {index >= 0 ? index + 1 : ""}</h5>
        <TextInput
          textName={index}
          textType="text"
          index={index}
          content={name}
          onChange={onChangeName}
        />
        {
          this.props.isVr 
          ? <TextAreaInput 
              isForVR
              textType="textarea" 
              parentStateKey={index}
              onChange={onChangeDesc} 
              content={desc}
            />
          :
          <div> 
            <TinyMCE 
              parentStateKey={index} 
              content={desc}
              onChange={onChangeDesc}
            /> 
            {this.state.showUpload ?        
                <div>
                  <Button onClick={this.toggleUpload}><FormattedMessage id="button-toggle-hide"/></Button>
                  <FileDropzone 
                    parentStateKey={index} 
                    content={desc}
                    onChange={onChangeDesc} 
                    grade={this.props.lessonGrade}
                    subject={this.props.lessonSubject}
                    fileRules={this.props.fileRules}
                  /> 
                </div> 
              :
                <Button onClick={this.toggleUpload}><FormattedMessage id="button-toggle-show"/></Button>
            }
          </div>
        }
        {
          limitFromRules ? <p style={ desc ? desc.length > limitFromRules ? {color: "red"} : {} : {}}>{desc ? desc.length : 0} / {limitFromRules}</p> : ""
        }
        
        
        <Button 
          style={style.removeButton} 
          variant="danger" 
          onClick={() => removeField(index)}>
            remove
        </Button>
      </div>
    );
  }
}

export default AddedTextAndField;