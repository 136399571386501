import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { apiKey, apiUpdateQuizUrl, apiGetQuizUrl, apiGetQuizRules, apiLessonGetContent} from '../../config';
import axios from 'axios';
import { logoutUser } from '../../utils/authHandling/logout-user';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';

import { quizValidator } from '../../utils/quizValidator';
import { decodeHTMLEntities } from '../../utils/htmlEntityDecoder';

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import LessonHTMLViewer from '../../components/lessonHTMLViewer';
import TabNavigator from '../../components/tabNavigator';

//Local Components
import QuizInfo from './components/quizInfoComponent';
import QuizQuestions from './components/quizQuestionsComponent';
import QuizCommentsSection from './components/commentsSection/comments-section-quiz';

import { getComments } from '../../server/getComments/get-comments';
import { getCommentsSent } from '../../server/getCommentsSent/get-comments-sent';


class EditQuizPage extends Component{
  constructor(props){
    super(props);
    this.state={
      //Quiz Model
      quizId: "",
      quizReadyToPublish: "",
      quizLessonId: "",
      quizTitle: "",
      quizInstructions: "",
      quizLevelOfKnowledge: "",
      quizIsExam: "",
      quizType: "",
      quizExtraInfo: "",
      //For questions
      quizQuestionModel: [],
      quizQuestionRubricModelList: [],
      quizQuestionResponseModelList: [],
      loading: false
    }
    this.onChange = this.onChange.bind(this);
    this.updateQuiz = this.updateQuiz.bind(this);
    this.getQuiz = this.getQuiz.bind(this);
    this.getQuizRules = this.getQuizRules.bind(this);
    this.getLessonGradeAndSubject = this.getLessonGradeAndSubject.bind(this);
    this.removePreviewId = this.removePreviewId.bind(this);
    this.loadComments = this.loadComments.bind(this);
    this.getQuizType = this.getQuizType.bind(this);
  }
  onChange(field, value){
    this.setState({[field]: value});
    // console.log(this.state)
  }
  getQuiz(){
    let self = this;
    let params = {
      "token": sessionStorage.athenasToken,
      "apiKey": apiKey,
      "langcode": this.props.language,
      "quizId": sessionStorage.quizId,
      "isForOutsideSource": true
    }
    axios.get(apiGetQuizUrl, {params}).then(function(response) {
      if(response.data.TransactionValid && response.data.SessionValid){
        console.log("quiz data from server", response.data);
        let quizQuestions = [];
        let quizRubrics = [];
        let quizResponses = [];
        response.data.QuizRequestModel.QuestionRequestModelList.map((item, index) => {
          quizQuestions.push(item.QuizQuestionModel);
          if(item.QuizQuestionRubricModelList.length === 0){
            quizRubrics.push([{Rubric: "", QuestionRubricCriterionModelList: [{Criterion: "", QuestionRubricCriterionValueModelList: [{Desc: "", Value: ""}]}]}]);
            console.log("Push empty rubric object");
          } else{
            quizRubrics.push(item.QuizQuestionRubricModelList);
          }
          quizResponses.push(item.QuizQuestionResponseModelList);
          return true;
        });
        self.setState({
          quizId: response.data.QuizRequestModel.QuizModel.Id,
          quizReadyToPublish: response.data.QuizRequestModel.QuizModel.ReadyToPublish,
          quizLessonId: response.data.QuizRequestModel.QuizModel.LessonId,
          quizTitle: response.data.QuizRequestModel.QuizModel.Title,
          quizInstructions: response.data.QuizRequestModel.QuizModel.Instruction,
          quizExtraInfo: response.data.QuizRequestModel.QuizExtraInfoModel ? response.data.QuizRequestModel.QuizExtraInfoModel.ExtraInfo : "",
          quizLevelOfKnowledge: response.data.QuizRequestModel.QuizModel.LevelOfKnowledge,
          quizIsExam: response.data.QuizRequestModel.QuizModel.IsExam,
          quizType: response.data.QuizRequestModel.QuizModel.QuizTypeId,
          quizQuestionModel:  quizQuestions,
          quizQuestionRubricModelList: quizRubrics,
          quizQuestionResponseModelList: quizResponses,
          levelOfKnowledgeString: response.data.QuizRequestModel.TaxonomyLevel.TaxLevelName + " (" + response.data.QuizRequestModel.QuizModel.LevelOfKnowledge + ")"
        }, () => {
          self.getQuizRules();
          self.getLessonGradeAndSubject();
        });
      }
    });
  }
  updateQuiz(){
    // Function to create a quiz
    // console.log("Quiz data example", this.state);

    //Passing the strings through the decoder
    let questionsDecoded = this.state.quizQuestionModel;
    this.state.quizQuestionModel.forEach((element, index) => {
      questionsDecoded[index].Desc = decodeHTMLEntities(element.Desc);
    });

    let responsesDecoded = this.state.quizQuestionResponseModelList;
    this.state.quizQuestionResponseModelList.forEach((element, index) => {
      element.forEach((item, c) => {
        responsesDecoded[index][c].Response = decodeHTMLEntities(item.Response);
      });
    }); 

    // console.log("questions without codes", questionsDecoded);
    // console.log("responses decoded: ", responsesDecoded);
    
    let questionRequestModelGenerated = [];
    let updateQuizJSON = {};

    this.setState({loading: true, quizQuestionModel: questionsDecoded, quizQuestionResponseModelList: responsesDecoded}, function(){
      if(this.state.quizLevelOfKnowledge > 2){
        this.state.quizQuestionModel.map((item, index) => {
          return questionRequestModelGenerated.push({
            "QuizQuestionModel": this.state.quizQuestionModel[index],
            "QuizQuestionRubricModelList": this.state.quizQuestionRubricModelList[index], 
            "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
          });
        });
      } else{
        this.state.quizQuestionModel.map((item, index) => {
          return questionRequestModelGenerated.push({
            "QuizQuestionModel": this.state.quizQuestionModel[index],
            "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
          });
        });
      }
      updateQuizJSON = {
        "QuizRequestModel": {
          "QuizModel": {
            "Id": this.state.quizId,
            "LessonId": this.state.quizLessonId,
            "LevelOfKnowledge": this.state.quizLevelOfKnowledge,
            "Title": this.state.quizTitle,
            "Instruction": this.state.quizInstructions,
            "IsExam": this.state.quizIsExam,
            "QuizTypeId": this.state.quizType,
            "ReadyToPublish": this.state.quizReadyToPublish
          },
          "QuizExtraInfoModel":{
            "ExtraInfo": this.state.quizExtraInfo
          }, 
          "QuestionRequestModelList": questionRequestModelGenerated,
        }, 
        "APIKey": apiKey,
        "Token": sessionStorage.athenasToken,
        "LangCode": this.props.language
      }
      console.log("json for quiz update", updateQuizJSON);
      //Validating quiz data
      let quizWarnings = quizValidator(updateQuizJSON, this.state.quizRules);
      if(quizWarnings.length > 0){
        let warningString = "";
        quizWarnings.forEach((item) => {
          warningString = warningString + item + "\n";
        });
        alert("Error: \n" + warningString);
      } else{
        let self = this;
        axios.post(apiUpdateQuizUrl, updateQuizJSON).then(function(response){
          console.log(response);
          if(response.data.SessionValid){
            if(response.data.TransactionValid){
              alert(response.data.Messages);
            } else{
              let str = ""
              response.data.Messages.map((item) => {
                str = str + item + "\n";
                return true;
              })
              alert("Lesson not created\n\n" + str);
            }
          } else{
            logoutUser();
          }
          self.setState({loading: false});
        }).catch(error => {
          alert(error);
          self.setState({loading: false});
        });
        
      }
    });
  }

  getLessonGradeAndSubject(){
    let self = this;
    let params = {
      "apiKey": apiKey,
      "lessonId": sessionStorage.lastSearch,
      "langCode": this.props.language,
      "isForOutsideSource": false,
      "isAWebService": true,
      "token": sessionStorage.athenasToken
    }
    axios.get(apiLessonGetContent, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        let lessonData = response.data.LessonModifierRequestModel;
        self.setState({
          lessonId: lessonData.LessonModel.Id,
          lessonTitle: lessonData.LessonModel.LessonTitle,
          lessonGrade: lessonData.LessonModel.LevelCode,
          lessonSubject: lessonData.LessonModel.SubjectCode
        })
      }
    });
  }
  
  getQuizRules(){
    let self = this;
    let params = {
      quizTypeId: this.state.quizType,
      apiKey: apiKey,
      token: sessionStorage.athenasToken,
      lessonId: sessionStorage.lastSearch
    }
    console.log("Rules Params:", params);
    axios.get(apiGetQuizRules, {params}).then(function(response){
      console.log("Return from Rules Call", response)
      if(response.data.TransactionValid && response.data.SessionValid){
        self.setState({
          quizRules: response.data.Rules[0]
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages, "Quiz rules could not be retrieved from server.")
    })
  }

  componentDidMount(){
    this.getQuiz();
    onRulesCall().then(data => {
      console.log("Called rules data in edit quiz", data)
      this.setState({"fileRules": data});
    });
  }
  removePreviewId(){
    console.log("Removed the id")
    this.setState({previewId: null});
  }

  loadComments(){
    getComments(this.props.language, this.state.quizId, "QUI").then((data) => {
      console.log("Comments for me" ,data);
      let messagesReceived = []
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesReceived.push({to: item.RecipientName ,from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed, read: item.Read, id: item.Id, userFromId: item.UserId});
      })  
      this.setState({MessagesReceived: messagesReceived});
    });



    getCommentsSent(this.props.language, this.state.quizId, "QUI").then((data) => {
      console.log("Comments that I sent", data);
      let messagesSent = []
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesSent.push({to: item.RecipientName, from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed});
      })  
      this.setState({MessagesSent: messagesSent});
    })
  }

  getQuizType(typeInt){
    switch(typeInt){
      case '1':
        return  <b><FormattedMessage id="qp-isMultipleChoice"/></b>
      case '2': 
        return  <b><FormattedMessage id="qp-isDragAndDrop"/></b>
      case '3':
        return  <b><FormattedMessage id="qp-isFreeText"/></b>
      default:
        return typeInt
    }
  }
  

  render(){
    const sections = [
      {
        "sectionTitle": "Info",
        "sectionContent": <QuizInfo 
                            onChange={this.onChange} 
                            state={this.state} 
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      },
      {
        "sectionTitle": "Preguntas",
        "sectionContent": <QuizQuestions 
                            onChange={this.onChange} 
                            state={this.state}
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      }, {
          "sectionTitle": "Comentarios",
          "sectionContent": <QuizCommentsSection
            parentStateHandle={this.onChange}
            state={this.state}
            language={this.props.language}
            messagesSent={this.state.MessagesSent}
            messagesReceived={this.state.MessagesReceived}
            loadComments={this.loadComments}
          />,
          "onSelect": this.loadComments
      }
    ];
    return(
      <div>
        <h3><FormattedMessage id="qp-title-edit"/></h3>
        {this.state.loading ? <Button disabled variant="success" style={{marginBottom: "10px"}} ><FormattedMessage id="qp-update-quiz"/></Button>: <Button variant="success" style={{marginBottom: "10px"}} onClick={this.updateQuiz}><FormattedMessage id="qp-update-quiz"/></Button>}
        <Link to="lesson"><Button style={{marginBottom: "10px", marginLeft: "5px"}}><FormattedMessage id="qp-back-to-lesson"/></Button></Link>
        <div style={{backgroundColor: "#eee", padding: "5px", margin: "10px", width: "80%"}}>
          <Container>
            <Row>
              <Col>
              <FormattedMessage id="qp-lesson-title"/>:
              </Col>
              <Col>
              <b>{this.state.lessonTitle}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <FormattedMessage id="qp-lesson-id"/>:
              </Col>
              <Col>
                <b>{this.state.lessonId}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
              <FormattedMessage id="qp-quiz-title"/>:
              </Col>
              <Col>
              <b>{this.state.quizTitle}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <FormattedMessage id="qp-quiz-id"/>
              </Col>
              <Col>
                <b>{this.state.quizId}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <FormattedMessage id="qp-evaluation"/>:
              </Col>
              <Col>
                {this.state.quizIsExam === "1" ? <b><FormattedMessage id="qp-isExam"/></b> : <b><FormattedMessage id="qp-isPractice"/></b>}
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <FormattedMessage id="qp-level-of-knowledge"/>:
              </Col>
              <Col>
                <b>{this.state.levelOfKnowledgeString}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <FormattedMessage id="qp-type"/>:
              </Col>
              <Col>
                {this.getQuizType(this.state.quizType)}
              </Col>
            </Row>
          </Container>
        </div>
        <TabNavigator 
          sections={sections} 
          defaultActive="Info"
        />
        <div 
          style={{
            bottom: "0",
            right: "0",
            position: "fixed",
            textAlign: "center",
            zIndex: "100"
          }}
        > 
          <FontAwesomeIcon
            onClick={()=>this.setState({previewId: 4})}
            icon={faEye} 
            size="4x" 
            color="#fff"
            style={{
              backgroundColor: "#f22", 
              height: "70px",
              width: "70px",
              padding: "5px", 
              borderRadius: "100%",
              margin: "10px"
            }} 
          />
          <p><FormattedMessage id="preview"/></p>
          <LessonHTMLViewer
            contentId={this.state.previewId}
            lessonId={this.state.quizId}
            language={this.props.language}
            removePreviewId={this.removePreviewId}
          />
        </div>
      </div>
    );
  }
}

export default EditQuizPage;