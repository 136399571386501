import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form } from 'react-bootstrap';

class TextInput extends Component{

  constructor(props){
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }


  onTextChange(event){
    //getting field name and value
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    this.props.onChange(fieldName, fieldValue);
  }

  render() {
    const { textType, textName, labelID, index, limitFromRules, content, type } = this.props;
    return(
      <div>
        {
          labelID ? <h4><FormattedMessage id={labelID}/> {index >= 0 ? index + 1 : ""}</h4> : ''
        }
        
        <Form.Control
          name={textName} 
          onChange={this.onTextChange}
          value={content}
          type={type ? type : textType}
        />
        {
          limitFromRules ? <p style={content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</p> : ""
        }
      </div>
    );
  }
}

export default TextInput;