//For Prod Build
import { apiKeySecure, apiUrlSecure} from './apiKey';
export const apiKey = apiKeySecure;
const apiUrl = apiUrlSecure;

//For dev server Build
// export const apiKey = "12345";
// const apiUrl = "http://3.220.244.32:9787/api/";


export const tinyMCEkey = "01x0x2uo9lcvh41iwm6y9h2vizdtv1khcganwk0fq57ro3hq";
//File upload
export const uploadLink = apiUrl + "files/upload?";

//Urls for Users
export const apiLoginUrl = apiUrl + "auth/login";
export const apiGetUsersUrl = apiUrl + "accounts/advanced";
export const apiGetUserDataUrl = apiUrl + "accounts/advanced";
export const apiRegister = apiUrl + "register";
export const apiUserUpdate = apiUrl + "accounts/update";
export const apiPasswordUpdate = apiUrl + "accounts/password/update";
export const apiGetUserRoles = apiUrl + "roles";

//Urls for Lessons
export const apiLessonsTableUrl = apiUrl + "lessons/advance";
export const apiLessonStatusesUrl = apiUrl + "lessons/statuses";
export const apiLessonGetContent = apiUrl + "lessons/lesson";
export const apiLessonCreate = apiUrl + "lessons/create";
export const apiLessonUpdate = apiUrl + "lessons/update";
export const apiSubjectUrl = apiUrl + "subjects";
export const apiGradeUrl = apiUrl + "grades";
export const apiAdressUrl = apiUrl + "address-all";
export const apiTaxonomyVerbs = apiUrl + "taxonomy/verbs";
export const apiUnitsUrl = apiUrl + "unit/grade-unit";
export const apiLessonLanguages = apiUrl + "lessons/languages";
export const apiTemplateUrl = apiUrl + "templates";
export const apiGetLessonRulesUrl = apiUrl + "addressed/rules";

//Urls for Standard Codes
export const apiGetStandard = apiUrl + "pr-standards/standards";
export const apiGetExpectations = apiUrl + "pr-standards/expectations";
export const apiGetSubCodes = apiUrl + "pr-standards/subcodes";
export const apiGetSubMainSubCodes = apiUrl + "pr-standards/submain-subcodes";

//Urls for File Uploads
export const apiGetFileRules = apiUrl + "files/rules";
export const apiUploadFiles = apiUrl + "files/upload";

//Urls for Quizzes
export const apiCreateQuizUrl = apiUrl + "quizzes/create";
export const apiGetQuizUrl = apiUrl + "quizzes/quiz";
export const apiUpdateQuizUrl = apiUrl + "quizzes/update";
export const apiGetQuizRules = apiUrl + "quizzes/rules";
export const apiQuizTypeUrl = apiUrl + "quizzes/quiz-type";
export const apiPsychUrl = apiUrl + "taxonomy/psych";
export const apiLevelsUrl = apiUrl + "taxonomy/levels";

//Urls 
export const apiGetPdfLink = apiUrl + "lessons/print";

//HTML Viewers
export const apiHtmlLesson = apiUrl + "html/lesson";
export const apiHtmlConcept = apiUrl + "html/concept";
export const apiHtmlDefinition = apiUrl + "html/definition";
export const apiHtmlExample = apiUrl + "html/example";
export const apiHtmlQuiz = apiUrl + "html/quiz";

//Templates
export const apiGetTemplateCodes = apiUrl + "templates/codes";
export const apiCreateTemplateUrl = apiUrl + "templates/create";
export const apiUpdateTemplateUrl = apiUrl + "templates/update"
export const apiGetTemplates = apiUrl + "templates";
export const apiGetTemplateDetails = apiUrl + "templates/details";

//Comments
export const apiGetReceivedComments = apiUrl + "comments/received";
export const apiGetSentComments = apiUrl + "comments/sent";
export const apiCreateComment = apiUrl + "comments/create";
export const apiReadComment = apiUrl + "comments/update/read";
export const apiCompleteComment = apiUrl + "comments/update/completed";
export const apiGetCommentUsers = apiUrl + "accounts/generic/names";
