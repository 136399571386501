import axios from 'axios';
import { apiKey, apiSubjectUrl } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const onSubjectCall = async function(langcode){
  let params = {
    "token": sessionStorage.athenasToken,
    "apiKey": apiKey,
    "langcode": langcode
  }
  try {
    const response = await axios.get(apiSubjectUrl, { params });
    if(response.data.SessionValid){
      if (response.data.TransactionValid) {
        // console.log("Response from subject call:", response.data);
        return response.data.Subjects.map((item) => ({ title: item.Name, code: item.Code }));
      }
    }
    else {
      //session is not valid, user is sent to login
      alert('Session is not valid.');
      logoutUser();
    }
  }
  catch (error) {
    alert(error.response.data.Messages);
  }
}