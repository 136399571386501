import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PreSelectionPage from '../preSelectionPage';

import { quizValidator } from '../../utils/quizValidator';
import { Redirect } from 'react-router-dom';

import TabNavigator from '../../components/tabNavigator';
import Button from 'react-bootstrap/Button'

//Local Components
import QuizInfo from './components/quizInfoComponent';
import QuizQuestions from './components/quizQuestionsComponent';

import { decodeHTMLEntities } from '../../utils/htmlEntityDecoder';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';

import { apiKey, apiCreateQuizUrl, apiGetQuizRules, apiLessonGetContent } from '../../config';
import axios from 'axios';

class QuizPage extends Component{
  constructor(props){
    super(props);
    this.state={
      //Quiz Model
      loading: false,
      redirect: false,
      quizLessonId: sessionStorage.lastSearch,
      quizReadyToPublish: "0",
      quizTitle: "",
      quizInstructions: "",
      quizLevelOfKnowledge: "",
      quizIsExam: "",
      quizType: "",
      quizExtraInfo: "",
      //For questions
      quizQuestionModel: [],
      quizQuestionRubricModelList: [],
      quizQuestionResponseModelList: [],
      levelSelected: false,
    }
    this.onChange = this.onChange.bind(this);
    this.createQuiz = this.createQuiz.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.getLessonGradeAndSubject = this.getLessonGradeAndSubject.bind(this);
  }

  onChange(field, value){
    this.setState({[field]: value});
    // console.log(this.state)
  }

  createQuiz(){

    //Passing the strings through the decoder
    let questionsDecoded = this.state.quizQuestionModel;
    this.state.quizQuestionModel.forEach((element, index) => {
      questionsDecoded[index].Desc = decodeHTMLEntities(element.Desc);
    });

    let responsesDecoded = this.state.quizQuestionResponseModelList;
    this.state.quizQuestionResponseModelList.forEach((element, index) => {
      element.forEach((item, c) => {
        responsesDecoded[index][c].Response = decodeHTMLEntities(item.Response);
      });
    });
    // this.state.quizQuestionResponseModelList.forEach((element, index) =>{
    //   responsesDecoded[index]
    // });
    console.log("questions without codes", questionsDecoded);
    console.log("responses decoded: ", responsesDecoded);
    this.setState({
      loading: true,
      quizQuestionModel: questionsDecoded ,
      quizQuestionResponseModelList: responsesDecoded
    });
    let self = this;
    //Function to create a quiz
    let questionRequestModelGenerated = [];
    let createQuizJSON = {};

    if(this.state.quizLevelOfKnowledge < 3){
      if(this.state.quizQuestionModel.length === this.state.quizQuestionResponseModelList.length){
        this.state.quizQuestionModel.map((item, index) => {
          return questionRequestModelGenerated.push({
            "QuizQuestionModel": this.state.quizQuestionModel[index],
            "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
          });
        });
        createQuizJSON = {
          "QuizRequestModel": {
            "QuizModel": {
              "LessonId": this.state.quizLessonId,
              "LevelOfKnowledge": this.state.quizLevelOfKnowledge,
              "Title": this.state.quizTitle,
              "Instruction": this.state.quizInstructions,
              "IsExam": this.state.quizIsExam,
              "QuizTypeId": this.state.quizType,
              "ReadyToPublish": this.state.quizReadyToPublish
            },
            "QuizExtraInfoModel":{
              "ExtraInfo": this.state.quizExtraInfo
            }, 
            "QuestionRequestModelList": questionRequestModelGenerated,
          }, 
          "APIKey": apiKey,
          "Token": sessionStorage.athenasToken,
          "LangCode": this.props.language
        }
        console.log("Json que se envia", createQuizJSON);
        console.log("Quiz create rules", this.state.quizRules);
        let quizWarnings = quizValidator(createQuizJSON, this.state.quizRules);
        if(quizWarnings.length > 0){
          let warningString = "";
          quizWarnings.forEach((item) => {
            warningString = warningString + item + "\n";
          });
          alert("Error: \n" + warningString);
          this.setState({'loading': false});
        } else{
          axios.post(apiCreateQuizUrl, createQuizJSON).then(function(response){
            alert(response.data.Messages);
            if(response.data.TransactionValid && response.data.SessionValid){
              self.setState({'redirect': true});
            }
            self.setState({'loading': false});
          }).catch(error => {
            alert(error.response.data.Messages);
            this.setState({'loading': false});
          });
        }
      }
    } else{
      this.state.quizQuestionModel.map((item, index) => {
        return questionRequestModelGenerated.push({
          "QuizQuestionModel": this.state.quizQuestionModel[index],
          "QuizQuestionRubricModelList": [this.state.quizQuestionRubricModelList[index]], 
          "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
        });
      });
      createQuizJSON = {
        "QuizRequestModel": {
          "QuizModel": {
            "LessonId": this.state.quizLessonId,
            "LevelOfKnowledge": this.state.quizLevelOfKnowledge,
            "Title": this.state.quizTitle,
            "Instruction": this.state.quizInstructions,
            "IsExam": this.state.quizIsExam,
            "QuizTypeId": this.state.quizType,
            "ReadyToPublish": this.state.quizReadyToPublish
          },
          "QuizExtraInfoModel":{
            "ExtraInfo": this.state.quizExtraInfo
          }, 
          "QuestionRequestModelList": questionRequestModelGenerated,
        }, 
        "APIKey": apiKey,
        "Token": sessionStorage.athenasToken,
        "LangCode": this.props.language
      }
      console.log("Json que se envia", createQuizJSON);
      console.log("Quiz create rules", this.state.quizRules);
      let quizWarnings = quizValidator(createQuizJSON, this.state.quizRules);
      if(quizWarnings.length > 0){
        let warningString = "";
        quizWarnings.forEach((item) => {
          warningString = warningString + item + "\n";
        });
        alert("Error: \n" + warningString);
        this.setState({'loading': false});
      } else{
        axios.post(apiCreateQuizUrl, createQuizJSON).then(function(response){
          if(response.data.TransactionValid && response.data.SessionValid){
            alert("Quiz created succesfuly");
            sessionStorage.setItem("sessionStorage", response.quizId);
            self.setState({'redirect': true});
          } else{
            alert(response.data.Messages)
            self.setState({'loading': false});
          }
        }).catch(error => {
          alert(error.response.data.Messages);
          self.setState({'loading': false});
        });
      }
    }
  }

  getQuizRules(){
    let self = this;
    let params = {
      quizTypeId: this.state.quizType,
      apiKey: apiKey,
      token: sessionStorage.athenasToken,
      lessonId: sessionStorage.lastSearch
    }
    axios.get(apiGetQuizRules, {params}).then(function(response){
      if(response.data.TransactionValid && response.data.SessionValid){
        self.setState({
          quizRules: response.data.Rules[0]
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages, "Quiz rules could not be retrieved from server.")
    })
  }

  componentDidUpdate(){
    if(!this.state.quizRules && this.state.levelSelected){
      this.getQuizRules();
    }
    if(!this.state.lessonGrade && !this.state.lessonSubject){
      this.getLessonGradeAndSubject();
    }
  }

  getLessonGradeAndSubject(){
    let self = this;
    let params = {
      "apiKey": apiKey,
      "lessonId": sessionStorage.lastSearch,
      "langCode": this.props.language,
      "isForOutsideSource": false,
      "isAWebService": true,
      "token": sessionStorage.athenasToken
    }
    axios.get(apiLessonGetContent, {params}).then(function (response){
      if(response.data.SessionValid && response.data.TransactionValid){
        let lessonData = response.data.LessonModifierRequestModel;
        self.setState({
          lessonId: lessonData.LessonModel.Id,
          lessonTitle: lessonData.LessonModel.LessonTitle,
          lessonGrade: lessonData.LessonModel.LevelCode,
          lessonSubject: lessonData.LessonModel.SubjectCode
        })
      }
    });
  }

  selectOption(value, value2, value3, value4){
    this.setState({
      quizLevelOfKnowledge: value,
      quizType: value2,
      quizIsExam: value3,
      selectedLevelOfKnowledgeString: value4,
      levelSelected: true
    })
    //Auto fill instructions area for multiple choice quiz
    if(value2 === "1"){
      let autoInstruction = "";
      if(this.state.lessonSubject === "EN" || this.state.lessonSubject === "MAT-EN" || this.state.lessonSubject === "SCI_EN"){
        autoInstruction = "Select the correct answer.";
      } else{
        autoInstruction = "Seleccione la respuesta correcta.";
      }
      this.setState({quizInstructions: autoInstruction});
    }
    console.log("Quiz type for multiple choice", value2);
  }
  componentDidMount(){
    onRulesCall().then(data => {
      this.setState({"fileRules": data});
    });
  }
  render(){

    const sections = [
      {
        "sectionTitle": "Info",
        "sectionContent": <QuizInfo 
                            onChange={this.onChange} 
                            state={this.state} 
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      },
      {
        "sectionTitle": "Preguntas",
        "sectionContent": <QuizQuestions 
                            onChange={this.onChange} 
                            state={this.state}
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      }
    ];
    if(this.state.redirect){
      return(<Redirect to="lesson"/>);
    }
    return(
      <div>
        {this.state.redirect ? <Redirect to="lesson"/> : <div>
        {this.state.levelSelected ? 
          <div>
            <h3><FormattedMessage id="qp-title"/></h3>
            {this.state.loading ? <Button disabled variant="success" style={{marginBottom: "10px"}}>Create Quiz</Button> : <Button variant="success" style={{marginBottom: "10px"}} onClick={this.createQuiz}>Create Quiz</Button>}
            <TabNavigator 
              sections={sections} 
              defaultActive="Info"
            />    
          </div> :
          <div>
            <PreSelectionPage
              isForQuiz
              title="Quiz PreCreation"
              language={this.props.language}
              selectOption={this.selectOption}
            />
          </div> }
        </div>}</div>
    );
  }
}

export default QuizPage;