import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

// import  axios  from 'axios';
// import { apiKey, apiCreateTemplateUrl } from '../../config';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call'
import { createTemplateCall } from '../../server/postTemplateCreate/post-template-create-call';
import { emptyTemplateModelObject, emptyTemplateInstructionsModelList, emptyTemplateCodeModel, validateTemplate } from './create-template-utils';

import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import TemplateDetailsDisplay from '../../components/templateDetailsDisplay';
import Button from 'react-bootstrap/Button';

class CreateTemplatePage extends Component{
  
  constructor(props){
    super(props);
    this.state={
      templateModel: emptyTemplateModelObject,
      templateInstructionsModelList: emptyTemplateInstructionsModelList,
      templateCodeModel: emptyTemplateCodeModel
    }
    console.log("Create Template Page State", this.state);
    this.onChange = this.onChange.bind(this);
    this.onChangeTemplateModel = this.onChangeTemplateModel.bind(this);
    this.onChangeInstructions = this.onChangeInstructions.bind(this);
    this.postTemplate = this.postTemplate.bind(this);
    this.validateBeforePost = this.validateBeforePost.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  onChange(name, value){
    this.setState({[name]: value});
  }

  onChangeTemplateModel(propertyName, value){
    let newTemplateState = this.state.templateModel;
    newTemplateState[propertyName] = value
    this.setState({templateModel: newTemplateState}, function(){
      // console.log("On change template model in create-template-page", this.state);
    });
  }

  onChangeInstructions(value, lang){
    let newInstructions = this.state.templateInstructionsModelList;
    if(lang === "en"){
      newInstructions[0].InstructionDesc = value;
    } else if(lang === "es"){
      newInstructions[1].InstructionDesc = value;
    } 
    this.setState({templateInstructionsModelList: newInstructions})
    // console.log(this.state);
  }

  validateBeforePost(){
    //Function that shows validation messages
    console.log("validating");
    if(this.state.templateCodeModel.Id === null){
      alert(this.props.intl.formatMessage({id: 'ct-template-code-require'}));
    } else{
      //Since code is selected, we can validate
      let templateIsValidated = validateTemplate(
        this.state.templateCodeModel, 
        this.state.templateModel, 
        this.state.templateInstructionsModelList);
      if(templateIsValidated){
        this.postTemplate();
      } 
    }
  }

  postTemplate(){

    const templateModifierRequestModle = {
      TemplateModel: this.state.templateModel,
      TemplateInstructionModelList: this.state.templateInstructionsModelList
    }

    this.setState({loading: true}, function(){
      createTemplateCall(this.props.language, templateModifierRequestModle).then((data) => {
        this.setState({
          loading: data,
          redirect: !data
        });
      });
    });
  }

  setLocation(){
    this.setState({
      templateModel: emptyTemplateModelObject,
      templateInstructionsModelList: emptyTemplateInstructionsModelList,
      templateCodeModel: emptyTemplateCodeModel
    }, function(){
      this.props.history.push('/view-templates');
    })
  }

  UNSAFE_componentWillMount(){
    onRulesCall().then((data) => {
      this.setState({fileUploadRules: data});
    })
    this.setState({
      templateModel: emptyTemplateModelObject,
      templateInstructionsModelList: emptyTemplateInstructionsModelList,
      templateCodeModel: emptyTemplateCodeModel
    })
  }

  render(){
    if(this.state.redirect){
      return <Redirect to='/view-templates'/>
    } else{
      return(
        <div style={{marginBottom: "50px"}}>
          <h1><FontAwesomeIcon icon={faEdit}/>   <FormattedMessage id="ct-create-template"/></h1>
          <Button 
            style={{float: "right"}} 
            variant="success"
            onClick={this.validateBeforePost}
            disabled={this.state.loading}
          >
            <FormattedMessage id="ct-upload-template"/>
          </Button>
          <TemplateDetailsDisplay 
            onChange={this.onChange}
            onChangeTemplate={this.onChangeTemplateModel}
            onChangeInstructions={this.onChangeInstructions}
            parentState={this.state}
            language={this.props.language}/>
        </div>
      );
    }
  }
}

export default injectIntl(CreateTemplatePage);