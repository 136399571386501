import React, { Component } from 'react';

import ValidatedTextInput from '../../../validatedTextField';
import RichTextInput from '../../../richTextInput';
import RadioButtonSubjects from '../../../radioButtonSubjects';
import RadioButtonGrades from '../../../radioButtonGrades';
import LessonIntroAudio from '../../../lessonIntroAudio/lesson-intro-audio';
import StandardCodeSelector from '../../../standardCodeSelector';
import RadioButtonUnits from '../../../radioButtonUnits/radio-button-units-view';
import TextInput from '../../../textInput';

import { FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';

class CreateLessonInfoSection extends Component{
  
  constructor(props){
    super(props);
    this.state = {
      lessonDataState: this.props.state,
      lessonTitle: "",
      lessonDescription: "",
      lessonSubject: "",
      lessonGrade: "",
      lessonCode: "",
      lessonUnit: [],
      lessonAddress: "",
      //remove this later
      lessonStandardCode: ""
    }
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(field, value){
    this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
  }
  
  componentWillReceiveProps(nextProps){
    // console.log("state of lesosn info section", this.props.state)
    this.setState({
      subjectCode: nextProps.state.lessonSubject,
      lessonGrade: nextProps.state.lessonGrade ? nextProps.state.lessonGrade : "",
      selectedStandardId: nextProps.state.selectedStandardId ? nextProps.state.selectedStandardId : "",
      selectedExpectationId: nextProps.state.selectedExpectationId ? nextProps.state.selectedExpectationId : "",
      selectedSubCodeId: nextProps.state.selectedSubCodeId ? nextProps.state.selectedSubCodeId : "",
      selectedSubMainSubCodeId : nextProps.state.selectedSubMainSubCodeId ? nextProps.state.selectedSubMainSubCodeId : "",
      lessonStandardCode: nextProps.state.lessonStandardCode ? nextProps.state.lessonStandardCode : "",
    }, function() {
      // console.log("state after set state", this.state);
    });
  }
  
  render(){ 
    const { state } = this.props;
    return(
      <div>
        <RadioButtonSubjects 
          textName="lessonSubject" 
          onChange={this.onChange} 
          content={this.props.state.lessonSubject}
          language={this.props.language} 
          isValidated={state.validateLessonSubject}
        />
        <RadioButtonGrades 
          textName="lessonGrade" 
          onChange={this.onChange} 
          content={this.props.state.lessonGrade}  
          language={this.props.language} 
          isValidated={state.validateLessonGrade}
        />
        <RadioButtonUnits 
          labelID="cl-unit-input" 
          textName="lessonUnit"
          onChange={this.onChange} 
          content={this.props.state.lessonUnit}
          levelCode={this.state.lessonGrade}
          language={this.props.language}
          isValidated={state.validateLessonUnit} 
        />
        <StandardCodeSelector
          onChange={this.onChange} 
          subjectCode={this.props.state.lessonSubject} 
          grade={this.props.state.lessonGrade}
          language={this.props.language}
          selectedStandardId={this.state.selectedStandardId}
          selectedExpectationId={this.state.selectedExpectationId}
          selectedSubCodeId={this.state.selectedSubCodeId}
          selectedSubMainSubCodeId={this.state.selectedSubMainSubCodeId}
          loading={this.props.state.loadingLessonData}
          loadingFromServer
        />
        <TextInput
          labelID="cl-code-input-intern"
          textName="lessonStandardCode"
          content={this.props.state.lessonStandardCode}
          onChange={this.onChange}
        />
        <ValidatedTextInput 
          labelID="cl-title-input" 
          textName="lessonTitle" 
          onChange={this.onChange} 
          content={state.lessonTitle} 
          isValidated={state.validateLessonTitle}
          limitFromRules={this.props.lessonRules ? this.props.lessonRules.TitleMaxLength : null}
        />
        <ValidatedTextInput 
          labelID="cl-number-input" 
          textName="lessonNo" 
          onChange={this.onChange} 
          content={state.lessonNo} 
          isValidated={state.validateLessonNo}
          textType="number"
        />
        <RichTextInput 
          isVR={this.props.state.lessonAddress === '1'} 
          labelID="cl-description-input" 
          textName="lessonDescription" 
          onChange={this.onChange} 
          content={state.lessonDescription} 
          isValidated={state.validateLessonDescription}
          language={this.props.language}
          subject={this.props.state.lessonSubject}
          grade={this.props.state.lessonGrade}
          fileRules={this.props.fileRules}
        />
        <LessonIntroAudio
          src={this.props.state.lessonAudioSrc}
          lessonSubject={this.props.state.lessonSubject}
          lessonGrade={this.props.state.lessonGrade}
          language={this.props.language}
          onChange={this.onChange}
          fileRules={this.props.fileRules}
        />
        {/* <RadioPsichSelect textName="lessonKnowledgeLevel" onChange={this.onChange} content={this.props.state.lessonKnowledgeLevel} language={this.props.language} isValidated={state.validateLessonKnowledgeLevel}/> */}
        {/* <ChecklistTaxonomySelector 
          textName="lessonTaxonomies" 
          onChange={this.onChange} 
          language={this.props.language} 
          isValidated={state.validateLessonTaxonomy} 
          parentState={this.props.state.lessonTaxonomies}
        /> */}
        <RichTextInput 
          isVR={this.props.state.lessonAddress === '1'} 
          labelID="el-concept" 
          textName="lessonConcept" 
          onChange={this.onChange} 
          content={state.lessonConcept} 
          isValidated={state.validateLessonConcept}
          language={this.props.language}
          subject={this.props.state.lessonSubject}
          grade={this.props.state.lessonGrade}
          limitFromRules={this.props.lessonRules ? this.props.lessonRules.ConceptMaxLength : null}
          fileRules={this.props.fileRules}
        />
        <h3><FormattedMessage id="cl-audio-recorder"/></h3>
        <p><FormattedMessage id="cl-lesson-audio-recording"/></p>
        <Form>
          <Form.Check
            inline
            label={<FormattedMessage id="cl-yes"/>}
            checked={this.props.state.lessonHasRecorder}
            onClick={() => {this.onChange("lessonHasRecorder", true)}}
          />
          <Form.Check
            inline
            label={<FormattedMessage id="cl-no"/>}
            checked={!this.props.state.lessonHasRecorder}
            onClick={() => {this.onChange("lessonHasRecorder", false)}}
          />
        </Form>
      </div>
    );
  }
}

export default CreateLessonInfoSection;