import axios from 'axios';
import { apiKey, apiGetSentComments } from '../../config';

export const getCommentsSent = async function(lang, entId, entType){
  let params = {
    apiKey: apiKey,
    token: sessionStorage.athenasToken,
    langCode: lang,
    entityId: entId,
    entityType: entType
  }
  try {
    const response = await axios.get(apiGetSentComments, { params });
    //This response just returns the rules
    if(response.status === 200){
        return response.data;
    }else {
      alert("Something went wrong with getting the file upload rules.");
    }
  } catch(error){
    alert(error.response.data.Messages);
  }
}