import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import ModalPopout from '../../components/modalPopout';
class ComponentTest extends Component{

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
    }
    this.openModal = this.openModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  openModal(){
    this.setState({showModal: true});
  }
  onCloseModal(){
    this.setState({showModal: false});
  }

  render(){
    return(
      <div>
        <Button onClick={this.openModal}>open</Button>
        {this.state.showModal ?
          <ModalPopout
            title="Modal Title"
            body="Modal Body"
            handleClose={this.onCloseModal}
          />
          :
          ""
        }
        
      </div>
    );
  }
}

export default ComponentTest;