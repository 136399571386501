//This call gives a list of available templates  
//Examples(templateType: "EXA") or Definitions(templateType: "DEF")

import axios from 'axios';
import { apiKey, apiGetTemplates } from '../../config';
import { logoutUser } from '../../utils/authHandling/logout-user';

export const getTemplates = async function(language, type){
  const params = {
    apiKey: apiKey,
    token: sessionStorage.athenasToken,
    templateType: type,
    langCode: language,
    isForOutsideSource: true
  }
  try{
    const response = await axios.get(apiGetTemplates, { params });
    if(response.data.SessionValid){
      if(response.data.TransactionValid){
        //Session and transaction is valid, return the values
        // console.log("Get templates response: ", response.data);
        return response.data.TemplateModelList;
      }
    } else {
      //session is not valid, user is sent to login
      alert('Session is not valid');
      logoutUser();
    }
  } catch(error){
    alert(error.response.data.Messages);
  }
}