import React, { Component } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import Dropzone from 'react-dropzone-uploader';
import { FormattedMessage } from 'react-intl';

import { apiKey, uploadLink } from '../../config';

//bootstrap
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';


class LessonIntroAudio extends Component{
  constructor(props){
    super(props);
    this.state={
      audioSrc: "",
      uploadType: "audio",
      reuploadDisplay: false,
      askForReplace: false,
      audioHeaders: []
    }
    this.validateFiles = this.validateFiles.bind(this);
  }

  getUploadParams =  (headers) => {
    console.log("getUploadParams", headers)
    //In order to get the response URL: headers.xhr.response NOTE: this object is available when the call ends, it is async
    this.setState({dropzoneResponseObject: headers});
    let newState = this.state.audioHeaders;
    newState.push(headers);
    this.setState({audioHeaders: newState});
    console.log(headers);
    return { url : uploadLink + "token=" + sessionStorage.athenasToken
      + "&apiKey=" + apiKey
      + "&subject=" + this.props.lessonSubject 
      + "&level=" + this.props.lessonGrade 
      + "&uploadType=" + this.state.uploadType 
      + "&langCode=" + this.props.language   
    } 
  }

  validateFiles(droppedFile){
    console.log("validating?", droppedFile);
    let appliedRules = {};
    let messages = [];

    if(this.props.fileRules === null || this.props.fileRules === undefined){
      alert("File Rules are missing");
    } else{
      this.props.fileRules.forEach((item) => {
        let typeString = item.Classification + "/" + item.Format;
        if(typeString === droppedFile.meta.type){
          appliedRules = item;
        } 
      })
      if(droppedFile.meta.size > appliedRules.MaxSize){
        messages.push("File size is too large");
      }
      if(!this.props.lessonSubject){
        messages.push("Must select a subject");
      }
      if(!this.props.lessonGrade){
        messages.push("Must select a grade");
      }
      if(messages.length > 0){
        droppedFile.remove();
        alert(messages);
      }
    }
  }

  handleSubmit = (files, allFiles) => {
    //console.log(files.map(f => f.meta));
    console.log("handleSubmit");
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    this.props.onChange("lessonAudioSrc", response.Messages[0]);
    this.setState({reuploadDisplay: false});
    console.log("Waaaaaaaaaa", response);
    allFiles.forEach(f => f.remove())
  }

  render(){ 
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
      <div>
        <hr/>
        <h3><FormattedMessage id="cl-lesson-intro-audio"/></h3>
        {this.props.src ? 
          <div>
            <AudioPlayer
              // substitute for this.props.src
              src={this.props.src}
            />
            <Button 
              style={{marginTop: "10px"}} 
              onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay, askForReplace: true})}}
              aria-controls="dropzone-area"
              aria-expanded={this.state.reuploadDisplay}
            >
                <FormattedMessage id="cl-lesson-intro-upload"/>
            </Button>
            <Collapse in={this.state.reuploadDisplay}>
              <div id="dropzone-area">
                {this.state.askForReplace ? 
                  <div style={{textAlign: "center"}}>
                    <p><FormattedMessage id="cl-lesson-audio-rewrite-warning"/></p>
                    <Button 
                      onClick={() => {this.setState({askForReplace: false})}}
                      style={{margin: "5px"}}><FormattedMessage id="cl-yes"/></Button>
                    <Button
                      onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay})}}
                      style={{margin: "5px"}}
                      aria-controls="dropzone-area"
                      aria-expanded={this.state.reuploadDisplay}><FormattedMessage id="cl-no"/></Button>
                  </div> :
                  <Dropzone
                    accept="audio/ogg"
                    key="Dropzone View"
                    validate={this.validateFiles}
                    getUploadParams={this.getUploadParams}
                    // onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    styles={{ dropzone: baseStyle }}
                    inputContent={<FormattedMessage id={"u-audio/ogg"}/>}
                  />
                }
              </div>
            </Collapse>
          </div> : 
          <div>
            <Dropzone
              accept="audio/ogg"
              key="Dropzone View"
              validate={this.validateFiles}
              getUploadParams={this.getUploadParams}
              // onChangeStatus={this.handleChangeStatus}
              onSubmit={this.handleSubmit}
              styles={{ dropzone: baseStyle }}
              inputContent={<FormattedMessage id={"u-audio/ogg"}/>}
            />
          </div>}
        <div>
        
        </div>
        <hr/>
      </div>
    );
  }
}

export default LessonIntroAudio;