import React, { Component } from 'react';
import VideoPlayer from '../videoPlayer/video-player-view';
import AudioPlayer from 'react-h5-audio-player';

import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';

//This is a component that takes in te content of a rich ex editor, adn for each video or audio, it will display a preview accordibgly.
//Also it has a view/hide feature for organizational porpuses;

class MediaPreview extends Component{

  constructor(props){
    super(props);
    this.state={
      showPreviews: false,
      currentContent: "",
      videoSrcArray: [],
      audioSrcArray: []
    }
  }

  componentDidUpdate(){
    if(this.state.currentContent !== this.props.content){
      this.setState({
        currentContent: this.props.content
      }, function(){
        if(this.state.currentContent){  
          let parsedTagString =  this.state.currentContent.split('"');
          let srcArrayVids = [];
          let srcArrayAudios = [];
          parsedTagString.forEach(element => {
            if(element.includes("https") && element.includes("mp4")){
              srcArrayVids.push(element);		
            }
            if(element.includes("https") && element.includes("mp3")){
              srcArrayAudios.push(element);		
            }
          });
          this.setState({
            videoSrcArray: srcArrayVids,
            audioSrcArray: srcArrayAudios
          });
        }
      });
    }
  }

  render(){
    return(
      <div>
        {this.state.videoSrcArray.length > 0 || this.state.audioSrcArray.length > 0 ? 
          <Button onClick={() => {this.setState({showPreviews: !this.state.showPreviews})}}><FormattedMessage id="ps-open-preview"/></Button> : ""
        }
        <Collapse in={this.state.showPreviews}>
          <div>
          {this.state.videoSrcArray ? this.state.videoSrcArray.map((item, index) => {
            return (
              <div style={{marginBottom: "15px", marginTop: "10px"}}>
                <VideoPlayer videoSrc={item} key={index}/> 
              </div>
            )
          }) : ""}
          {this.state.audioSrcArray ? this.state.audioSrcArray.map((item, index) => {
            return(
              <div style={{marginBottom: "15px", marginTop: "10px"}}>
                <AudioPlayer src={item} key={index}/>
              </div>
            )
          }): ""}
          </div>
        </Collapse>
      </div>
    );
  }
}
export default MediaPreview;