//Utils for view Lessons Page

/**getStatusTitle()
 * This function will return the string that corresponds with the provided id
 * @param {string} id - id of the status 
 */
export const getStatusTitle = (id) => {
  switch(id) {
    case "1":
      return "Under Resource";
    case "2":
      return "Under Specialist";
    case "3":
      return "Under Artist";
    case "4":
      return "Under Editor";
    case "5":
      return "Under Supervisor";
    case "6":
      return "Published";
    case "7":
      return "Uploaded Error";
    default:
      return "Has no status";
  }
}