import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { apiKey, apiUnitsUrl } from '../../config';

import DynamicChecklist from '../dynamicChecklist';

class RadioButtonUnits extends Component{
  constructor(props){
    super(props);
    this.state = {
      unitsFromServer: [],
      selectedUnit: this.props.content
    }
    this.onUnitsCall = this.onUnitsCall.bind(this);
  }

  onUnitsCall(){
    const self = this;
    let params = {
      "token": sessionStorage.athenasToken,
      "apiKey": apiKey,
      "levelCode": this.state.levelCode
    }
    this.setState({unitsFromServer: []}, function(){
      // console.log(params)
      axios.get(apiUnitsUrl, { params }).then(function (response){
        if(response.data.TransactionValid && response.data.SessionValid){
          const unitsStrings = response.data.Units.map((item) => ({title: item.Name, code: item.Id}));
          self.setState({
            unitsFromServer: unitsStrings
          }, function(){
            if(!self.state.hasLoaded){
              self.setState({hasLoaded: true});
            } else{
              self.props.onChange(this.props.textName, []);
            }
          });
        } else{
          alert("Call Error: " + response.status);
        }
      }).catch(error => {
        alert(error.response.data.Messages);
      });
    });
  }

  componentWillReceiveProps(){
    if(this.props.levelCode !== ""){
      if(this.props.levelCode !== this.state.levelCode){
        this.setState({levelCode: this.props.levelCode}, () => { this.onUnitsCall(); });
      }
    }
  }
  render(){
    return(
      <div>        
        {this.state.unitsFromServer.length > 0 ? <h4><FormattedMessage id="cl-unit-input"/></h4> : ""}
        <DynamicChecklist 
          dontLoad
          items={this.state.unitsFromServer}
          parentState={this.props.content}
          stateName={this.props.textName}
          onChange={this.props.onChange}
          content={this.props.content}
        />
      </div>
    );
  }
}

export default RadioButtonUnits;