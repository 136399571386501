import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { apiKey, uploadLink } from '../../../../config'; 

import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

class ImageUploaderAndPreview extends Component{
  
  constructor(props){
    super(props);
    this.state={
      submitButtonDisabled: false
    }
    this.validateFiles = this.validateFiles.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);
  }

  validateFiles(droppedFile){
    console.log("validating?", droppedFile);
    let appliedRules = {};
    let messages = [];
    this.props.fileRules.forEach((item) => {
      let typeString = item.Classification + "/" + item.Format;
      if(typeString === droppedFile.meta.type){
        appliedRules = item;
      } 
    })

    if(appliedRules.Classification === "image"){

      if(droppedFile.meta.height > appliedRules.MaxHeight){
        messages.push("File heigth is too large");
      }
      if(droppedFile.meta.width > appliedRules.MaxWidth){
        messages.push("File width is too large");
      }
    }
    if(droppedFile.meta.size > appliedRules.MaxSize){
      console.log("Comparing", droppedFile.meta.size, ">", appliedRules.MaxSize);
      messages.push("File size is too large");
    }
    if(messages.length > 0){
      droppedFile.remove();
      alert(messages);
    }
  }

  getUploadParams =  (headers) => {
    console.log("getUploadParams")
    //In order to get the response URL: headers.xhr.response NOTE: this object is available when the call ends, it is async
    this.setState({dropzoneResponseObject: headers});
    console.log(headers);
    return { url :  uploadLink + "token=" + sessionStorage.athenasToken + "&apiKey=" + apiKey
      + "&subject=html" 
      + "&level=" 
      + "&uploadType=image" 
      + "&langCode=" + this.props.language   
    } 
  }

  //here we can get image size and set a cancelation if the file does not follow the rules.
  handleChangeStatus = (fileWithMeta, status) => {
    console.log("Hadle change status", status, fileWithMeta);
  }

  handleSubmit = (files, allFiles) => {
    //console.log(files.map(f => f.meta));
    console.log("handleSubmit");
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    // console.log("Waaaaaaaaaa", response);
    if(this.state.removeFile){
      allFiles.forEach(f => {f.remove()});
      alert("File id too large");
    } else{
      this.setState({stringImageSrc: response.Messages[0]});
      this.props.changeParentState(this.props.stateName, response.Messages[0]);
    }
    allFiles.forEach(f => f.remove())
  }
  
  componentDidMount(){
    if(this.props.content !== null || this.props.content !== undefined){
      this.setState({
        stringImageSrc: this.props.content
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(newProps.content){
      this.setState({stringImageSrc: newProps.content});
    }
  }
  

  render(){
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
      <div>
        <h5>{this.props.require === "1" ?  <span style={{color: "#f00"}}>*</span> : ""}<FormattedMessage id={this.props.label}/></h5>
        <div style={{paddingLeft: "20px"}}>
            {this.state.stringImageSrc ? 
              <div style={{paddingLeft: "20px"}}>
                <h6>Preview:</h6>
                <Image
                  src={this.state.stringImageSrc}
                  style={{margin: "10px"}}
                />
                <p><Button onClick={() => {this.setState({stringImageSrc: null})}}><FormattedMessage id="ct-edit-template-image"/></Button></p>
              </div>
            : 
              <Dropzone
                styles={{ dropzone: baseStyle }}
                inputContent={<FormattedMessage id={"u-image/png, image/jpg, image/jpeg"}/>}
                accept="image/png, image/jpg, image/jpeg"
                validate={this.validateFiles}
                submitButtonDisabled={false}
                getUploadParams={this.getUploadParams}
                onChangeStatus={this.handleChangeStatus}
                onSubmit={this.handleSubmit}
              />
            }
        </div>
      </div>
    );
  }
}

//TODO: Handle parent state

export default ImageUploaderAndPreview;