import React, { Component } from 'react';
import LoginForm from '../../components/loginForm';

import './login.css';

class LoginPage extends Component{
    render(){
        return(
            <div className="page">
                <LoginForm language={this.props.language}/>
            </div>
        );
    }
}

export default LoginPage;