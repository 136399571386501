import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SimpleFieldGenerator from '../../../simpleFieldGenerator';
class CreateLessonEstrategiaSection extends Component{
  constructor(props){
    super(props);
    this.setObjectiveData = this.setObjectiveData.bind(this);
  }

  setObjectiveData(content){
    this.props.parentStateHandle("lessonStrategies", content);
  }

  render(){
    return(
      <div>
        <h3><FormattedMessage id="cl-strategy-title"/></h3>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        <p><FormattedMessage id="cl-field-instruction"/></p>
        <SimpleFieldGenerator 
          titleId="cl-strategy" 
          setParentState={this.setObjectiveData}
          state={this.props.state}
          />
      </div>
    );
  }
}

export default CreateLessonEstrategiaSection;