import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import RichTextInput from '../../../../../../components/richTextInput';
import QuestionAnswersList from '../questionAnswerList';
import QuizRubricComponent from '../quizRubricComponent';
class QuestionComponent extends Component{
  render(){
    const { question, deleteQuestion } = this.props;
    return(
      <div>
        {/* Question */}
        <h4><FormattedMessage id="qp-question"/> {this.props.question.index + 1}</h4>
        <RichTextInput 
          onChange={this.props.onChangeQuestion} 
          content={this.props.quizQuestionModel} 
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade}
          limitFromRules={this.props.quizRules ? this.props.quizRules.QuestionMaxLength : null}
          fileRules={this.props.fileRules}
        />
        {/* Response */}
        <h4><FormattedMessage id="qp-response"/></h4>
        <QuestionAnswersList 
          addNewAnswer={this.props.addNewAnswer} 
          onChangeAnswer={this.props.onChangeAnswer}
          onChangeCorrectStatus={this.props.onChangeCorrectAnswerStatus}
          responses={this.props.quizQuestionResponseModelList}
          deleteAnswer={this.props.deleteAnswer}
          lessonSubject={this.props.lessonSubject}
          lessonGrade={this.props.lessonGrade}
          quizRules={this.props.quizRules}
          fileRules={this.props.fileRules}
        />
        {/* Rubric */}
        <h3><FormattedMessage id="qp-question"/> {this.props.question.index + 1}</h3>
        {this.props.quizLevel >= 3 || this.props.quizRules.CriterionRequired === 1 ? 
          <div>
            <QuizRubricComponent
              content={this.props.rubricContent}
              onChange={this.props.onChangeRubricContent}
            />
          </div>
        : ""}
        <Button 
          variant="danger"
          onClick={() => deleteQuestion(question.index)}
        ><FormattedMessage id="qp-delete-question"/></Button>
      </div>
    );
  }
}

export default QuestionComponent;